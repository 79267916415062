import React, { useEffect, useMemo, useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HashChipDialog from "./HashChipDialog";
import { OperationHashMaster, OperationHashMasterList } from "../../apicaller/domain/response/operation";
import { OperationHashItemChipGroup } from "./display/home";
import { WorkStandardWorkTabLabel } from "../../consts/label";

export interface Props {
  AddHandle: any;
  HashDialogContents: OperationHashMasterList;
  HashRegisteredList: OperationHashItemChipGroup;
}

export default function DialogHashBoard(props: Props) {
  const [open, setOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const operationHashMasterList:OperationHashMasterList = useMemo(() => {
    const tmp = props.HashDialogContents.operation_hash.map((hash):OperationHashMaster => {
      return {
        operation_hash_master_id:hash.operation_hash_master_id,
        content:hash.content,
      }
    })

    return { operation_hash:tmp };
  },[])
  const handleClickOpen = () => {
    setOpen(true);
    setIsProcessing(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={WorkStandardWorkTabLabel.ADD_HASH}>
        <IconButton onClick={handleClickOpen}>
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
      {operationHashMasterList && (
        <HashChipDialog
          open={open}
          onClose={handleClose}
          AddHandle={props.AddHandle}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          HashDialogContents={operationHashMasterList}
          HashRegisteredList={props.HashRegisteredList ?? new OperationHashItemChipGroup()}
        />
      )}
    </div>
  );
}
