import React, { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeItem from "@material-ui/lab/TreeItem";
import { Link, Tooltip, Typography } from "@material-ui/core";
import { OperationDisplay, OperationInProcessDisplayRows } from "../OperationManagementBook/display/operationManagementBook";
import { Message } from "../../context/appContextData";
import { ErrorMessages } from "../../consts/messages";
import { useManagementBookList, useOperationInProcessList } from "../../apicaller/repository/managementbook";
import { ListOption } from "../Common/ListBox";
import { ManagementBookItem } from "../../apicaller/domain/response/managementbook";
import { WorkFlowStatus } from "../../consts/workFlow";
import { WorkStandard } from "./WorkStandard";
import { purple } from "@material-ui/core/colors";
import { WorkStandardPageLabel } from "../../consts/label";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      marginTop: 50,
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    treeview: {
      height: 240,
      flexGrow: 1,
      maxWidth: 400,
    },
    drawerText: {
      flexGrow: 1,
      width: "200px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      verticalAlign: "middle",
    },
    selectedDrawerText: {
      backgroundColor: purple[100],
      fontWeight: "bold",
      flexGrow: 1,
      width: "200px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      verticalAlign: "middle",
    },
    drawerLabel: {
      color: "gray",
    },
    searchButtonItem: {
      justifyContent: "flex-end",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    warningMessage: {
      marginTop: 60,
      marginLeft: 10,
    },
    errorMessage: {
      color: "indianred",
      whiteSpace: "pre-wrap",
    },
  })
);

//Home画面に渡す検索条件
export type SelectedConditions = {
  factory: ListOption;
  plant: ListOption;
  module: ListOption;
  unit: ListOption;
  team: ListOption;
  model: ListOption;
  productionCapacity: ListOption;
};

export type SelectedOperationInfo = {
  operation_id: number;
  managed_number: number;
  operation_name: string;
  operation_in_process_id: number | undefined;
};

type Props = {
  selectedConditions: SelectedConditions;
  selectedWorkStandardInfo: SelectedOperationInfo;
  managementBookId: number;
  handleWorkStandardDrawerInvert?: () => void;
};

export default function WorkStandardWithDarwer(props: Props) {
  const styles = useStyles();
  const snackBarMessage = useContext(Message);

  const [operationInProcessList, getOperationInProcessList, resetOperationInProcessList, mutateOperationInProcessList, operationInProcessListError] =
    useOperationInProcessList();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedOperationInfo, setSelectedOperationInfo] = useState<SelectedOperationInfo>(props.selectedWorkStandardInfo);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  // 工程一覧リストの表示用データを作成
  const operationListDisplay = useMemo(() => {
    if (operationInProcessList?.process_list?.length) {
      return new OperationInProcessDisplayRows().setData(operationInProcessList);
    }
    return new OperationInProcessDisplayRows();
  }, [operationInProcessList]);

  const handleSelectOperationInfo = (selectedOperation: OperationDisplay, selectedProcessId: number) => {
    setSelectedOperationInfo({
      operation_id: selectedOperation.operation_id,
      managed_number: selectedOperation.managed_number,
      operation_name: selectedOperation.operation_name,
      operation_in_process_id: selectedOperation.operation_in_process_id,
    });
  };

  useEffect(() => {
    getOperationInProcessList(String(props.managementBookId));
  }, [props.managementBookId]);

  useEffect(() => {
    if (operationInProcessListError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_OPERATION_IN_PROCESS, "error");
    }
  }, [operationInProcessListError]);

  const selectedOperationInfoInHome = useMemo(
    () => ({
      operation_id: selectedOperationInfo.operation_id,
      managed_number: selectedOperationInfo.managed_number,
      operation_name: selectedOperationInfo.operation_name,
    }),
    [selectedOperationInfo]
  );

  if (!selectedOperationInfo) {
    return (
      <div className={styles.warningMessage}>
        <Typography className={styles.errorMessage} variant="h5">
          {WorkStandardPageLabel.WORK_STANDARD_GET_ERROR_FROM_MANAGEMENT_BOOK_OPEN}
        </Typography>
      </div>
    );
  }

  return (
    <>
      <Drawer
        className={styles.drawer}
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: styles.drawerPaper,
        }}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <List>
          <ListItem>
            <Typography className={styles.drawerLabel}>{WorkStandardPageLabel.PRODUCTION_CAPACITY}</Typography>
            <Typography>{props.selectedConditions.productionCapacity.value}</Typography>
          </ListItem>
        </List>
        <Divider />
        <List>
          <TreeView className={styles.treeview} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
            {operationListDisplay.processRows.map((row, index) => {
              return (
                <TreeItem
                  key={index}
                  nodeId={String(index)}
                  label={
                    <Tooltip title={row.process_name}>
                      <Typography className={styles.drawerText}>{row.process_name}</Typography>
                    </Tooltip>
                  }
                >
                  {row.operation_list.map((operationRow, rowIndex) => {
                    const operationLabel = operationRow.managed_number + "." + operationRow.operation_name;
                    return (
                      <Tooltip title={operationLabel} key={rowIndex}>
                        <Link style={{ cursor: "pointer" }} onClick={() => handleSelectOperationInfo(operationRow, row.process_id)}>
                          <Typography
                            className={
                              operationRow.operation_id === selectedOperationInfo.operation_id ? styles.selectedDrawerText : styles.drawerText
                            }
                          >
                            {operationLabel}
                          </Typography>
                        </Link>
                      </Tooltip>
                    );
                  })}
                </TreeItem>
              );
            })}
          </TreeView>
        </List>
      </Drawer>
      <main
        className={clsx(styles.content, {
          [styles.contentShift]: drawerOpen,
        })}
      >
        <WorkStandard
          showSideBar={true}
          drawerOpen={drawerOpen}
          selectedConditions={props.selectedConditions}
          selectedWorkStandardInfo={selectedOperationInfoInHome!}
          handleWorkStandardDrawerInvert={handleDrawerOpen}
        />
      </main>
    </>
  );
}
