import React, { useContext, useEffect, useMemo, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import DialogHashBoard from "./DialogHashBoard";
import { EditInfo, Message } from "../../context/appContextData";
import { ErrorMessages, SuccessMessages } from "../../consts/messages";
import { deleteOperationHashItem, postOperationHashItem, useOperationHashItem, useOperationHashMaster } from "../../apicaller/repository/operation";
import { OperationHashItem, OperationHashMaster, OperationHashMasterList } from "../../apicaller/domain/response/operation";
import { OperationHashItemChip, OperationHashItemChipGroup } from "./display/home";
import CompOperationHashItemChip from "./CompOperationHashItemChip";
import { OperationHashItemRegistRequest } from "../../apicaller/domain/request/operation";
import { HashContents } from "../../consts/opeationHashItem";

interface Props {
  operationCardId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outterDiv: {
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
    },
    innerDiv: {
      flexGrow: 1,
      padding: 10,
      display: "flex",
      flexdirection: "row",
      flexWrap: "wrap",
    },
  })
);

const CompOperationHashItemChipGroup = (props: Props) => {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);
  const snackBarMessage = useContext(Message);

  const [operationHashItemList, getOperationHashItemList, mutateOperationHashItemList, operationHashItemError] = useOperationHashItem();
  const [operationHashMasterList, getOperationHashMasterItemList, mutateOperationHashMasterItem, operationHashMasterItemError] =
    useOperationHashMaster();

  // ハッシュの順番
  const hashItemOder = [HashContents.ImportantProcessManagementItems, HashContents.ImportantItems, HashContents.CertifiedParts];

  const hashItemCompFunc = (a: OperationHashItemChip, b: OperationHashItemChip) => {
    if (hashItemOder.indexOf(a.content) < hashItemOder.indexOf(b.content)) {
      return -1;
    }
    if (hashItemOder.indexOf(a.content) > hashItemOder.indexOf(b.content)) {
      return 1;
    }
    return 0;
  };

  const operationHashItemChipList = useMemo(() => {
    let ret = new OperationHashItemChipGroup();
    if (!operationHashItemList?.operation_hash_item?.length || !operationHashMasterList?.operation_hash?.length) return ret;
    const tmp: OperationHashItemChip[] = operationHashItemList.operation_hash_item.map((e: OperationHashItem) => {
      const contentIndex = operationHashMasterList.operation_hash.findIndex((hash) => hash.operation_hash_master_id === e.operation_hash_master_id);
      return {
        operation_hash_item_id: e.operation_hash_item_id,
        operation_card_id: e.operation_card_id,
        operation_hash_master_id: e.operation_hash_master_id,
        content: operationHashMasterList.operation_hash[contentIndex].content,
      };
    });
    ret.operation_hash_item_chip_group = tmp.sort(hashItemCompFunc);
    return ret;
  }, [operationHashItemList, operationHashMasterList]);

  const hashMasterCompFunc = (a: OperationHashMaster, b: OperationHashMaster) => {
    if (hashItemOder.indexOf(a.content) < hashItemOder.indexOf(b.content)) {
      return -1;
    }
    if (hashItemOder.indexOf(a.content) > hashItemOder.indexOf(b.content)) {
      return 1;
    }
    return 0;
  };

  const sortedOperationHashMasterList: OperationHashMasterList = useMemo(() => {
    if (!operationHashMasterList) return new OperationHashMasterList();
    return { operation_hash: operationHashMasterList.operation_hash.sort(hashMasterCompFunc) };
  }, [operationHashMasterList]);

  // add chip
  const handleChipAdd = (masterHashID: number) => {
    const reqData = new OperationHashItemRegistRequest(props.operationCardId, masterHashID);
    postOperationHashItem(reqData).then(
      (data) => {
        mutateOperationHashItemList();
        snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION_HASH_ITEM, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATION_HASH_ITEM, "error");
      }
    );
  };

  const handleChipDelete = (operationHashItemId: number) => {
    deleteOperationHashItem(String(operationHashItemId)).then(
      (data) => {
        mutateOperationHashItemList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_OPERATION_HASH_ITEM, "success");
      },
      (error) => {
        snackBarMessage.setMessage(ErrorMessages.DELETE_OPERATION_HASH_ITEM, "error");
      }
    );
  };

  useEffect(() => {
    mutateOperationHashItemList();
    getOperationHashMasterItemList();
    getOperationHashItemList(String(props.operationCardId));
  }, []);

  useEffect(() => {
    getOperationHashMasterItemList();
    getOperationHashItemList(String(props.operationCardId));
  }, [props.operationCardId]);

  return (
    <div className={styles.outterDiv}>
      <div className={styles.innerDiv}>
        {operationHashItemChipList.operation_hash_item_chip_group.map((hashChip, index) => {
          return <CompOperationHashItemChip key={index} chipContent={hashChip} handleDelete={handleChipDelete} />;
        })}
        {operationHashMasterList && EditContext.editMode && (
          <DialogHashBoard
            AddHandle={handleChipAdd}
            HashDialogContents={sortedOperationHashMasterList}
            HashRegisteredList={operationHashItemChipList}
          />
        )}
      </div>
    </div>
  );
};

export default CompOperationHashItemChipGroup;
