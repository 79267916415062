import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

export type DialogContent = {
  name: string;
  image: string;
  avatarSourceId: number;
};

export interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  AddHandle: any;
  DialogContents: DialogContent[];
}

export default function ChipDialog(props: Props) {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (props.open){
      setClicked(false);
    }
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
  };

  const handleListItemClick = (avatarSourceId: number) => {
    if (clicked) return;
    setClicked(true);
    props.AddHandle(avatarSourceId);
    props.onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
      <List>
        {props.DialogContents.map((DialogContent) => (
          <ListItem button onClick={() => handleListItemClick(DialogContent.avatarSourceId)} key={DialogContent.name}>
            <ListItemAvatar>
              <Avatar src={DialogContent.image} variant="square"/>
            </ListItemAvatar>
            <ListItemText primary={DialogContent.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
