import React, { useContext, useEffect, useRef, useState } from "react";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EditInfo } from "../../context/appContextData";
import { OperationPointChip } from "../Home/display/home";
import { WorkStandardPointLimits } from "../../consts/inputLimits";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import { IconButton, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface Props {
  parentWidth: number;
  chipContent: OperationPointChip;
  comboBoxOptions: string[];
  handleUpdate: (operationPointId: number, value: string) => void;
  handleDelete: (operationPointId: number) => void;
  isCardSelected: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexdirection: "row",
      padding: 5,
    },
    chip: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 100,
      "& .MuiChip-label": {
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
    chiperror: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 100,
      backgroundColor: "#F75D59",
      "& .MuiChip-label": {
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
    tooltips: {
      fontSize: 18,
    },
    comboBox: {
      flexGrow: 1,
      padding: 5,
      width: 300,
    },
  })
);

export const OperationChip = (props: Props) => {
  const styles = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const EditContext = useContext(EditInfo);

  const [onEdit, setOnEdit] = useState(false);

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.handleUpdate(props.chipContent.operation_point_id, e.target.value);
    setOnEdit(false);
  };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      if (!e.nativeEvent.isComposing) {
        props.handleUpdate(props.chipContent.operation_point_id, (e.target as HTMLInputElement).value);
        setOnEdit(false);
      }
    }
  };

  const voiceFunc = (readingMsg: string) => {
    var msg = new SpeechSynthesisUtterance();
    msg.text = readingMsg;
    msg.lang = "ja-JP";
    window.speechSynthesis.speak(msg);
  };

  useEffect(() => {
    if (onEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [onEdit]);

  return (
    <div className={styles.root}>
      {EditContext.editMode ? (
        <>
          <Chip
            className={props.chipContent.isError ? styles.chiperror : styles.chip}
            label={
              onEdit ? (
                <div className={styles.comboBox}>
                  <Autocomplete
                    open={onEdit}
                    className={styles.comboBox}
                    disableClearable={true}
                    noOptionsText={"no data found"}
                    options={props.comboBoxOptions}
                    getOptionLabel={(option) => option}
                    size="small"
                    onKeyPress={handleOnKeyPress}
                    value={props.chipContent.content}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onBlur={handleOnBlur}
                        inputRef={inputRef}
                        inputProps={{
                          ...params.inputProps, // このエラーを防止：Cannot read properties of null (reading 'setAttribute')
                          maxLength: WorkStandardPointLimits.INPUT_MAX_LENGTH,
                        }} 
                        variant="standard"
                      />
                    )}
                  />
                </div>
              ) : (
                props.chipContent.content
              )
            }
            onDelete={() => props.handleDelete(props.chipContent.operation_point_id)}
            avatar={<Avatar src={props.chipContent.source} />}
            onClick={(e) => {
              setOnEdit(true);
            }}
          />
          <IconButton className="chipDragHandleSelector" size="small">
            <DragHandleIcon />
          </IconButton>
        </>
      ) : (
        <Chip
          className={styles.chip}
          label={props.chipContent.content}
          onClick={() => voiceFunc(props.chipContent.content)}
          avatar={<Avatar src={props.chipContent.source} />}
        />
      )}
    </div>
  );
};
