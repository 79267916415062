import { useState } from "react";
import useSWR from "swr";
import { fetchDelete, get, post, put, putKeepalive } from "../apiCaller";
import { ApiMethod, ApiName, createEndPoint } from "../createEndPoint";
import {
  DuplicateRequest,
  ManagementBookRegistRequest,
  OperationInProcessOrderUpdateRequest,
  OperationInProcessRequest,
  ProcessNameUpdateRequest,
  ProcessOrderUpdateRequest,
  ProcessRegistRequest,
} from "../domain/request/managementbook";
import {
  ManagementBookHistoryListResponse,
  ManagementBookItem,
  ManagementBookListResponse,
  ManagementBookUnderRevisionResponse,
  OperationInProcessResponse,
  ProcessListResponse,
} from "../domain/response/managementbook";
import { ApiBody, ApiResponse } from "../../utils/schemaUtil";
import * as $axios from "../../utils/axiosUtils";
import { AxiosError, AxiosResponse } from "axios";
import { useAppSWR } from "../../hooks/useAppSWR";

// GET management_book
export const useManagementBookList = (): [
  ManagementBookListResponse,
  (model_id: string, team_id: string, production_capacity_id: string) => void,
  () => void,
  () => void,
  string
] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const getData = (model_id: string, team_id: string, production_capacity_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.ManagementBookList, method: ApiMethod.Get }, [model_id, team_id, production_capacity_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

// POST management book
export const postManagementBook = (body: ManagementBookRegistRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.ManagementBook, method: ApiMethod.Post }), bodyJson);
};

// PUT editing off management book
export const putEditingOff = (body: ApiBody<"/management_book/editing_off", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/management_book/editing_off",
    method: "put",
    data: body,
  });
};

// PUT editing on management book
export const putEditingOn = (body: ApiBody<"/management_book/editing_on", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/management_book/editing_on",
    method: "put",
    data: body,
  });
};

// PUT editing off all management book
export const putEditingOffAll = (): Promise<any> => {
  return putKeepalive(createEndPoint({ name: ApiName.ManagementBookEditingOffAll, method: ApiMethod.Put }), "");
};

// POST duplicate management book
export const postDuplicate = (body: DuplicateRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Duplicate, method: ApiMethod.Post }), bodyJson);
};

// GET management book
export const useManagementBook = (): [ManagementBookItem, (management_book_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get, { refreshInterval: 1000 });
  const getData = (management_book_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.ManagementBook, method: ApiMethod.Get }, [management_book_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

export const useProcessList = (): [
  ApiResponse<"/management_book/process_list", "get"> | undefined,
  (management_book_id: number) => void,
  () => void,
  () => void,
  AxiosError | undefined
] => {
  const [stateData, setStateData] = useState<number>(0);
  const { data, mutate, error } = useAppSWR({
    url: "/management_book/process_list",
    method: "get",
    params: {
      management_book_id: stateData,
    },
  });
  const getData = (management_book_id: number) => {
    mutate();
    setStateData(management_book_id);
  };
  const resetData = () => {
    mutate(undefined, false);
  };
  const mutateData = () => {
    mutate();
  };
  return [data, getData, resetData, mutateData, error];
};

// POST process
export const postProcess = (body: ProcessRegistRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.ManagementBookProcess }), bodyJson);
};

// DELETE process
export const deleteProcess = (process_id: string): Promise<any> => {
  return fetchDelete(createEndPoint({ name: ApiName.ManagementBookProcess, method: ApiMethod.Delete }, [process_id]));
};

// PUT process_name
export const putProcessName = (body: ProcessNameUpdateRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.ManagementBookProcessName, method: ApiMethod.Put }), bodyJson);
};

// Put Process Order
export const putProcessOrder = (body: ProcessOrderUpdateRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.ProcessOrder, method: ApiMethod.Put }), bodyJson);
};

// PUT process applicable
export const postProcessApplicable = (body: ApiBody<"/management_book/process_applicable", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/management_book/process_applicable",
    method: "post",
    data: body,
  });
};

// DELETE process applicable
export const deleteProcessApplicable = (process_applicable_id: number): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/management_book/process_applicable/{process_applicable_id}",
    method: "delete",
    paths: {
      process_applicable_id: process_applicable_id,
    },
  });
};

// GET operation_in_process_list
export const useOperationInProcessList = (): [OperationInProcessResponse, (management_book_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (management_book_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.ManagementBookOperationInProcessList }, [management_book_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

export const postOperationInProcess = (body: OperationInProcessRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.OperationInProcess, method: ApiMethod.Post }), bodyJson);
};

export const PutOperationInProcess = (body: OperationInProcessOrderUpdateRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.OperationInProcess, method: ApiMethod.Put }), bodyJson);
};

export const deleteOperationInProcess = (operation_in_process_id: string) => {
  return fetchDelete(createEndPoint({ name: ApiName.OperationInProcess, method: ApiMethod.Delete }, [operation_in_process_id]));
};

// GET management_book_history
export const useManagementBookHistory = (): [
  ManagementBookHistoryListResponse,
  (management_book_id: string) => void,
  () => void,
  () => void,
  string
] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (management_book_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.ManagementBookHistory, method: ApiMethod.Get }, [management_book_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

// GET /management_book/under_revision
export const useManagementBookUnderRevision = (): [
  ManagementBookUnderRevisionResponse,
  (management_book_id: string) => void,
  () => void,
  () => void,
  string
] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (management_book_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.ManagementBookUnderRevision, method: ApiMethod.Get }, [management_book_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

export const useRejectMessage = (available:boolean): [
  ApiResponse<"/management_book/reject_message", "get"> | undefined,
  (management_book_id: number) => void,
  () => void,
  () => void,
  AxiosError | undefined
] => {
  const [stateData, setStateData] = useState<number>(0);
  const { data, mutate, error } = useAppSWR(stateData !== 0 && available ? {
    url: "/management_book/reject_message",
    method: "get",
    params: {
      management_book_id: stateData,
    },
  }:undefined);
  const getData = (management_book_id: number) => {
    mutate();
    setStateData(management_book_id);
  };
  const resetData = () => {
    mutate(undefined, false);
  };
  const mutateData = () => {
    mutate();
  };
  // 管理台帳IDが決まってない時にエラーを出さないようにする
  // もっといい方法があれば変更してください
  return [data, getData, resetData, mutateData, stateData === 0 ? undefined : error];
};

export const putPublishDay = (body: ApiBody<"/management_book/publish_day", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/management_book/publish_day",
    method: "put",
    data: body,
  });
};

export const useOperationSignByWork = (): [
  ApiResponse<"/management_book/operator_sign_of_process", "get"> | undefined,
  (process_id: number) => void,
  () => void,
  () => void,
  AxiosError | undefined
] => {
  const [stateData, setStateData] = useState<number>(0);
  const { data, mutate, error } = useAppSWR(stateData !== 0 ? {
    url: "/management_book/operator_sign_of_process",
    method: "get",
    params: {
      management_book_id: stateData,
    },
  }:undefined);
  const getData = (management_book_id: number) => {
    mutate();
    setStateData(management_book_id);
  };
  const resetData = () => {
    mutate(undefined, false);
  };
  const mutateData = () => {
    mutate();
  };
  
  return [data, getData, resetData, mutateData, stateData !== 0 ? error:undefined];
};