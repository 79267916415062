import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import OperationManagementBook from "./pages/OperationManagementBook";
import { MiniDrawer } from "./pages/MiniDrawer";
import CreateNew from "./pages/CreateNew";
import DataManagement from "./pages/DataManagement";
import LoginPage from "./pages/LoginPage";
import UserList from "./pages/UserList";
import { useLoginUser } from "./apicaller/repository/authentication";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, StylesProvider, Theme } from "@material-ui/core";
import { AlertType, SnackMessage } from "./components/Common/SnackbarMessage";
import { EditInfo, LoginInfo, Message, UserFeedback, WindowSize, windowSize, innerAreaSize, InnerAreaSize } from "./context/appContextData";
import OperationList from "./pages/OperationListPage";
import Instructions from "./pages/Instructions";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import "./styles/Global.css";
import { Loading2 } from "./components/Common/Loading2";
import { useGetElementProperty } from "./hooks/getElementProperty";
import { putEditingOffAll as putWsEditingOffAll } from "./apicaller/repository/workstandard";
import { putEditingOffAll as putMbEditingOffAll } from "./apicaller/repository/managementbook";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#AC4DC8",
    },
  },
});

function App() {
  const styles = useStyles();
  const appBarRef = useRef(null);
  const appBarHeight = useGetElementProperty(appBarRef).getElementProperty("height");

  const [loginUser, getLoginUser, isLoading, error] = useLoginUser();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState<windowSize>({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [innerAreaSizeContext, setInnerAreaSizeContext] = useState<innerAreaSize>({
    height: window.innerHeight - 48,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<SnackMessage>({
    message: "",
    type: "info",
  });
  const [userFeedbackData, setUserFeedbackData] = useState<{ feedbackType: string; targetPage: string; content: string }>({
    feedbackType: "IMPROVEMENT",
    targetPage: "作業標準",
    content: "",
  });

  //メッセージ表示する
  const handleMessage = (message: string, type: AlertType) => {
    //メッセージ表示時間をリセットするために一回FALSEにする
    setShowAlert(false);
    setShowAlert(true);
    setAlertMessage({ ...{ message: message, type: type } });
  };
  //メッセージを非表示にする
  const handleCloseAlart = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  const resizeWindow = () => {
    windowSize.height = window.innerHeight;
    windowSize.width = window.innerWidth;
    setWindowSize({ ...windowSize });
  };

  const changeFeedbackTypeHandler = (feedbackType: string) => {
    userFeedbackData.feedbackType = feedbackType;
    setUserFeedbackData({ ...userFeedbackData });
  };

  const changeTargetPageHandler = (targetPage: string) => {
    userFeedbackData.targetPage = targetPage;
    setUserFeedbackData({ ...userFeedbackData });
  };

  const changeContentHandler = (content: string) => {
    userFeedbackData.content = content;
    setUserFeedbackData({ ...userFeedbackData });
  };

  const handleBeforeUnload = (_: BeforeUnloadEvent) => {
    putWsEditingOffAll();
    putMbEditingOffAll();
  };

  useEffect(() => {
    window.addEventListener("resize", resizeWindow);

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (appBarHeight === 0) return;
    const height = window.innerHeight - appBarHeight;
    setInnerAreaSizeContext({ height: height });
  }, [windowSize, appBarHeight]);

  if (isLoading) {
    return (
      <Backdrop className={styles.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <BrowserRouter>
          <Message.Provider value={{ showAlert: showAlert, message: alertMessage, setMessage: handleMessage, closeMessage: handleCloseAlart }}>
            <UserFeedback.Provider
              value={{
                feedbackType: userFeedbackData.feedbackType,
                targetPage: userFeedbackData.targetPage,
                content: userFeedbackData.content,
                changeFeedbackTypeHandler: changeFeedbackTypeHandler,
                changeTargetPageHandler: changeTargetPageHandler,
                changeContentHandler: changeContentHandler,
              }}
            >
              <LoginInfo.Provider value={{ loginUser: loginUser, getLoginUser: getLoginUser }}>
                <EditInfo.Provider value={{ editMode: editMode, invert: () => setEditMode(!editMode) }}>
                  <WindowSize.Provider value={windowSize}>
                    <InnerAreaSize.Provider value={innerAreaSizeContext}>
                      {/* ページ切り替え */}
                      <Switch>
                        {/* ログイン画面 */}
                        <Route exact path="/login">
                          <MiniDrawer ref={appBarRef} pageComponents={[<LoginPage key={"LoginPage"} />]} pageTitle="Pivot" />
                        </Route>
                        {/* ホーム画面（工程に紐づいた作業標準の表示） */}
                        <Route exact path="/">
                          {loginUser ? (
                            <MiniDrawer
                              ref={appBarRef}
                              pageComponents={[<OperationManagementBook key={"OperationManagementBook"} />]}
                              pageTitle="Pivot"
                            />
                          ) : (
                            <Redirect to="/login" />
                          )}
                        </Route>
                        {/* 作業標準管理台帳 */}
                        <Route exact path="/operationManagementBook">
                          {loginUser ? (
                            <MiniDrawer
                              ref={appBarRef}
                              pageComponents={[<OperationManagementBook key={"OperationManagementBook"} />]}
                              pageTitle="Pivot"
                            />
                          ) : (
                            <Redirect to="/login" />
                          )}
                        </Route>
                        {/* サイドバー有り作業標準画面 */}
                        {/* <Route exact path="/workStandard">
                          {loginUser ? (
                            <MiniDrawer ref={appBarRef} pageComponents={[<OperationDrawer key={"OperationDrawer"} />]} pageTitle="Pivot" />
                          ) : (
                            <Redirect to="/login" />
                          )}
                        </Route> */}
                        {/* 使い方説明画面 */}
                        <Route exact path="/instructions">
                          {loginUser ? (
                            <MiniDrawer ref={appBarRef} pageComponents={[<Instructions key={"Instructions"} />]} pageTitle="Pivot" />
                          ) : (
                            <Redirect to="/login" />
                          )}
                        </Route>
                        {/* 習熟管理画面 */}
                        {/* <Route exact path="/training">
                          {loginUser ? (
                            <MiniDrawer ref={appBarRef} pageComponents={[<Training key={"Training"} />]} pageTitle="Pivot" />
                          ) : (
                            <Redirect to="/login" />
                          )}
                        </Route> */}
                        {/* 作業確認者一覧画面 */}
                        {/* <Route exact path="/confirmer">
                          {loginUser ? (
                            <MiniDrawer ref={appBarRef} pageComponents={[<Confirmer key={"Confirmer"} />]} pageTitle="Pivot" />
                          ) : (
                            <Redirect to="/login" />
                          )}
                        </Route> */}
                        {/* データ(画像/動画/3D/テキスト)登録画面 */}
                        {/* <Route exact path="/fileUpload">
                          {loginUser ? <MiniDrawer pageComponents={[<FileUpload key={"FileUpload"} />]} pageTitle="Pivot" /> : <Redirect to="/login" />}
                        </Route> */}
                        {/* デルタ改廃画面 */}
                        {/* <Route exact path="/deltaList">
                          {loginUser ? (
                            <MiniDrawer ref={appBarRef} pageComponents={[<DeltaList key={"DeltaList"} />]} pageTitle="Pivot" />
                          ) : (
                            <Redirect to="/login" />
                          )}
                        </Route> */}
                        {/* データ管理画面 */}
                        <Route exact path="/dataManagement">
                          {loginUser ? (
                            <MiniDrawer ref={appBarRef} pageComponents={[<DataManagement key={"DataManagement"} />]} pageTitle="Pivot" />
                          ) : (
                            <Redirect to="/login" />
                          )}
                        </Route>
                        {/* 作業標準新規作成画面 */}
                        <Route exact path="/createNew">
                          {loginUser ? (
                            <MiniDrawer ref={appBarRef} pageComponents={[<CreateNew key={"CreateNew"} />]} pageTitle="Pivot" />
                          ) : (
                            <Redirect to="/login" />
                          )}
                        </Route>
                        {/* 作業標準一覧画面 */}
                        <Route exact path="/operationList">
                          {loginUser ? (
                            <MiniDrawer ref={appBarRef} pageComponents={[<OperationList key={"OperationList"} />]} pageTitle="Pivot" />
                          ) : (
                            <Redirect to="/login" />
                          )}
                        </Route>
                        {/* ユーザ一覧画面 */}
                        <Route exact path="/userList">
                          {loginUser ? (
                            <MiniDrawer ref={appBarRef} pageComponents={[<UserList key={"UserList"} />]} pageTitle="Pivot" />
                          ) : (
                            <Redirect to="/login" />
                          )}
                        </Route>
                      </Switch>
                    </InnerAreaSize.Provider>
                  </WindowSize.Provider>
                </EditInfo.Provider>
              </LoginInfo.Provider>
            </UserFeedback.Provider>
          </Message.Provider>
        </BrowserRouter>
        <Loading2 />
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
