import React, { useEffect, useMemo } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { useOperationSignByWork } from "../../apicaller/repository/managementbook";
import { ManagementBookProcessListTabLabel } from "../../consts/label";
import { formatDate } from "../../function/formatDatetime";

const useStyles = makeStyles(() =>
  createStyles({
    tabelCell: {
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  })
);

export interface Props {
  open: boolean;
  process_id: number;
  management_book_id: number;
  onClose: () => void;
}

interface userItem {
  login_id: string;
  name: string;
}

export const OperatorSignByWorkDialog = (props: Props) => {
  const styles = useStyles();

  const [operationSignByWork, getOperationSignByWork, resetOperationSignByWork, mutateOperationSignByWork, operationSignByWorkError] =
    useOperationSignByWork();

  const handleCloseDialog = () => {
    props.onClose();
  };

  const signedUserList = useMemo<userItem[]>(() => {
    let ret: userItem[] = [];

    if (!operationSignByWork?.operator_sign_of_process_list) {
      return ret;
    }

    for (const i of operationSignByWork.operator_sign_of_process_list) {
      if (i.process_id !== props.process_id) {
        continue;
      }
      for (const j of i.operator_signed_list) {
        for (const operator_signed_list of j.operator_signed_list) {
          const loginId = operator_signed_list.login_id;
          const exists = ret.some((item) => item.login_id === loginId);
          if (!exists) {
            ret.push({
              login_id: loginId,
              name: operator_signed_list.user_name,
            });
          }
        }
      }
    }

    return ret;
  }, [operationSignByWork, props.process_id]);

  useEffect(() => {
    if (props.management_book_id) {
      getOperationSignByWork(props.management_book_id);
    }
  }, [props.management_book_id]);

  return (
    <Dialog fullWidth={true} maxWidth={"lg"} open={props.open} onClose={handleCloseDialog}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.tabelCell} align={"center"}>
                工程名
              </TableCell>
              <TableCell className={styles.tabelCell} align={"center"}>
                作業標準名
              </TableCell>
              {signedUserList.map((user, index) => (
                <Tooltip title={user.name + " (" + user.login_id + ")"}>
                  <TableCell className={styles.tabelCell} key={index} align={"center"}>
                    {user.name}
                  </TableCell>
                </Tooltip>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {operationSignByWork?.operator_sign_of_process_list?.map((process, pIndex) => {
              if (process.process_id !== props.process_id) {
                return;
              }
              return process.operator_signed_list.map((work, index) => {
                return (
                  <TableRow key={index}>
                    {index === 0 && (
                      <TableCell rowSpan={process.operator_signed_list.length} className={styles.tabelCell}>
                        {process.process_name}
                      </TableCell>
                    )}
                    <TableCell className={styles.tabelCell}>{work.ws_name}</TableCell>
                    {signedUserList.map((user, index) => {
                      const signed_date = work.operator_signed_list.find((sign, index) => sign.login_id === user.login_id)?.signed_date;
                      return (
                        <TableCell key={index} className={styles.tabelCell} align={"center"}>
                          {signed_date ? formatDate(signed_date) : "-"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
};
