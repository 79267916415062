export class UserInfoLimits {
  static readonly ID_MIN_LENGTH = 8;
  static readonly ID_MAX_LENGTH = 30;
  static readonly NAME_MIN_LENGTH = 1;
  static readonly NAME_MAX_LENGTH = 30;
  static readonly PW_MIN_LENGTH = 8;
  static readonly PW_MAX_LENGTH = 30;
}

export class ProcessLimits {
  static readonly NAME_MIN_LENGTH = 1;
  static readonly NAME_MAX_LENGTH = 50;
}

export class HistoryLimits {
  static readonly INPUT_MIN_LENGTH = 1;
  static readonly INPUT_TITLE_MAX_LENGTH = 100;
  static readonly INPUT_DETAIL_MAX_LENGTH = 200;
}

export class HistoryDetailLimits {
  static readonly INPUT_MIN_LENGTH = 1;
  static readonly INPUT_MAX_LENGTH = 200;
}

export class WorkStandardCardLimits {
  static readonly INPUT_MIN_LENGTH = 1;
  static readonly INPUT_MAX_LENGTH = 200;
}

export class WorkStandardPointLimits {
  static readonly INPUT_MAX_LENGTH = 200;
}

export class WorkStandardRequirementOtherLimits {
  static readonly INPUT_MAX_LENGTH = 50;
}

export class WorkStandardPartsLimits {
  static readonly INPUT_MIN_LENGTH = 1;
  static readonly INPUT_MAX_LENGTH = 200;
}

export class WorkStandardSafetyPointLimits {
  static readonly INPUT_MIN_LENGTH = 1;
  static readonly INPUT_MAX_LENGTH = 200;
}

export class WorkStandardNameLimits {
  static readonly INPUT_MIN_LENGTH = 1;
  static readonly INPUT_MAX_LENGTH = 200;
}

export class WorkStandardRejectMessageLimits {
  static readonly INPUT_MAX_LENGTH = 200;
}

export class ManagementBookHistoryLimits {
  static readonly INPUT_MIN_LENGTH = 1;
  static readonly INPUT_MAX_LENGTH = 200;
}

export class ManagementBookRejectMessageLimits {
  static readonly INPUT_MAX_LENGTH = 200;
}
