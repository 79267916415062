import React, { useContext, useEffect, useMemo, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { EditInfo, Message } from "../../context/appContextData";
import { Requirement } from "../../apicaller/domain/response/operation";
import { RequirementRegistRequest } from "../../apicaller/domain/request/operation";
import { deleteRequirement, postRequirement, putRequirementOther, useRequirement } from "../../apicaller/repository/operation";
import { ErrorMessages, SuccessMessages } from "../../consts/messages";
import { Requirement as RequirementLabel } from "../../consts/label.ts";

import torqueWrench from "../../icons/torque-wrench.png";
import glue from "../../icons/glue.png";
import grease from "../../icons/grease.png";
import oil from "../../icons/oil.png";
import silicone from "../../icons/silicone.png";
import antiRust from "../../icons/anti-rust.png";
import torqueWrenchClassA from "../../icons/torque-wrench-classA.png";
import torqueWrenchClassB from "../../icons/torque-wrench-classB.png";
import degreaser from "../../icons/degreaser.png";
import other from "../../icons/other.png";
import RequirementChip from "./RequirementChip";
import DialogRequirement from "./DialogRequirement";
import { RequirementsChipForEdit } from "../WorkStandard/RequirementsChipForEdit.tsx";
import { ApiBody } from "../../utils/schemaUtil.ts";

interface Props {
  operationId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outterDiv: {
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      scrollSnapType: "y mandatory",
    },
    innerDiv: {
      flexGrow: 1,
      padding: "0 10px 0 10px",
      display: "flex",
      flexdirection: "row",
      flexWrap: "wrap",
      scrollSnapType: "y mandatory",
    },
  })
);

const RequirementChipGroup = (props: Props) => {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);
  const snackBarMessage = useContext(Message);

  const [requirementList, getRequirementList, resetRequirementList, mutateRequirementList, requirementError] = useRequirement();

  const requirementItems = [
    { index: 1, name: RequirementLabel.TORQUE, image: torqueWrench, avatarSourceId: 1, text: RequirementLabel.TORQUE, avatarSource: torqueWrench },
    { index: 2, name: RequirementLabel.GLUE, image: glue, avatarSourceId: 2, text: RequirementLabel.GLUE, avatarSource: glue },
    { index: 3, name: RequirementLabel.GREASE, image: grease, avatarSourceId: 3, text: RequirementLabel.GREASE, avatarSource: grease },
    { index: 4, name: RequirementLabel.OIL, image: oil, avatarSourceId: 4, text: RequirementLabel.OIL, avatarSource: oil },
    {
      index: 5,
      name: RequirementLabel.SLIDING_AGENT,
      image: silicone,
      avatarSourceId: 5,
      text: RequirementLabel.SLIDING_AGENT,
      avatarSource: silicone,
    },
    { index: 6, name: RequirementLabel.ANTI_RUST, image: antiRust, avatarSourceId: 6, text: RequirementLabel.ANTI_RUST, avatarSource: antiRust },
    {
      index: 7,
      name: RequirementLabel.CLASS_A,
      image: torqueWrenchClassA,
      avatarSourceId: 7,
      text: RequirementLabel.CLASS_A,
      avatarSource: torqueWrenchClassA,
    },
    {
      index: 8,
      name: RequirementLabel.CLASS_B,
      image: torqueWrenchClassB,
      avatarSourceId: 8,
      text: RequirementLabel.CLASS_B,
      avatarSource: torqueWrenchClassB,
    },
    { index: 9, name: RequirementLabel.DEGREASER, image: degreaser, avatarSourceId: 9, text: RequirementLabel.DEGREASER, avatarSource: degreaser },
    { index: 10, name: RequirementLabel.OTHER, image: other, avatarSourceId: 10, text: RequirementLabel.OTHER, avatarSource: other },
  ];

  //#region チップ操作
  // add chip
  const handleChipAdd = (requirement_id: number) => {
    const reqData = new RequirementRegistRequest(Number(props.operationId), requirement_id);

    postRequirement(reqData)
      .then(
        (data) => {
          mutateRequirementList();
          snackBarMessage.setMessage(SuccessMessages.INSERT_REQUIREMENT, "success");
        },
        (error) => {
          snackBarMessage.setMessage(ErrorMessages.INSERT_REQUIREMENT, "error");
        }
      );
  };

  const handleChipUpdate = (requirementNecessityId: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    //その他内容修正APIを呼ぶ
    putRequirementOther({
      requirement_necessity_id: requirementNecessityId,
      content: e.currentTarget.value,
    } as ApiBody<"/operation/requirement_other", "put">)
      .then(() => {
        mutateRequirementList();
        snackBarMessage.setMessage(SuccessMessages.INSERT_REQUIREMENT, "success");
      })
      .catch((error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_REQUIREMENT, "error");
      });
  };

  // delete chip
  const handleChipDelete = (requirementNecessityId: number) => {
    deleteRequirement(String(requirementNecessityId)).then(
      (data) => {
        mutateRequirementList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_REQUIREMENT, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.DELETE_REQUIREMENT, "error");
      }
    );
  };
  //#endregion

  const dialogRequirementList = useMemo(() => {
    if (!requirementList || !requirementList.requirement) return [];

    const displayItem = requirementItems.filter((item) => {
      if (item.avatarSourceId === 10) {
        return true;
      }
      if (requirementList.requirement.find((e) => e.requirement_id === item.avatarSourceId)) {
        return false;
      }
      return true;
    });
    return displayItem ? displayItem : [];
  }, [requirementList]);

  useEffect(() => {
    getRequirementList(props.operationId);
  }, [props.operationId]);

  // SWR error
  useEffect(() => {
    if (requirementError) snackBarMessage.setMessage(ErrorMessages.GET_REQUIREMENT, "error");
  }, [requirementError]);

  return (
    <div className={styles.outterDiv}>
      <div className={styles.innerDiv}>
        {requirementList?.requirement.map((requirement) => {
          if (requirement.requirement_id === 10) {
            return (
              <RequirementsChipForEdit
                content={requirement.content}
                icon={other}
                requirementNecessityId={requirement.requirement_necessity_id}
                handleDelete={handleChipDelete}
                handleUpdate={handleChipUpdate}
              />
            );
          }
          return <RequirementChip requirementData={requirement} handleDelete={handleChipDelete} requirementItems={requirementItems} />;
        })}
        {EditContext.editMode && <DialogRequirement AddHandle={handleChipAdd} requirementItems={dialogRequirementList} />}
      </div>
    </div>
  );
};

export default RequirementChipGroup;
