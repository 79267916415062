import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { WorkStandardInfo } from "./WorkStandardInfo";
import { SelectedConditions, SelectedOperationInfo } from "../../types/workStandard";
import { HistoryLimits } from "../../consts/inputLimits";
import { Box, Button, createStyles, IconButton, makeStyles, Switch, Tab, Tabs, Theme, Toolbar, Tooltip, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { EditModeLabel, RevisionNumberLabel, WorkFlowButtonLabel, WorkFlowStatusNamesLabel, WorkStandardPageLabel } from "../../consts/label";
import { postOperatorSignature, useCanOperatorSign, useOperation } from "../../apicaller/repository/operation";
import { Authorized } from "../../consts/authorized";
import { DialogMessages, ErrorMessages, SuccessMessages, TooltipMessages, WarningMessages } from "../../consts/messages";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { WorkFlowStatus } from "../../consts/workFlow";
import { putEditingOff, putEditingOn, useRejectMessage } from "../../apicaller/repository/workstandard";
import { isLengthCorrect } from "../../function/validationCheck";
import {
  ApplicationRequest,
  ApproverRequest,
  CancelUnderRevisionRequest,
  ConfirmerRequest,
  ModifyRequest,
  ModifyUnderRevisionRequest,
  OperatorSignRequest,
  PublishRequest,
  PublishUnderRevisionRequest,
  RevertRequest,
  ReviseRequest,
  RevisingApplicationRequest,
  RevisingApproveRequest,
} from "../../apicaller/domain/request/workflow";
import {
  postApprover,
  postConfirmer,
  postReviseRequest,
  putApplicationRequest,
  putArchiveApplicationRequest,
  putArchiveApproveRequest,
  putArchiveRejectRequest,
  putCancelUnderRevision,
  putModify,
  putModifyUnderRevision,
  putPublishRequest,
  putPublishUnderRevisionRequest,
  putReject,
  putRevert,
  putRevisingApplicationRequest,
  putRevisingApprove,
  useAuthorized,
  useUnderRevision,
} from "../../apicaller/repository/workflow";
import { OperationHistory } from "../Home/display/home";
import { WorkStandardIdRequest } from "../../apicaller/domain/request/workstandard";
import { EditInfo, InnerAreaSize, LoginInfo, Message } from "../../context/appContextData";
import { Authority } from "../../consts/authority";
import { Handler } from "../../editor/components/canvas";
import { StatusCodes } from "http-status-codes";
import PropTypes from "prop-types";
import { Errors } from "../../apicaller/domain/response/common";
import { WorkTab } from "./WorkTab";
import { ManagementTab } from "./ManagementTab";
import { useGetElementProperty } from "../../hooks/getElementProperty";
import { HistoryDetailTab } from "./HistoryDetailTab";
import Loading from "../Common/Loading";
import ApplyUnderRevisionDialog from "../Home/ApplyUnderRevisionDialog";
import { useTheme } from "@material-ui/core/styles";
import { code } from "../../editor/components/canvas/constants";
import { OkCancelDialog } from "../Common/OkCancelDialog";
import { WorkStandardDialog } from "./WorkStandardDialog";
import { RejectMessageDialog } from "./RejectMessageDialog";
import { ApiBody } from "../../utils/schemaUtil";

type Props = {
  showSideBar: boolean;
  drawerOpen?: boolean;
  selectedConditions: SelectedConditions;
  selectedWorkStandardInfo: SelectedOperationInfo;
  handleWorkStandardDrawerInvert?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: -15,
    },
    button: {
      marginInline: theme.spacing(1),
    },
    iconButton: {
      marginRight: "15px",
      marginLeft: "-5px",
    },
    revisionNumber: {
      fontWeight: "bold",
    },
    errMessage: {
      color: "red",
      margin: "10px",
      fontWeight: "bold",
    },
    infoButton: {
      color: "white",
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  })
);

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export const WorkStandard = (props: Props) => {
  const styles = useStyles();
  const theme = useTheme();
  const LoginContext = useContext(LoginInfo);
  const snackBarMessage = useContext(Message);
  const EditContext = useContext(EditInfo);
  const InnerAreaSizeContext = useContext(InnerAreaSize);
  const element = useRef<HTMLDivElement | null>(null);
  const wsInfoRef = useRef(null);
  const tabBarRef = useRef(null);

  const handleChangeTab = (event: any, index: React.SetStateAction<number>) => setTabIndex(index);

  const [operation, getOperation, mutateOperation, operationError] = useOperation();
  const [
    underRevisionWorkStandard,
    getUnderRevisionWorkStandard,
    resetUnderRevisionWorkStandard,
    mutateUnderRevisionWorkStandard,
    underRevisionWorkStandardError,
  ] = useUnderRevision();
  const [authorized, getAuthorized, resetAuthorized, mutateAuthorized, authorizedError] = useAuthorized();
  const [canOperatorSign, getCanOperatorSign, resetCanOperatorSign, mutateCanOperatorSign, CanOperatorSignError] = useCanOperatorSign();
  const [rejectMessage, getRejectMessage, resetRejectMessage, mutateRejectMessage, rejectMessageError] = useRejectMessage(
    props.selectedWorkStandardInfo.operation_id,
    LoginContext.loginUser.authority !== Authority.GENERAL
  );

  const [workStandardDialogOpen, setWorkStandardDialogOpen] = useState<boolean>(false);
  const [selectRevisionWorkStandardInfo, setSelectRevisionWorkStandardInfo] = useState<SelectedOperationInfo>({
    operation_id: props.selectedWorkStandardInfo.operation_id,
    managed_number: props.selectedWorkStandardInfo.managed_number,
    operation_name: props.selectedWorkStandardInfo.operation_name,
  });
  const [dialogDisplayData, setDialogDisplayData] = useState<OperationHistory>(new OperationHistory());
  const [tabIndex, setTabIndex] = React.useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [isRejectMessageViewDialogOpen, setIsRejectMessageViewDialogOpen] = useState(false);
  const [isRejectMessageInputDialogOpen, setIsRejectMessageInputDialogOpen] = useState(false);
  const [width, setWidth] = useState<number | undefined>();
  const [applyButtonDisabled, setApplyButtonDisabled] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [disableEditing, setDisableEditing] = useState(false);
  const [inputRejectMessage, setInputRejectMessage] = useState("");

  const wsInfoHeight = useGetElementProperty(wsInfoRef).getElementProperty("height");
  const tabBarHeight = useGetElementProperty(tabBarRef).getElementProperty("height");

  const tabContentHeight = useMemo(() => {
    return InnerAreaSizeContext.height - (wsInfoHeight + tabBarHeight);
  }, [InnerAreaSizeContext, wsInfoHeight, tabBarHeight]);

  function resizeWindow() {
    setWidth(element.current?.clientWidth);
  }

  const handleCtrlZ = (e: KeyboardEvent) => {
    if ((e.ctrlKey || e.metaKey) && e.code === code.KEY_Z) {
      e.preventDefault();
    }
  };

  const handleOpenWorkStandard = (workStandardId: number) => {
    setSelectRevisionWorkStandardInfo({ ...selectRevisionWorkStandardInfo, operation_id: workStandardId });
    setWorkStandardDialogOpen(true);
  };

  //#region UseEffect
  useEffect(() => {
    window.addEventListener("keydown", handleCtrlZ);
    return () => {
      window.removeEventListener("keydown", handleCtrlZ);
    };
  }, []);

  useEffect(() => {
    setWidth(element.current?.clientWidth);
    window.addEventListener("resize", resizeWindow);
  }, [element]);

  useEffect(() => {
    getOperation(String(props.selectedWorkStandardInfo.operation_id));
    getRejectMessage(props.selectedWorkStandardInfo.operation_id);
  }, [props.selectedWorkStandardInfo.operation_id]);

  useEffect(() => {
    if (operation?.editting_user_id === 0) {
      // 編集中のユーザーがいないときEditトグルをオフにする
      updateEditMode(false);
      setDisableEditing(false);
    } else if (operation?.editting_user_id !== LoginContext.loginUser.user_id) {
      // 他のユーザーが編集中のときEditトグルを非活性にする
      updateEditMode(false);
      setDisableEditing(true);
    } else if (operation?.editting_user_id === LoginContext.loginUser.user_id) {
      // ログインユーザーが編集中のとき
      updateEditMode(true);
      setDisableEditing(false);
    }
  }, [operation]);

  useEffect(() => {
    if (!operation?.operation_id) return;
    getUnderRevisionWorkStandard(String(operation.operation_id));
    getAuthorized(String(operation.operation_id), String(LoginContext.loginUser.user_id));
    getCanOperatorSign(String(operation.operation_id));
  }, [operation]);

  useEffect(() => {
    if (!operation?.operation_id) return;

    mutateUnderRevisionWorkStandard();
    mutateAuthorized();
    mutateCanOperatorSign();
  }, [operation?.editting_user_id]);

  useEffect(() => {
    if (operationError !== undefined) {
      //returnするコンポーネントが変わるのでsnackbarMessageいらない
    }
    if (underRevisionWorkStandardError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_UNDER_REVISION, "error");
    }
    if (authorizedError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_AUTHORIZED, "error");
    }
    if (CanOperatorSignError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_CAN_OPERATOR_SIGN, "error");
    }
    if (rejectMessageError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_WORK_STANDARD_REJECT_MESSAGE, "error");
    }
  }, [underRevisionWorkStandardError, authorizedError, operationError, CanOperatorSignError, rejectMessageError]);

  useEffect(() => {
    if (!operation?.illustration_s3_key) return;
    Handler.key = operation.illustration_s3_key;
    Handler.modelId = String(props.selectedConditions.model.key);
  }, [operation?.illustration_s3_key, props.selectedConditions.model.key]);
  //#endregion

  //#region ボタン表示非表示
  const isWorkFlowButtonVisibleWithoutConfirmApprove = () => {
    return !(operation?.status === WorkFlowStatus.PUBLISHED || operation?.status === WorkFlowStatus.ARCHIVED);
  };

  const isApplicationButtonVisible = () => {
    return operation?.status === WorkFlowStatus.EDITING && LoginContext.loginUser.authority >= 1;
  };

  const isConfirmButtonVisible = () => {
    if (operation?.status === WorkFlowStatus.ARCHIVED) return false;

    if (operation?.status === WorkFlowStatus.PUBLISHED) {
      return authorized?.authorized === Authorized.CONFIRM && !authorized?.executed;
    }

    return true;
  };

  const isApproveButtonVisible = () => {
    if (operation?.status === WorkFlowStatus.ARCHIVED) return false;

    if (operation?.is_waiting_approve_archive) return false;

    if (operation?.status === WorkFlowStatus.PUBLISHED) {
      return authorized?.authorized === Authorized.APPROVE && !authorized?.executed;
    }

    return true;
  };
  
  const isConfirmButtonDisabled = () => {
    return authorized?.authorized === Authorized.CONFIRM && !authorized?.executed;
  };

  const isApproveButtonDisabled = () => {
    return authorized?.authorized === Authorized.APPROVE && !authorized?.executed;
  };

  const isRevertButtonVisible = () => {
    return (
      (operation?.status === WorkFlowStatus.WAITING_CONFIRM || operation?.status === WorkFlowStatus.WAITING_APPROVE) &&
      (LoginContext.loginUser.user_id === operation?.author_id || LoginContext.loginUser.authority >= 1)
    );
  };

  const isRejectButtonVisible = () => {
    return (
      (operation?.status === WorkFlowStatus.WAITING_CONFIRM &&
        (LoginContext.loginUser.authority === 2 || (authorized?.authorized === Authorized.CONFIRM && !authorized?.executed))) ||
      (operation?.status === WorkFlowStatus.WAITING_APPROVE &&
        (LoginContext.loginUser.authority === 2 || (authorized?.authorized === Authorized.APPROVE && !authorized?.executed)))
    );
  };

  const isRejectMessageButtonVisible = () => {
    return (
      (operation?.status === WorkFlowStatus.EDITING ||
        operation?.status === WorkFlowStatus.WAITING_CONFIRM ||
        operation?.status === WorkFlowStatus.WAITING_APPROVE ||
        operation?.status === WorkFlowStatus.REJECTED) &&
      LoginContext.loginUser.authority >= Authority.EDITOR &&
      rejectMessage !== undefined &&
      rejectMessage.login_id.length !== 0
    );
  };

  const isModifyButtonVisible = () => {
    return operation?.status === WorkFlowStatus.REJECTED && LoginContext.loginUser.authority >= 1;
  };

  const isPublishButtonVisible = () => {
    return operation?.status === WorkFlowStatus.APPROVED && LoginContext.loginUser.authority >= 1;
  };

  const isArchiveApplicationButtonVisible = () => {
    return (
      operation?.status === WorkFlowStatus.PUBLISHED &&
      !operation?.is_waiting_approve_archive &&
      !operation?.is_link_not_archived_management_book &&
      LoginContext.loginUser.authority >= Authority.EDITOR
    );
  };

  const isArchiveApproveAndRejectButtonVisible = () => {
    return (
      operation?.status === WorkFlowStatus.PUBLISHED &&
      operation?.is_waiting_approve_archive &&
      !operation?.is_link_not_archived_management_book &&
      LoginContext.loginUser.authority >= Authority.EDITOR &&
      authorized?.authorized === Authorized.APPROVE
    );
  };

  const isRevisingButtonVisible = () => {
    return (
      operation?.status === WorkFlowStatus.PUBLISHED &&
      LoginContext.loginUser.authority >= 1 &&
      underRevisionWorkStandard !== undefined &&
      underRevisionWorkStandard?.under_revision_operation_id !== 0
    );
  };

  const isOperatorSignButtonVisible = (): boolean => {
    return canOperatorSign?.can_operator_sign;
  };

  const isEditSwitchVisible = () => {
    return operation?.status === WorkFlowStatus.EDITING && LoginContext.loginUser.authority >= 1;
  };

  const isEditingOffButtonVisible = () => {
    return (
      operation?.status === WorkFlowStatus.EDITING &&
      LoginContext.loginUser.authority === Authority.ADMIN &&
      operation?.editting_user_id !== 0 &&
      operation?.editting_user_id !== LoginContext.loginUser.user_id
    );
  };
  //#endregion

  //#region ボタン押下処理
  // 承認依頼ボタン押下時処理
  const handleApplication = () => {
    if (EditContext.editMode) {
      alert(DialogMessages.PROMPTS_TO_DISABLE_EDIT_MODE);
      return;
    }

    if (operation?.original_operation_id === 0) {
      const body = new ApplicationRequest();
      body.operation_id = props.selectedWorkStandardInfo.operation_id;
      putApplicationRequest(body)
        .then(() => {
          mutateOperation();
          mutateAuthorized();
          snackBarMessage.setMessage(SuccessMessages.APPLICATION, "success");
        })
        .catch((e: Errors) => {
          if (e.status_code === StatusCodes.CONFLICT) {
            snackBarMessage.setMessage(WarningMessages.WORK_STANDARD_APPLICATION_OTHER_USER, "warning");
          } else {
            snackBarMessage.setMessage(ErrorMessages.APPLICATION, "error");
          }
        });
    } else {
      handleOpenApplicationDialog();
    }
  };

  // 引き戻しボタン押下処理
  const handleRevert = () => {
    // 改訂フローかどうか判別する
    if (operation.original_operation_id !== 0) {
      // 改訂フロー
      const reqBody = new CancelUnderRevisionRequest(props.selectedWorkStandardInfo.operation_id);
      putCancelUnderRevision(reqBody).then(
        () => {
          mutateAuthorized();
          mutateOperation();
          snackBarMessage.setMessage(SuccessMessages.CANCEL, "success");
        },
        (error) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.CANCEL, "error");
        }
      );
    } else {
      //新規作成
      const reqBody = new RevertRequest(props.selectedWorkStandardInfo.operation_id);
      putRevert(reqBody).then(
        (data) => {
          mutateAuthorized();
          mutateOperation();
          snackBarMessage.setMessage(SuccessMessages.CANCEL, "success");
        },
        (error) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.CANCEL, "error");
        }
      );
    }
  };

  // 確認ボタン押下処理
  const handleConfirm = () => {
    const confirmer = new ConfirmerRequest(props.selectedWorkStandardInfo.operation_id);
    postConfirmer(confirmer).then(
      (data) => {
        mutateAuthorized();
        mutateOperation();
        snackBarMessage.setMessage(SuccessMessages.CONFIRM, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.CONFIRM, "error");
      }
    );
  };

  // 承認ボタン押下処理
  const handleApprove = () => {
    if (operation.original_operation_id !== 0 && operation?.status === WorkFlowStatus.WAITING_APPROVE) {
      // 改訂中の承認
      const revisingApprove = new RevisingApproveRequest(props.selectedWorkStandardInfo.operation_id);
      putRevisingApprove(revisingApprove).then(
        () => {
          mutateAuthorized();
          mutateOperation();
          snackBarMessage.setMessage(SuccessMessages.APPROVE, "success");
        },
        (error) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.APPROVE, "error");
        }
      );
    } else {
      // 新規作成の承認
      const approver = new ApproverRequest(props.selectedWorkStandardInfo.operation_id);
      postApprover(approver).then(
        (data) => {
          mutateAuthorized();
          mutateOperation();
          snackBarMessage.setMessage(SuccessMessages.APPROVE, "success");
        },
        (error) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.APPROVE, "error");
        }
      );
    }
  };

  // 否認ボタン押下処理
  const handleReject = () => {
    const reqBody: ApiBody<"/work_flow/reject", "put"> = {
      operation_id: props.selectedWorkStandardInfo.operation_id,
      message: inputRejectMessage,
    };
    putReject(reqBody).then(
      (data) => {
        mutateOperation();
        mutateRejectMessage();
        setInputRejectMessage("");
        snackBarMessage.setMessage(SuccessMessages.REJECT, "success");
        setIsRejectMessageInputDialogOpen(false);
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.REJECT, "error");
      }
    );
  };

  // 修正ボタン押下処理
  const handleModify = () => {
    // 改訂フローかどうか判別する
    if (operation.original_operation_id !== 0) {
      // 改訂フロー
      const reqBody = new ModifyUnderRevisionRequest(props.selectedWorkStandardInfo.operation_id);
      putModifyUnderRevision(reqBody).then(
        () => {
          mutateOperation();
          snackBarMessage.setMessage(SuccessMessages.MODIFY, "success");
        },
        (error) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.MODIFY, "error");
        }
      );
    } else {
      // 新規作成フロー
      const reqBody = new ModifyRequest(props.selectedWorkStandardInfo.operation_id);
      putModify(reqBody).then(
        () => {
          mutateOperation();
          snackBarMessage.setMessage(SuccessMessages.MODIFY, "success");
        },
        (error) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.MODIFY, "error");
        }
      );
    }
  };

  // 発行ボタン押下
  const handlePublishRequest = () => {
    // 初版発行
    if (operation.original_operation_id === 0) {
      const body = new PublishRequest(props.selectedWorkStandardInfo.operation_id);
      putPublishRequest(body).then(
        (data: any) => {
          mutateOperation();
          snackBarMessage.setMessage(SuccessMessages.PUBLISH, "success");
        },
        (error: any) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.PUBLISH, "error");
        }
      );
    } else {
      // 改訂発行
      const body = new PublishUnderRevisionRequest(props.selectedWorkStandardInfo.operation_id);
      putPublishUnderRevisionRequest(body).then(
        (data: any) => {
          mutateOperation();
          mutateUnderRevisionWorkStandard();
          mutateCanOperatorSign();
          snackBarMessage.setMessage(SuccessMessages.PUBLISH, "success");
        },
        (error: any) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.PUBLISH, "error");
        }
      );
    }
  };

  // アーカイブ承認依頼ボタン押下
  const handleArchiveApplicationRequest = () => {
    putArchiveApplicationRequest(props.selectedWorkStandardInfo.operation_id)
      .then(() => {
        snackBarMessage.setMessage(SuccessMessages.ARCHIVE_APPLICATION, "success");
        mutateOperation();
        mutateAuthorized();
      })
      .catch((error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.ARCHIVE_APPLICATION, "error");
      });
  };

  // アーカイブ否認ボタン押下
  const handleArchiveRejectRequest = () => {
    putArchiveRejectRequest(props.selectedWorkStandardInfo.operation_id)
      .then(() => {
        snackBarMessage.setMessage(SuccessMessages.ARCHIVE_REJECT, "success");
        mutateOperation();
        mutateAuthorized();
      })
      .catch((error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.ARCHIVE_REJECT, "error");
      });
  };

  // アーカイブ承認ダイアログの承認ボタン押下
  const handleArchiveApproveRequest = () => {
    putArchiveApproveRequest(props.selectedWorkStandardInfo.operation_id)
      .then(() => {
        snackBarMessage.setMessage(SuccessMessages.ARCHIVE_APPROVE, "success");
        setIsArchiveDialogOpen(false);
        mutateOperation();
      })
      .catch((error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.ARCHIVE_APPROVE, "error");
      });
  };

  // 改訂中ボタン押下
  const handleRevisingRequest = () => {
    // 現在改訂中の作業標準があるかチェック
    getUnderRevisionWorkStandard(String(props.selectedWorkStandardInfo.operation_id));
    if (underRevisionWorkStandard?.under_revision_operation_id === 0) {
      snackBarMessage.setMessage(ErrorMessages.GET_WORK_STANDARD, "error");
      return;
    }

    // 改訂中の作業標準を表示
    handleOpenWorkStandard(underRevisionWorkStandard?.under_revision_operation_id);
  };

  // 改訂内容入力ダイアログを開く
  const handleOpenApplicationDialog = () => {
    if (operation?.status !== WorkFlowStatus.EDITING) {
      snackBarMessage.setMessage(WarningMessages.HOME_REVISED_APPROVE_REQUEST, "warning");
      return;
    }
    setDialogDisplayData({
      operation_history_id: "",
      operation_id: "",
      date: "",
      title: "",
      detail: "",
      approver_name: "",
      approver_login_id: "",
      author_name: LoginContext.loginUser.user_name,
      author_login_id: "",
      revision_number: "",
    });
    setIsDialogOpen(true);
  };

  // 改訂内容入力ダイアログの概要を変更
  const handleChangeApplicationDialogTitle = (title: string) => {
    dialogDisplayData.title = title;
    setDialogDisplayData({ ...dialogDisplayData });
  };

  // 改訂内容入力ダイアログの詳細を変更
  const handleChangeApplicationDialogDetail = (detail: string) => {
    dialogDisplayData.detail = detail;
    setDialogDisplayData({ ...dialogDisplayData });
  };

  // 改訂内容入力ダイアログの承認依頼ボタン押下処理
  const handleApplyApplicationDialog = () => {
    if (!isLengthCorrect(dialogDisplayData.title, 1, HistoryLimits.INPUT_TITLE_MAX_LENGTH)) {
      snackBarMessage.setMessage(WarningMessages.HOME_HISTORY_TITLE_INPUT_LENGTH, "warning");
      return;
    }
    if (!isLengthCorrect(dialogDisplayData.detail, 1, HistoryLimits.INPUT_DETAIL_MAX_LENGTH)) {
      snackBarMessage.setMessage(WarningMessages.HOME_HISTORY_DETAIL_INPUT_LENGTH, "warning");
      return;
    }

    setApplyButtonDisabled(true);

    const reqData = new RevisingApplicationRequest(props.selectedWorkStandardInfo.operation_id, dialogDisplayData.title, dialogDisplayData.detail);
    putRevisingApplicationRequest(reqData)
      .then(() => {
        mutateOperation();
        mutateAuthorized();
        setIsDialogOpen(false);
        snackBarMessage.setMessage(SuccessMessages.APPLICATION, "success");
      })
      .catch((e: Errors) => {
        if (e.status_code === StatusCodes.CONFLICT) {
          snackBarMessage.setMessage(WarningMessages.WORK_STANDARD_APPLICATION_OTHER_USER, "warning");
        } else {
          snackBarMessage.setMessage(ErrorMessages.APPLICATION, "error");
        }
      })
      .finally(() => {
        setApplyButtonDisabled(false);
      });
  };

  // 改訂内容入力ダイアログのキャンセルボタン押下処理
  const handleCancelApplicationDialog = () => {
    setIsDialogOpen(false);
  };

  const updateEditMode = (mode: boolean) => {
    if (mode) {
      if (!EditContext.editMode) {
        EditContext.invert();
      }
    } else {
      if (EditContext.editMode) {
        EditContext.invert();
      }
    }
  };

  // 作業者サインボタン押下処理
  const handleOperatorSign = () => {
    const req = new OperatorSignRequest(props.selectedWorkStandardInfo.operation_id);
    postOperatorSignature(req).then(
      () => {
        mutateCanOperatorSign();
        mutateOperation();
        snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATOR_SIGNATURE, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATOR_SIGNATURE, "error");
      }
    );
  };

  const handleEditingOff = () => {
    const req = new WorkStandardIdRequest(props.selectedWorkStandardInfo.operation_id);
    const result: boolean = window.confirm(DialogMessages.EDIT_MODE_OFF_ADMIN);
    if (result) {
      putEditingOff(req)
        .then(() => {
          updateEditMode(false);
        })
        .catch((err: Errors) => {
          console.log(err);
          snackBarMessage.setMessage(ErrorMessages.UPDATE_EDITING_OFF, "error");
        });
    }
  };

  const handleEditModeChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const req = new WorkStandardIdRequest(props.selectedWorkStandardInfo.operation_id);
    if (checked) {
      if (window.confirm(DialogMessages.EDIT_MODE_ON)) {
        putEditingOn(req)
          .then(() => {
            updateEditMode(true);
          })
          .catch((e: Errors) => {
            if (e.status_code === StatusCodes.CONFLICT) {
              snackBarMessage.setMessage(WarningMessages.WORK_STANDARD_EDITING_ON_OTHER_USER, "warning");
            } else {
              snackBarMessage.setMessage(ErrorMessages.UPDATE_EDITING_ON, "error");
            }
          });
      }
    } else {
      if (window.confirm(DialogMessages.EDIT_MODE_OFF)) {
        putEditingOff(req)
          .then(() => {
            updateEditMode(false);
          })
          .catch((e: Errors) => {
            if (e.status_code === StatusCodes.CONFLICT) {
              updateEditMode(false);
              snackBarMessage.setMessage(WarningMessages.WORK_STANDARD_EDITING_OFF_OTHER_USER, "warning");
            } else {
              snackBarMessage.setMessage(ErrorMessages.UPDATE_EDITING_OFF, "error");
            }
          });
      }
    }
  };
  //#endregion

  // 作業標準取得失敗時
  if (operationError) {
    return (
      <div className={styles.root} ref={element}>
        <Typography className={styles.errMessage}>{WorkStandardPageLabel.WORK_STANDARD_GET_ERROR}</Typography>
        <Typography className={styles.errMessage}>{WorkStandardPageLabel.WORK_STANDARD_GET_ERROR_CONTACT}</Typography>
      </div>
    );
  }

  return (
    <>
      <Loading open={isProcessing} title={WorkStandardPageLabel.LOADING}></Loading>
      <WorkStandardDialog
        open={workStandardDialogOpen}
        selectedConditions={props.selectedConditions}
        selectedWorkStandardInfo={selectRevisionWorkStandardInfo}
        onClose={() => {
          setWorkStandardDialogOpen(false);
        }}
      />
      <ApplyUnderRevisionDialog
        open={isDialogOpen}
        historyDispData={dialogDisplayData}
        handleChangeTitle={handleChangeApplicationDialogTitle}
        handleChangeDetail={handleChangeApplicationDialogDetail}
        handleApply={handleApplyApplicationDialog}
        handleCancel={handleCancelApplicationDialog}
        disableRegisterButton={applyButtonDisabled}
      />
      <OkCancelDialog
        open={isArchiveDialogOpen}
        title={WorkStandardPageLabel.ARCHIVE_APPROVE_DIALOG_TITLE}
        description={WorkStandardPageLabel.ARCHIVE_APPROVE_DIALOG_MESSAGE}
        okButtonLabel={WorkStandardPageLabel.ARCHIVE_APPROVE_DIALOG_APPROVE_BUTTON}
        onOk={handleArchiveApproveRequest}
        onCancel={() => setIsArchiveDialogOpen(false)}
      />
      <RejectMessageDialog
        open={isRejectMessageViewDialogOpen}
        rejectMessage={rejectMessage}
        onClose={() => {
          setIsRejectMessageViewDialogOpen(false);
        }}
      />
      <RejectMessageDialog
        open={isRejectMessageInputDialogOpen}
        isInput
        rejectMessage={{ message: inputRejectMessage, login_id: LoginContext.loginUser.login_id, user_name: LoginContext.loginUser.user_name }}
        onChange={setInputRejectMessage}
        onClose={() => {
          setInputRejectMessage("");
          setIsRejectMessageInputDialogOpen(false);
        }}
        onReject={handleReject}
      />
      <div className={styles.root}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              padding: theme.spacing(1),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {props.showSideBar && (
              <IconButton className={styles.infoButton} size="small" onClick={props.handleWorkStandardDrawerInvert}>
                <ChevronRightIcon />
              </IconButton>
            )}
          </div>
          <WorkStandardInfo ref={wsInfoRef} selectedConditions={props.selectedConditions} selectedWorkStandardInfo={props.selectedWorkStandardInfo} />
        </div>
        <Toolbar ref={tabBarRef}>
          <Tabs value={tabIndex} onChange={handleChangeTab}>
            <Tab label={WorkStandardPageLabel.WORK_TAB} />
            <Tab label={WorkStandardPageLabel.REVISE_HISTORY_TAB} />
            <Tab label={WorkStandardPageLabel.MANAGEMENT_TAB} />
          </Tabs>

          {isWorkFlowButtonVisibleWithoutConfirmApprove() && (
            <>
              {/* 承認依頼 */}
              <Tooltip
                title={
                  disableEditing
                    ? operation?.editting_user_name + " (" + operation?.editting_login_id + ") " + TooltipMessages.EDITING_OTHER_USER
                    : ""
                }
                placement="top"
              >
                <div>
                  <Button
                    className={styles.button}
                    size={"small"}
                    variant={"contained"}
                    color={"secondary"}
                    disabled={!isApplicationButtonVisible()}
                    onClick={handleApplication}
                  >
                    {WorkFlowButtonLabel.APPLICATION}
                  </Button>
                </div>
              </Tooltip>
            </>
          )}

          {isConfirmButtonVisible() && (
            <Tooltip
              title={authorized?.authorized === Authorized.CONFIRM && authorized?.executed ? WorkFlowButtonLabel.CONFIRMED : ""}
              arrow
              placement="top"
            >
              <div>
                <Button
                  disabled={!isConfirmButtonDisabled()}
                  className={styles.button}
                  size={"small"}
                  variant={"contained"}
                  color={"secondary"}
                  onClick={handleConfirm}
                >
                  {WorkFlowButtonLabel.CONFIRM}
                </Button>
              </div>
            </Tooltip>
          )}
          {isApproveButtonVisible() && (
            <Tooltip
              title={authorized?.authorized === Authorized.APPROVE && authorized?.executed ? WorkFlowButtonLabel.APPROVED : ""}
              arrow
              placement="top"
            >
              <div>
                <Button
                  disabled={!isApproveButtonDisabled()}
                  className={styles.button}
                  size={"small"}
                  variant={"contained"}
                  color={"secondary"}
                  onClick={handleApprove}
                >
                  {WorkFlowButtonLabel.APPROVE}
                </Button>
              </div>
            </Tooltip>
          )}

          {isWorkFlowButtonVisibleWithoutConfirmApprove() && (
            <>
              {/* 引き戻し */}
              <Button
                disabled={!isRevertButtonVisible()}
                className={styles.button}
                size={"small"}
                variant={"contained"}
                color={"secondary"}
                onClick={handleRevert}
              >
                {WorkFlowButtonLabel.CANCEL}
              </Button>
              {/* 否認 */}
              <Button
                disabled={!isRejectButtonVisible()}
                className={styles.button}
                size={"small"}
                variant={"contained"}
                color={"secondary"}
                onClick={() => {
                  setIsRejectMessageInputDialogOpen(true);
                }}
              >
                {WorkFlowButtonLabel.REJECT}
              </Button>
              {/* 否認理由 */}
              <Button
                disabled={!isRejectMessageButtonVisible()}
                className={styles.button}
                size={"small"}
                variant={"contained"}
                color={"secondary"}
                onClick={() => {
                  setIsRejectMessageViewDialogOpen(true);
                }}
              >
                {WorkStandardPageLabel.REJECT_MESSAGE}
              </Button>
              {/* 修正 */}
              <Button
                disabled={!isModifyButtonVisible()}
                className={styles.button}
                size={"small"}
                variant={"contained"}
                color={"secondary"}
                onClick={handleModify}
              >
                {WorkFlowButtonLabel.MODIFY}
              </Button>
              {/* 発行 */}
              <Button
                disabled={!isPublishButtonVisible()}
                className={styles.button}
                size={"small"}
                variant={"contained"}
                color={"secondary"}
                onClick={handlePublishRequest}
              >
                {WorkFlowButtonLabel.PUBLISH}
              </Button>
            </>
          )}

          {/* アーカイブ承認系 */}
          {isArchiveApplicationButtonVisible() && (
            <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handleArchiveApplicationRequest}>
              {WorkFlowButtonLabel.ARCHIVE_APPLICATION}
            </Button>
          )}
          {isArchiveApproveAndRejectButtonVisible() && (
            <>
              <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handleArchiveRejectRequest}>
                {WorkFlowButtonLabel.ARCHIVE_REJECT}
              </Button>
              <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={() => setIsArchiveDialogOpen(true)}>
                {WorkFlowButtonLabel.ARCHIVE_APPROVE}
              </Button>
            </>
          )}

          {/* 改定中 */}
          {isRevisingButtonVisible() && (
            <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handleRevisingRequest}>
              {WorkFlowButtonLabel.REVISING}
            </Button>
          )}
          {/* 作業者確認サイン */}
          {isOperatorSignButtonVisible() && (
            <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handleOperatorSign}>
              {WorkStandardPageLabel.OPERATOR_SIGN}
            </Button>
          )}
          {/* 一般権限の場合はEditモードボタンを非表示 */}
          {isEditSwitchVisible() && (
            <>
              <Typography className={"edit-label"}>{EditModeLabel.EDIT}</Typography>
              <Tooltip
                title={
                  disableEditing ? operation.editting_user_name + " (" + operation.editting_login_id + ") " + TooltipMessages.EDITING_OTHER_USER : ""
                }
                placement="top"
              >
                <div>
                  <Switch disabled={disableEditing} checked={EditContext.editMode} onChange={handleEditModeChange} />
                </div>
              </Tooltip>
            </>
          )}
          {/* 管理者権限の場合のみ　編集強制解除ボタン　を表示 */}
          {isEditingOffButtonVisible() && (
            <Tooltip title={TooltipMessages.EDITING_OFF_ADMIN} placement="top">
              <IconButton className={styles.iconButton} size={"small"} onClick={handleEditingOff}>
                <LockOpenIcon />
              </IconButton>
            </Tooltip>
          )}
          {operation && (
            <Typography className={styles.revisionNumber}>
              {RevisionNumberLabel.PREFIX + operation.revision_number + RevisionNumberLabel.SUFFIX}: {WorkFlowStatusNamesLabel[operation.status]}{" "}
              {operation.is_waiting_approve_archive && "(" + WorkFlowStatusNamesLabel.WAITING_APPROVE_ARCHIVED + ")"}
            </Typography>
          )}
        </Toolbar>

        <TabPanel value={tabIndex} index={0}>
          <WorkTab
            contentHeight={tabContentHeight}
            operationId={String(props.selectedWorkStandardInfo.operation_id)}
            revisionNumber={operation?.revision_number}
            modelId={props.selectedConditions.model.key}
            cardWidth={width}
            workStandardName={operation?.operation_name}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <HistoryDetailTab
            contentHeight={tabContentHeight}
            workStandardId={props.selectedWorkStandardInfo.operation_id}
            workStandardRevision={operation?.revision_number}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <ManagementTab
            contentHeight={tabContentHeight}
            selectedWorkStandardInfo={props.selectedWorkStandardInfo}
            selectedConditions={props.selectedConditions}
            onClickWorkStandardHistory={handleOpenWorkStandard}
            handleOpenWorkStandard={handleOpenWorkStandard}
          />
        </TabPanel>
      </div>
    </>
  );
};
