import { useState } from "react";
import useSWR from "swr";
import { get, post, put, fetchDelete, putKeepalive } from "../apiCaller";
import { createEndPoint, ApiName, ApiMethod } from "../createEndPoint";
import {
  PostDuplicateWorkStandardRequest,
  PostHistoryDetailRequest,
  PutHistoryDetailRequest,
  WorkStandardIdRequest,
} from "../domain/request/workStandard";
import { WorkStandardHistoryDetail } from "../domain/response/workstandard";
import { ApiBody, ApiResponse } from "../../utils/schemaUtil";
import { AxiosError, AxiosResponse } from "axios";
import * as $axios from "../../utils/axiosUtils";
import { useAppSWR } from "../../hooks/useAppSWR";

export const deleteWorkStandard = (work_standard_id: string) => {
  return fetchDelete(createEndPoint({ name: ApiName.WorkStandardDelete, method: ApiMethod.Delete }, [work_standard_id]));
};

export const postDuplicateWorkStandard = (body: PostDuplicateWorkStandardRequest) => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.WorkStandardDuplicate, method: ApiMethod.Post }), bodyJson);
};

export const postMoveWorkStandard = (body: ApiBody<"/work_standard/move", "post">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/work_standard/move",
    method: "post",
    data: body,
  });
};

export const useHistoryDetail = (): [WorkStandardHistoryDetail, (work_standard_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (work_standard_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.WorkStandardHistoryDetail, method: ApiMethod.Get }, [work_standard_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

export const postHistoryDetail = (body: PostHistoryDetailRequest) => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.WorkStandardHistoryDetail, method: ApiMethod.Post }), bodyJson);
};

export const putHistoryDetail = (body: PutHistoryDetailRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.WorkStandardHistoryDetail, method: ApiMethod.Put }), bodyJson);
};

export const deleteHistoryDetail = (work_standard_history_detail_id: string) => {
  return fetchDelete(createEndPoint({ name: ApiName.WorkStandardHistoryDetail, method: ApiMethod.Delete }, [work_standard_history_detail_id]));
};

export const putEditingOn = (body: WorkStandardIdRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.EditingOn, method: ApiMethod.Put }), bodyJson);
};

export const putEditingOff = (body: WorkStandardIdRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.EditingOff, method: ApiMethod.Put }), bodyJson);
};

export const putEditingOffAll = () => {
  return putKeepalive(createEndPoint({ name: ApiName.EditingOffAll, method: ApiMethod.Put }), "");
};

export const putWorkPersonSeconds = (body: ApiBody<"/work_standard/work_person_seconds", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/work_standard/work_person_seconds",
    method: "put",
    data: body,
  });
};

export const putPublishDay = (body: ApiBody<"/work_standard/publish_day", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/work_standard/publish_day",
    method: "put",
    data: body,
  });
};

export const putPublishDayAll = (body: ApiBody<"/work_standard/publish_day_all", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/work_standard/publish_day_all",
    method: "put",
    data: body,
  });
};

export const useRejectMessage = (workStandardId: number, available:boolean): [
  ApiResponse<"/work_standard/reject_message", "get"> | undefined,
  (work_standard_id: number) => void,
  () => void,
  () => void,
  AxiosError | undefined
] => {
  const [stateData, setStateData] = useState<number>(workStandardId);
  const { data, mutate, error } = useAppSWR(available?{
    url: "/work_standard/reject_message",
    method: "get",
    params: {
      work_standard_id: stateData,
    },
  }:undefined);

  const getData = (work_standard_id: number) => {
    mutate();
    setStateData(work_standard_id);
  };
  const resetData = () => {
    mutate(undefined, false);
  };
  const mutateData = () => {
    mutate();
  };
  return [data, getData, resetData, mutateData, error];
};

export const putWorkStandardWorkPointOrder = (body: ApiBody<"/work_standard/work_point_order", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/work_standard/work_point_order",
    method: "put",
    data: body,
  });
};

export const putWorkStandardSingleIllustration = (body: ApiBody<"/work_standard/single_illustration", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/work_standard/single_illustration",
    method: "put",
    data: body,
  });
};

