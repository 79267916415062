import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { isLengthCorrect } from "../../function/validationCheck";
import { OPERATION_NAME_MAX_LENGTH, OPERATION_NAME_MIN_LENGTH } from "../../pages/CreateNew";
import { ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import { OperationRegistRequest } from "../../apicaller/domain/request/operation";
import { getSignedUrlForPut, postOperation } from "../../apicaller/repository/operation";
import { Message } from "../../context/appContextData";
import { v4 as uuidv4 } from "uuid";
import { putAws } from "../../apicaller/repository/fileupload";
import illustration_template from "../../context/illustration_template.json";
import ListBox, { ListOption } from "../Common/ListBox";
import { CreateNewPageLabel, Label } from "../../consts/label";
import Loading from "../Common/Loading";
import { Selector } from "./Selector";
import { postDuplicateWorkStandard } from "../../apicaller/repository/workstandard";
import { PostDuplicateWorkStandardRequest } from "../../apicaller/domain/request/workstandard";
import { ArrowBack } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { WorkStandardDialog } from "../WorkStandard/WorkStandardDialog";
import { SelectedConditions, SelectedOperationInfo } from "../../types/workStandard";
import { useWorkStandardList } from "../../apicaller/repository/workstandardlist";
import { WorkStandardDialogForCreated } from "./WorkStandardDialogForCreated";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selector: {
      width: "100%",
    },
    textField: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  })
);

const CreateNewOperation = () => {
  const styles = useStyles();
  const theme = useTheme();
  const snackBarMessage = useContext(Message);

  const [workStandardList, getWorkStandardList, resetWorkStandardData, mutateWorkStandardData, WorkStandardDataError] = useWorkStandardList();

  const [isProcessing, setIsProcessing] = useState(false);
  const [checkedDuplicate, setCheckedDuplicate] = useState<boolean>(false);
  const [selectFactoryItem, setSelectFactoryItem] = useState<ListOption>(null);
  const [selectPlantItem, setSelectPlantItem] = useState<ListOption>(null);
  const [selectModuleItem, setSelectModuleItem] = useState<ListOption>(null);
  const [selectUnitItem, setSelectUnitItem] = useState<ListOption>(null);
  const [selectTeamItem, setSelectTeamItem] = useState<ListOption>(null);
  const [selectModelItem, setSelectModelItem] = useState<ListOption>(null);
  const [operationName, setOperationName] = useState("");

  const [copySourceFactoryItem, setCopySourceFactoryItem] = useState<ListOption>(null);
  const [copySourcePlantItem, setCopySourcePlantItem] = useState<ListOption>(null);
  const [copySourceModuleItem, setCopySourceModuleItem] = useState<ListOption>(null);
  const [copySourceUnitItem, setCopySourceUnitItem] = useState<ListOption>(null);
  const [copySourceTeamItem, setCopySourceTeamItem] = useState<ListOption>(null);
  const [copySourceModelItem, setCopySourceModelItem] = useState<ListOption>(null);
  const [copySourceWorkStandardItem, setCopySourceWorkStandardItem] = useState<ListOption>(null);
  const [workStandardDialogOpen, setWorkStandardDialogOpen] = useState<boolean>(false);

  const [openCreatedWorkStandard, setOpenCreatedWorkStandard] = useState<boolean>(false);
  const [createdWorkStandardId, setCreatedWorkStandardId] = useState<number>(0);

  const selectedConditions: SelectedConditions = useMemo(() => {
    return {
      factory: copySourceFactoryItem,
      plant: copySourcePlantItem,
      module: copySourceModuleItem,
      unit: copySourceUnitItem,
      team: copySourceTeamItem,
      model: copySourceModelItem,
    };
  }, [copySourceFactoryItem, copySourcePlantItem, copySourceModuleItem, copySourceUnitItem, copySourceTeamItem, copySourceModelItem]);

  const selectedOperationInfo: SelectedOperationInfo = useMemo(() => {
    const managed_number = workStandardList?.operation_list.find((ws) => ws.operation_id === copySourceWorkStandardItem?.key)?.managed_number;

    return {
      operation_id: copySourceWorkStandardItem?.key,
      managed_number: managed_number ?? 0,
      operation_name: copySourceWorkStandardItem?.value,
    };
  }, [copySourceWorkStandardItem]);

  useEffect(() => {
    if (WorkStandardDataError) {
      snackBarMessage.setMessage(ErrorMessages.GET_WORK_STANDARD_LIST, "error");
    }
  }, [WorkStandardDataError]);

  useEffect(() => {
    setCopySourceWorkStandardItem(null);

    if (!copySourceTeamItem || !copySourceModelItem) {
      resetWorkStandardData();
      return;
    }
    getWorkStandardList(String(copySourceTeamItem.key), String(copySourceModelItem.key));
  }, [copySourceTeamItem, copySourceModelItem]);

  const workStandardOptions = useMemo(() => {
    const options: ListOption[] = [];
    workStandardList?.operation_list.forEach((ws) => {
      options.push({ key: ws.operation_id, value: "(第" + ws.revision_number + "版) " + ws.managed_number + ": " + ws.operation_name });
    });
    return options;
  }, [workStandardList]);

  //新規作成処理
  const handleRegist = async () => {
    // 新規作成処理中チェック(連打防止)
    if (isProcessing) return;

    if (!selectFactoryItem) {
      snackBarMessage.setMessage(WarningMessages.CREATENEW_SELECTED_FACTORY, "warning");
      return;
    }

    if (!selectPlantItem) {
      snackBarMessage.setMessage(WarningMessages.CREATENEW_SELECTED_PLANT, "warning");
      return;
    }

    if (!selectModuleItem) {
      snackBarMessage.setMessage(WarningMessages.CREATENEW_SELECTED_MODULE, "warning");
      return;
    }

    if (!selectUnitItem) {
      snackBarMessage.setMessage(WarningMessages.SELECTED_UNIT, "warning");
      return;
    }

    if (!selectTeamItem) {
      snackBarMessage.setMessage(WarningMessages.SELECTED_TEAM, "warning");
      return;
    }

    if (!selectModelItem) {
      snackBarMessage.setMessage(WarningMessages.CREATENEW_SELECTED_MODEL, "warning");
      return;
    }

    if (!isLengthCorrect(operationName, OPERATION_NAME_MIN_LENGTH, OPERATION_NAME_MAX_LENGTH)) {
      snackBarMessage.setMessage(WarningMessages.CREATENEW_NAME_INPUT_LENGTH, "warning");
      return;
    }

    if (checkedDuplicate) {
      if (
        !copySourceFactoryItem ||
        !copySourcePlantItem ||
        !copySourceModuleItem ||
        !copySourceUnitItem ||
        !copySourceTeamItem ||
        !copySourceModelItem ||
        !copySourceWorkStandardItem
      ) {
        snackBarMessage.setMessage(WarningMessages.CREATENEW_COPY_SOURCE, "warning");
        return;
      }
      // 複製する
      setIsProcessing(true);
      const req = new PostDuplicateWorkStandardRequest(copySourceWorkStandardItem.key, operationName, selectTeamItem.key, selectModelItem.key);
      postDuplicateWorkStandard(req)
        .then((data) => {
          setOpenCreatedWorkStandard(true);
          setCreatedWorkStandardId(data.operation_id);
          snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION, "success");
        })
        .catch((err) => {
          console.log(err);
          snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATION, "error");
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      // 新規作成する
      setIsProcessing(true);
      let registData = new OperationRegistRequest();
      registData.operation_name = operationName;
      registData.team_id = selectTeamItem.key;
      registData.model_id = selectModelItem.key;
      registData.illustration_s3_key = `${selectModelItem.key}/main_illustration/${uuidv4()}_illustration.json`;

      let errMessage = ErrorMessages.GET_S3_URL_OPERATION;
      getSignedUrlForPut(registData.illustration_s3_key)
        .then((url) => {
          errMessage = ErrorMessages.S3_PUT_OPERATION;
          return putAws(url, new File([JSON.stringify(illustration_template)], "illastration_template.json"));
        })
        .then(() => {
          errMessage = ErrorMessages.INSERT_OPERATION;
          return postOperation(registData);
        })
        .then((data) => {
          setOpenCreatedWorkStandard(true);
          setCreatedWorkStandardId(data.operation_id);
          snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION, "success");
        })
        .catch((err) => {
          console.log(err);
          snackBarMessage.setMessage(errMessage, "error");
          setIsProcessing(false);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  };

  return (
    <>
      <WorkStandardDialog
        open={workStandardDialogOpen}
        selectedConditions={selectedConditions}
        selectedWorkStandardInfo={selectedOperationInfo}
        onClose={() => setWorkStandardDialogOpen(false)}
      />
      <WorkStandardDialogForCreated
        open={openCreatedWorkStandard}
        selectedConditions={{
          factory: selectFactoryItem,
          plant: selectPlantItem,
          module: selectModuleItem,
          unit: selectUnitItem,
          team: selectTeamItem,
          model: selectModelItem,
        }}
        selectedWorkStandardInfo={{
          operation_id: createdWorkStandardId,
          managed_number: 0,
          operation_name: operationName,
        }}
        onClose={() => {
          setOpenCreatedWorkStandard(false);
          // 新規作成処理終了(成功時は作業標準名をクリア)
          setOperationName("");
        }}
      />
      <Loading open={isProcessing} title={CreateNewPageLabel.LOADING}></Loading>
      <Grid container spacing={1} style={{ padding: theme.spacing(0, 1) }}>
        <Grid item xs={12}>
          <Typography variant={"h4"}>{CreateNewPageLabel.TITLEL}</Typography>
        </Grid>
        <Grid item xs={5} md={4}>
          {checkedDuplicate && <Typography variant={"h6"}>{CreateNewPageLabel.COPY_TO}</Typography>}
          <div className={styles.selector}>
            <Selector
              factoryValue={selectFactoryItem}
              plantValue={selectPlantItem}
              moduleValue={selectModuleItem}
              unitValue={selectUnitItem}
              teamValue={selectTeamItem}
              modelValue={selectModelItem}
              onChangeFactory={setSelectFactoryItem}
              onChangePlant={setSelectPlantItem}
              onChangeModule={setSelectModuleItem}
              onChangeUnit={setSelectUnitItem}
              onChangeTeam={setSelectTeamItem}
              onChangeModel={setSelectModelItem}
            />
            <TextField
              className={styles.textField}
              label={CreateNewPageLabel.WORK_STANDARD_NAME}
              variant={"outlined"}
              onChange={(e) => {
                setOperationName(e.currentTarget.value);
              }}
              value={operationName}
              inputProps={{ maxLength: OPERATION_NAME_MAX_LENGTH }}
            />
          </div>
        </Grid>
        <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
          <ArrowBack fontSize={"large"} color={checkedDuplicate ? "inherit" : "disabled"} style={{ textAlign: "center", width: "100%" }} />
        </Grid>
        <Grid item xs={5} md={4}>
          {checkedDuplicate && <Typography variant={"h6"}>{CreateNewPageLabel.COPY_FROM}</Typography>}
          <div className={styles.selector}>
            <Selector
              disabled={!checkedDuplicate}
              factoryValue={copySourceFactoryItem}
              plantValue={copySourcePlantItem}
              moduleValue={copySourceModuleItem}
              unitValue={copySourceUnitItem}
              teamValue={copySourceTeamItem}
              modelValue={copySourceModelItem}
              onChangeFactory={setCopySourceFactoryItem}
              onChangePlant={setCopySourcePlantItem}
              onChangeModule={setCopySourceModuleItem}
              onChangeUnit={setCopySourceUnitItem}
              onChangeTeam={setCopySourceTeamItem}
              onChangeModel={setCopySourceModelItem}
            />
            <div style={{ display: "flex" }}>
              <ListBox
                disable={!checkedDuplicate}
                label={Label.WORK_STANDARD}
                options={workStandardOptions}
                value={copySourceWorkStandardItem}
                onChange={setCopySourceWorkStandardItem}
              />
              <IconButton disabled={!checkedDuplicate || !copySourceWorkStandardItem} onClick={() => setWorkStandardDialogOpen(true)}>
                <VisibilityIcon />
              </IconButton>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <Button variant="contained" color="secondary" style={{ marginRight: theme.spacing(3) }} onClick={handleRegist}>
              {CreateNewPageLabel.CREATE_BUTTON_LABEL}
            </Button>
            <FormControlLabel
              control={<Checkbox checked={checkedDuplicate} onChange={() => setCheckedDuplicate(!checkedDuplicate)} />}
              label={CreateNewPageLabel.COPY_CHECKBOX_LABEL}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};
export default CreateNewOperation;
