import React, { useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Message } from "../../context/appContextData";
import { ErrorMessages, SuccessMessages } from "../../consts/messages";
import { DialogButtonLabel, ManagementBookManagementTabLabel, WorkStandardManagementTabLabel } from "../../consts/label";
import { putPublishDay } from "../../apicaller/repository/managementbook";

export interface Props {
  open: boolean;
  workStandardId: number;
  onCancel: () => void;
}

export const PublishDayPickerDialog = (props: Props) => {
  const snackBarMessage = useContext(Message);

  //get the current date
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = () => {
    putPublishDay({
      management_book_id: props.workStandardId,
      created_at: selectedDate.toLocaleString('ja-JP', { timeZone: 'UTC' }),
    })
      .then(() => {
        snackBarMessage.setMessage(SuccessMessages.PUT_PUBLISH_DAY, "success");
        props.onCancel();
      })
      .catch((error) => {
        snackBarMessage.setMessage(ErrorMessages.PUT_PUBLISH_DAY, "error");
        console.error(error);
      });
  };

  return (
    <Dialog open={props.open} fullWidth maxWidth={"sm"}>
      <DialogTitle style={{ textAlign: "center" }}>{ManagementBookManagementTabLabel.PUBLISH_DAY_EDIT_TITLE}</DialogTitle>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="yyyy/MM/dd"
          margin="normal"
          id="date-picker-inline"
          label={ManagementBookManagementTabLabel.PUBLISH_DAY}
          style={{ width: "90%", margin: "0 auto" }}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
      <DialogActions>
        <Button color="secondary" onClick={props.onCancel}>
          {DialogButtonLabel.CANCEL}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            handleTimeChange();
          }}
        >
          {DialogButtonLabel.REGIST}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
