import React from "react";
import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ChipDialog from "./ChipDialog";
import { WorkStandardWorkTabLabel } from "../../consts/label";

interface Props {
  AddHandle: (requirement_id: number) => void;
  requirementItems: any;
}

export default function DialogRequirement(props: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        style={{
          scrollSnapAlign: "center",
        }}
      >
        <AddCircleIcon />
      </IconButton>
      <ChipDialog
        title={props.requirementItems.length === 0 ? WorkStandardWorkTabLabel.ALL_REGISTERED : WorkStandardWorkTabLabel.ADD_REQUIREMENT}
        open={open}
        onClose={handleClose}
        AddHandle={props.AddHandle}
        DialogContents={props.requirementItems}
      />
    </div>
  );
}
