import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import { Redirect } from "react-router-dom";
import OperationList from "../components/OperationList/OperationList";
import { LoginInfo } from "../context/appContextData";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      // AppBar表示分の余白
      marginTop: 50,
      flexGrow: 1,
    },
  })
);

const OperationListPage = () => {
  const styles = useStyles();
  const LoginContext = useContext(LoginInfo);

  //権限
  if (LoginContext.loginUser.authority === 0) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={styles.container}>
      <OperationList />
    </div>
  );
};

export default OperationListPage;
