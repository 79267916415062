import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useMemo, useRef } from "react";
import AreaTable from "../components/DataManagement/AreaTable";
import { Switch, Toolbar } from "@material-ui/core";
import { EditInfo, InnerAreaSize, LoginInfo } from "../context/appContextData";
import { DialogMessages } from "../consts/messages";
import { Redirect } from "react-router-dom";
import ProductionCapacityTab from "../components/DataManagement/ProductionCapacityTab";
import { useGetElementProperty } from "../hooks/getElementProperty";
import { DataManagementPageLabel, EditModeLabel } from "../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // AppBar表示分の余白
      marginTop: 50,
      flexGrow: 1,
    },
    toolbar: {
      flexGrow: 1,
      minHeight: "36px",
    },
    tabLeftSide: {
      flexGrow: 1,
    },
    typography: {
      fontSize: 18,
      marginLeft: 10,
    },
    button: {
      fontSize: 24,
      width: 140,
      height: 50,
      position: "absolute",
      right: 40,
      bottom: 20,
    },
  })
);

export const DATA_MANAGEMENT_TITLE_MAX_LENGTH = 50;

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const DataManagement = () => {
  const styles = useStyles();

  const EditContext = useContext(EditInfo);
  const LoginContext = useContext(LoginInfo);
  const InnerAreaSizeContext = useContext(InnerAreaSize);

  const tabBarRef = useRef(null);

  const [value, setValue] = React.useState(0);

  const tabBarHeight = useGetElementProperty(tabBarRef).getElementProperty("height");

  const tabContentHeight = useMemo(() => {
    return InnerAreaSizeContext.height - tabBarHeight;
  }, [InnerAreaSizeContext, tabBarHeight]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // handle when Tab changed
  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  const handleEditModeChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let result: boolean = false;
    if (checked) {
      result = window.confirm(DialogMessages.EDIT_MODE_ON);
    } else {
      result = window.confirm(DialogMessages.EDIT_MODE_OFF);
    }
    if (result) {
      EditContext.invert();
    }
  };

  //権限
  if (LoginContext.loginUser.authority === 0) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={styles.container}>
      <Grid container>
        <Grid item sm={12}>
          <Toolbar ref={tabBarRef} className={styles.toolbar}>
            <Tabs className={styles.tabLeftSide} value={value} onChange={handleChange} aria-label="management tabs">
              <Tab label={DataManagementPageLabel.AREA_MANEGEMENT_TAB} {...a11yProps(0)} />
              <Tab label={DataManagementPageLabel.PRODUCTION_CAPACITY_MANAGEMENT_TAB} {...a11yProps(1)} />
            </Tabs>
            {/* 管理者権限のみEditモードボタンを表示 */}
            {LoginContext.loginUser.authority === 2 && (
              <>
                <Typography className={"edit-label"}>{EditModeLabel.EDIT}</Typography>
                <Switch checked={EditContext.editMode} onChange={handleEditModeChange} />
              </>
            )}
          </Toolbar>

          {/* 拠点管理タブ */}
          <TabPanel value={value} index={0}>
            <AreaTable tabContentHeight={tabContentHeight} />
          </TabPanel>
          
          {/* 生産台数管理タブ */}
          <TabPanel value={value} index={1}>
            <ProductionCapacityTab tabContentHeight={tabContentHeight} />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
};

export default DataManagement;
