import {
  LineListResponse,
  Line,
  UnitListResponse,
  UnitList,
  TeamListResponse,
  TeamList,
  ModelListResponse,
  ModelList,
} from "../../../apicaller/domain/response/datamanagement";
import { sortByAsc } from "../../../function/sort";
import { WorkStandardInfo, WorkStandardListResponse } from "../../../apicaller/domain/response/workstandardlist";
import { formatDate } from "../../../function/formatDatetime";
import { WorkFlowStatusNamesLabel } from "../../../consts/label";

export interface OperationListDisplayComboBox {
  text: string[];
}

export class LineDisplayComboBox implements OperationListDisplayComboBox {
  text: string[] = [];

  public setData(data: LineListResponse) {
    this.text = data.line.map((e: Line) => e.line_name);
  }
}

export class UnitDisplayComboBox implements OperationListDisplayComboBox {
  text: string[] = [];

  public setData(data: UnitListResponse) {
    this.text = data.unit.map((e: UnitList) => e.unit_name);
  }
}

export class TeamDisplayComboBox implements OperationListDisplayComboBox {
  text: string[] = [];

  public setData(data: TeamListResponse) {
    this.text = data.team.map((e: TeamList) => e.team_name);
  }
}

export class ModelDisplayComboBox implements OperationListDisplayComboBox {
  text: string[] = [];

  public setData(data: ModelListResponse) {
    this.text = data.model.map((e: ModelList) => e.model_name);
  }
}

export class WorkStandardListDisplayRows {
  rows: WorkStandardListDisplayRow[] = [];

  public setWorkStandardData(
    data: WorkStandardListResponse,
    selectFactoryItem: { id: number; name: string },
    selectPlantItem: { id: number; name: string },
    selectLineItem: { id: number; name: string },
    selectUnitItem: { id: number; name: string },
    selectTeamItem: { id: number; name: string },
    selectModelItem: { id: number; name: string },
    selectCheckBoxes: {
      EDITING: boolean;
      WAITING_CONFIRM: boolean;
      WAITING_APPROVE: boolean;
      REJECTED: boolean;
      APPROVED: boolean;
      PUBLISHED: boolean;
      ARCHIVED: boolean;
      ARCHIVED_WAITING_APPROVE: boolean;
    }
  ): WorkStandardListDisplayRows {
    const temp: WorkStandardListDisplayRow[] = [];
    data.operation_list.forEach((operation: WorkStandardInfo) => {
      if (selectCheckBoxes.ARCHIVED_WAITING_APPROVE) {
        if (operation.is_waiting_approve_archive) {
          const status = WorkFlowStatusNamesLabel[operation.status];
          temp.push({
            operation_id: operation.operation_id,
            team_id: operation.team_id,
            model_id: operation.model_id,
            status: status,
            operation_name: operation.operation_name,
            managed_number: operation.managed_number,
            login_id: operation.login_id,
            user_name: operation.user_name,
            created_at: formatDate(operation.created_at),
            factory_id: selectFactoryItem.id,
            factory_name: selectFactoryItem.name,
            plant_id: selectPlantItem.id,
            plant_name: selectPlantItem.name,
            line_id: selectLineItem.id,
            line_name: selectLineItem.name,
            unit_id: selectUnitItem.id,
            unit_name: selectUnitItem.name,
            team_name: selectTeamItem.name,
            model_name: selectModelItem.name,
            revision_number: operation.revision_number,
          });
        }
      } else if (selectCheckBoxes[operation.status]) {
        const status = WorkFlowStatusNamesLabel[operation.status];
        temp.push({
          operation_id: operation.operation_id,
          team_id: operation.team_id,
          model_id: operation.model_id,
          status: status,
          operation_name: operation.operation_name,
          managed_number: operation.managed_number,
          login_id: operation.login_id,
          user_name: operation.user_name,
          created_at: formatDate(operation.created_at),
          factory_id: selectFactoryItem.id,
          factory_name: selectFactoryItem.name,
          plant_id: selectPlantItem.id,
          plant_name: selectPlantItem.name,
          line_id: selectLineItem.id,
          line_name: selectLineItem.name,
          unit_id: selectUnitItem.id,
          unit_name: selectUnitItem.name,
          team_name: selectTeamItem.name,
          model_name: selectModelItem.name,
          revision_number: operation.revision_number,
        });
      }
    });
    this.rows = sortByAsc(temp, "managed_number");
    return this;
  }
}

export class WorkStandardCheckBoxes {
  work_standard_id: number
  checked: boolean
}

export class WorkStandardListDisplayRow {
  operation_id: number = 0;
  team_id: number = 0;
  model_id: number = 0;
  status: string = "";
  operation_name: string = "";
  managed_number: number = 0;
  login_id: string = "";
  user_name: string = "";
  created_at: string = "";
  factory_id: number = 0;
  factory_name: string = "";
  plant_id: number = 0;
  plant_name: string = "";
  line_id: number = 0;
  line_name: string = "";
  unit_id: number = 0;
  unit_name: string = "";
  team_name: string = "";
  model_name: string = "";
  revision_number: number = 0;
}
