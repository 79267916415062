import { cloneDeep } from "lodash";
import { get } from "../../../apicaller/apiCaller";
import { ApiMethod, ApiName, createEndPoint } from "../../../apicaller/createEndPoint";
import {
  ApproverList,
  ConfirmerList,
  HistoryList,
  OperationCard,
  OperationCardList,
  OperationSignatureList,
  PartsList,
} from "../../../apicaller/domain/response/operation";
import { sortByAsc, sortByDesc } from "../../../function/sort";
import {
  Factory,
  FactoryListResponse,
  Line,
  LineListResponse,
  ModelList,
  ModelListResponse,
  Plant,
  PlantListResponse,
  TeamList,
  TeamListResponse,
  UnitList,
  UnitListResponse,
  VolumeList,
  VolumeListResponse,
} from "../../../apicaller/domain/response/datamanagement";
import {
  WorkStandardHistoryDetail,
  WorkStandardHistoryDetailItem,
} from "../../../apicaller/domain/response/workstandard";
import { WorkStandardHistoryDetailTarget } from "../../../consts/workStandardHistoryDetailTarget";
import { ListOption } from "../../Common/ListBox";
import { formatDate, formatDatetime } from "../../../function/formatDatetime";
import { operatorRoleLabel } from "../../../consts/label";

export class SafetyPointChip {
  safetyPointId: number = 0;
  content: string = "";
  isError: Boolean = false;
}

export class SafetyPointChipList {
  chipList: SafetyPointChip[] = [];
}

export class ApproverDisplay {
  approver: string[] = [];

  public setData(approverList: ApproverList) {
    this.approver = approverList.approver.map((approver) => approver.user_name + " (" + approver.login_id + ")");
    return cloneDeep(this);
  }

  public sortData() {
    this.approver = sortByDesc(this.approver, "created_at");
    return cloneDeep(this);
  }
}

export class ConfirmerDisplay {
  confirmer: string[] = [];

  public setData(confirmerList: ConfirmerList) {
    this.confirmer = confirmerList.confirmer.map((confirmer) => confirmer.user_name + " (" + confirmer.login_id + ")");
    return cloneDeep(this);
  }

  public sortData() {
    this.confirmer = sortByDesc(this.confirmer, "created_at");
    return cloneDeep(this);
  }
}

export class CreatorDisplay {
  user_name: string = "";
  login_id: string = "";

  public setData(user_name: string, login_id: string) {
    this.user_name = user_name;
    this.login_id = login_id;
  }
}

export class Parts {
  no: string = "";
  name: string = "";
  count: string = "";
}

export class PartsTableInfo {
  parts: Parts[] = [];
  public setData = (data: PartsList) => {
    this.parts = data.parts.map((s) => {
      return { no: String(s.parts_id), name: s.name, count: String(s.count) };
    });
  };
}

export class OperationHistory {
  operation_history_id: string = "";
  operation_id: string = "";
  date: string = "";
  title: string = "";
  detail: string = "";
  approver_name: string = "";
  approver_login_id: string = "";
  author_name: string = "";
  author_login_id: string = "";
  revision_number: string = "";
}

export class OperationHistoryDisplayGroup {
  operationHistorys: OperationHistory[] = [];
  public setData = (data: HistoryList) => {
    this.operationHistorys = data.operation_history_list.map((s) => {
      return {
        operation_history_id: String(s.operation_history_id),
        operation_id: String(s.operation_id),
        date: formatDate(s.created_at),
        title: s.title,
        detail: s.detail,
        approver_name: s.approver_name,
        approver_login_id: String(s.approver_login_id),
        author_name: s.author_name,
        author_login_id: String(s.author_login_id),
        revision_number: String(s.revision_number),
      };
    });
  };
}

export class OperationSignatureDisplay {
  operation_id: number = 0;
  created_at: string = "";
  login_id: string = "";
  user_name: string = "";
  role: string = "";
}

export class OperationSignatureDisplayList {
  operationSignatures: OperationSignatureDisplay[] = [];
  public setData = (data: OperationSignatureList) => {
    this.operationSignatures = data.operator_signature_list.map((s) => {
      return {
        operation_id: s.operation_id,
        created_at: formatDatetime(s.created_at),
        login_id: String(s.login_id),
        user_name: s.user_name,
        role: operatorRoleLabel[s.role],
      };
    });
    return cloneDeep(this);
  };

  public sortByDesc(): OperationSignatureDisplayList {
    this.operationSignatures = sortByDesc(this.operationSignatures, "created_at");
    return cloneDeep(this);
  }
}

//---operationCard---
export class OperationCardDisplayGroup {
  operationCards: OperationCardDisplay[] = [];

  public async setData(operationCardList: OperationCardList) {
    this.operationCards = await Promise.all(
      operationCardList.operation_card.map(async (e: OperationCard): Promise<OperationCardDisplay> => {
        //取得したoperation_card_idを使って、page取得
        const ret = await get(createEndPoint({
          name: ApiName.IllustrationPageList,
          method: ApiMethod.Get,
        }, [String(e.operation_card_id)]));
        let tmpObject: IllustrationPage[] = ret.illustration_page_list.map((page: IllustrationPage) => {
          return {
            illustration_page_id: page.illustration_page_id,
            operation_card_id: page.operation_card_id,
            page: page.page,
            illustration_s3_key: page.illustration_s3_key,
          };
        });

        return {
          operation_card_id: e.operation_card_id,
          title: e.title,
          card_order: e.card_order,
          person_seconds: e.person_seconds,
          illustration_page_list: tmpObject,
        };
      }),
    );
    return cloneDeep(this);
  }

  public sortData() {
    this.operationCards = sortByAsc(this.operationCards, "card_order");
    this.operationCards.map((card)=>{
      card.illustration_page_list = sortByAsc(card.illustration_page_list,"page")
    })
    return cloneDeep(this);
  }

  public changeTitle(cardOrder: number, value: string) {
    this.operationCards[cardOrder].title = value;
    return cloneDeep(this);
  }

  public changePersonSeconds(cardOrder: number, personSeconds: string) {
    this.operationCards[cardOrder].person_seconds = Number(personSeconds);
    return cloneDeep(this);
  }

  public changeCardOrder(operationCards: OperationCardDisplay[]) {
    this.operationCards = operationCards;
    return cloneDeep(this);
  }
}

export class OperationCardDisplay {
  operation_card_id: number = 0;
  title: string = "";
  card_order: number = 0;
  person_seconds: number = 0;
  illustration_page_list: IllustrationPage[] = [];
}

export class IllustrationPage {
  illustration_page_id: number = 0;
  operation_card_id: number = 0;
  page: number = 0;
  illustration_s3_key: string = "";
}

// ---operationPoint---
export class OperationPointChip {
  operation_point_id: number = 0;
  operation_card_id: number = 0;
  page: number = 0;
  category: number = 0;
  source: string = "";
  content: string = "";
  isError: Boolean = false;
  order: number = 0;
}

export class OperationPointChipGroup {
  operation_points: OperationPointChip[] = [];
}

export class SafetyEquipmentChip {
  safety_equipment_necessity_id: number = 0;
  operation_id: number = 0;
  safety_equipment_id: number = 0;
}

export class SafetyEquipmentChipGroup {
  safety_equipment: SafetyEquipmentChip[] = [];
}

export class OperationHashItemChip {
  operation_hash_item_id: number = 0;
  operation_card_id: number = 0;
  operation_hash_master_id: number = 0;
  content: string = "";
}

export class OperationHashItemChipGroup {
  operation_hash_item_chip_group: OperationHashItemChip[] = [];
}

//選択ダイアログ表示用（ユニット）
export class DialogFactoryDisplayList {
  FactoryDisplayList: ListOption[] = [];

  public setData(factoryListResponse: FactoryListResponse): DialogFactoryDisplayList {
    var temp = factoryListResponse.factory.map((factory: Factory): ListOption => {
      return {
        key: factory.factory_id,
        value: factory.factory_name,
      };
    });
    this.FactoryDisplayList = temp;
    return this;
  }

  public sortByAsc(): DialogFactoryDisplayList {
    let sorted = new DialogFactoryDisplayList();
    sorted.FactoryDisplayList = sortByAsc(this.FactoryDisplayList, "key");
    return sorted;
  }
}

//選択ダイアログ表示用（ユニット）
export class DialogPlantDisplayList {
  PlantDisplayList: ListOption[] = [];

  public setData(plantListResponse: PlantListResponse): DialogPlantDisplayList {
    var temp = plantListResponse.plant.map((plant: Plant): ListOption => {
      return {
        key: plant.plant_id,
        value: plant.plant_name,
      };
    });
    this.PlantDisplayList = temp;
    return this;
  }

  public sortByAsc(): DialogPlantDisplayList {
    let sorted = new DialogPlantDisplayList();
    sorted.PlantDisplayList = sortByAsc(this.PlantDisplayList, "key");
    return sorted;
  }
}

//選択ダイアログ表示用（ライン）
export class DialogLineDisplayList {
  LineDisplayList: ListOption[] = [];

  public setData(lineListResponse: LineListResponse): DialogLineDisplayList {
    var temp = lineListResponse.line.map((line: Line): ListOption => {
      return {
        key: line.line_id,
        value: line.line_name,
      };
    });
    this.LineDisplayList = temp;
    return this;
  }

  public sortByAsc(): DialogLineDisplayList {
    let sorted = new DialogLineDisplayList();
    sorted.LineDisplayList = sortByAsc(this.LineDisplayList, "key");
    return sorted;
  }
}

//選択ダイアログ表示用（ユニット）
export class DialogUnitDisplayList {
  UnitDisplayList: ListOption[] = [];

  public setData(unitListResponse: UnitListResponse): DialogUnitDisplayList {
    var temp = unitListResponse.unit.map((unit: UnitList): ListOption => {
      return {
        key: unit.unit_id,
        value: unit.unit_name,
      };
    });
    this.UnitDisplayList = temp;
    return this;
  }

  public sortByAsc(): DialogUnitDisplayList {
    let sorted = new DialogUnitDisplayList();
    sorted.UnitDisplayList = sortByAsc(this.UnitDisplayList, "key");
    return sorted;
  }
}

export class DialogUnitDisplay {
  unit_id: number = 0;
  unit_name: string = "";
}

//選択ダイアログ表示用（チーム）
export class DialogTeamDisplayList {
  TeamDisplayList: ListOption[] = [];

  public setData(teamListResponse: TeamListResponse): DialogTeamDisplayList {
    var temp = teamListResponse.team.map((team: TeamList): ListOption => {
      return {
        key: team.team_id,
        value: team.team_name,
      };
    });
    this.TeamDisplayList = temp;
    return this;
  }

  public sortByAsc(): DialogTeamDisplayList {
    let sorted = new DialogTeamDisplayList();
    sorted.TeamDisplayList = sortByAsc(this.TeamDisplayList, "key");
    return sorted;
  }
}

export class DialogTeamDisplay {
  team_id: number = 0;
  team_name: string = "";
}

//選択ダイアログ表示用(機種）
export class DialogModelDisplayList {
  ModelDisplayList: ListOption[] = [];

  public setData(modelListResponse: ModelListResponse): DialogModelDisplayList {
    var temp = modelListResponse.model.map((model: ModelList): ListOption => {
      return {
        key: model.model_id,
        value: model.model_name,
      };
    });
    this.ModelDisplayList = temp;
    return this;
  }

  public sortByAsc(): DialogModelDisplayList {
    let sorted = new DialogModelDisplayList();
    sorted.ModelDisplayList = sortByAsc(this.ModelDisplayList, "key");
    return sorted;
  }
}

export class DialogModelDisplay {
  model_id: number = 0;
  model_name: string = "";
}

//選択ダイアログ表示用(機種）
export class DialogVolumeDisplayList {
  VolumeDisplayList: ListOption[] = [];

  public setData(volumeListResponse: VolumeListResponse): DialogVolumeDisplayList {
    var temp = volumeListResponse.volume.map((volume: VolumeList): ListOption => {
      return {
        key: volume.volume_id,
        value: String(volume.volume),
      };
    });
    this.VolumeDisplayList = temp;
    return this;
  }

  public sortByAsc(): DialogVolumeDisplayList {
    let sorted = new DialogVolumeDisplayList();
    sorted.VolumeDisplayList = sortByAsc(this.VolumeDisplayList, "key");
    return sorted;
  }
}

export class DialogVolumeDisplay {
  volume_id: number = 0;
  volume: string = "";
}

export class WorkStandardHistoryDetailListDisplay {
  workStandardHistoryDetailList: WorkStandardHistoryDetailDisplay[] = [];

  public setData(workStandardHistoryDetail: WorkStandardHistoryDetail): WorkStandardHistoryDetailListDisplay {
    var temp = workStandardHistoryDetail.work_standard_history_detail.map((item: WorkStandardHistoryDetailItem): WorkStandardHistoryDetailDisplay => {
      return {
        id: item.work_standard_history_detail_id,
        deltaNumber: item.delta_number,
        target: WorkStandardHistoryDetailTarget[item.target],
        detail: item.detail,
        user: item.user_name + " (" + item.login_id + ")",
        updatedAt: formatDatetime(item.updated_at),
      };
    });
    this.workStandardHistoryDetailList = temp;
    return this;
  }

  public sortByAsc(): WorkStandardHistoryDetailListDisplay {
    let sorted = new WorkStandardHistoryDetailListDisplay();
    sorted.workStandardHistoryDetailList = sortByAsc(this.workStandardHistoryDetailList, "updated_at");
    return sorted;
  }
}

export class WorkStandardHistoryDetailDisplay {
  id: number = 0;
  deltaNumber: number = 0;
  target: string = "";
  detail: string = "";
  user: string = "";
  updatedAt: string = "";
}
