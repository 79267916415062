import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { OperationChip } from "./OperationChip";
import DialogBoard from "../Home/DialogBoard";
import { EditInfo, Message } from "../../context/appContextData";
import {
  deleteOperationPoint,
  patchOperationPointLink,
  postOperationPoint,
  putOperationPointContent,
  putOperationPointLink,
  useOperationPoint,
  useOperationPointStereotyped,
} from "../../apicaller/repository/operation";
import { OperationPoint } from "../../apicaller/domain/response/operation";
import { OperationPointChip, OperationPointChipGroup } from "../Home/display/home";
import { sortByAsc } from "../../function/sort";
import ChipPoint from "../../icons/ChipPoint.png";
import ChipQualityCharacteristics from "../../icons/ChipQualityCharacteristics.png";
import ChipEventOccurring from "../../icons/ChipEventOccurring.png";
import ChipTools from "../../icons/ChipTools.png";
import ChipTrouble from "../../icons/ChipTrouble.png";
import ChipSaftyPoint from "../../icons/ChipSaftyPoint.png";
import ChipClipboard from "../../icons/ChipClipboard.png";
import {
  OperationPointRegistRequest,
  OperationPointUpdateContentRequest,
  OperationPointUpdateLinkRequest,
} from "../../apicaller/domain/request/operation";
import { ErrorMessages, SuccessMessages } from "../../consts/messages";
import { useGetElementProperty } from "../../hooks/getElementProperty";
import { DialogContent } from "../Home/ChipDialog";
import { WorkStandardWorkTabLabel } from "../../consts/label";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import { putWorkStandardWorkPointOrder } from "../../apicaller/repository/workstandard";
import { components, paths } from "../../gen/gen";
import { arrayMoveImmutable } from "array-move"; // 配列移動に使用するライブラリ

interface Props {
  stepsCount: number;
  operationCardId: number;
  displayllustrationPageId: number;
  isCardSelected: boolean;
}

const AvatarSource: string[] = [ChipPoint, ChipQualityCharacteristics, ChipEventOccurring, ChipTools, ChipTrouble, ChipSaftyPoint, ChipClipboard];

export const OperationChipGroup = (props: Props) => {
  const EditContext = useContext(EditInfo);
  const snackBarMessage = useContext(Message);
  const [operationPointList, getOperationPointList, resetOperationPointList, mutateOperationPointList, operationPointError] = useOperationPoint();
  const [
    operationPointStereotyped,
    getOperationPointStereotyped,
    resetOperationPointStereotyped,
    mutateOperationPointStereotyped,
    operationPointStereotypedError,
  ] = useOperationPointStereotyped();

  const [operationPointDisplay, setOperationPointDisplay] = useState<OperationPointChipGroup>({ operation_points: [] });

  // ポイントオーダー順で表示するためのカテゴリー順
  const chipDialogContents: DialogContent[] = [
    {
      name: WorkStandardWorkTabLabel.QUALITY_CHARACTERISTIC,
      image: ChipQualityCharacteristics,
      avatarSourceId: 1,
    },
    {
      name: WorkStandardWorkTabLabel.SPECIFICATION_VALUE,
      image: ChipClipboard,
      avatarSourceId: 6,
    },
    {
      name: WorkStandardWorkTabLabel.POINT,
      image: ChipPoint,
      avatarSourceId: 0,
    },
    {
      name: WorkStandardWorkTabLabel.TOOL,
      image: ChipTools,
      avatarSourceId: 3,
    },
    {
      name: WorkStandardWorkTabLabel.EVENT_OCCURRING,
      image: ChipEventOccurring,
      avatarSourceId: 2,
    },
    {
      name: WorkStandardWorkTabLabel.TROUBLE,
      image: ChipTrouble,
      avatarSourceId: 4,
    },
    {
      name: WorkStandardWorkTabLabel.SAFETY_POINT,
      image: ChipSaftyPoint,
      avatarSourceId: 5,
    },
  ];

  const groupRef = useRef(null);
  const groupWidth = useGetElementProperty(groupRef).getElementProperty("width");

  // カテゴリー順にソートする関数
  const compFunc = (a: OperationPointChip, b: OperationPointChip) => {
    const sourceId = chipDialogContents.map((e: DialogContent) => {
      return e.avatarSourceId;
    });
    if (sourceId.indexOf(a.category) < sourceId.indexOf(b.category)) {
      return -1;
    }
    if (sourceId.indexOf(a.category) > sourceId.indexOf(b.category)) {
      return 1;
    }
    return 0;
  };

  const sortedOperationPointDisplay: OperationPointChipGroup = useMemo(() => {
    return { operation_points: operationPointDisplay.operation_points.sort(compFunc) };
  }, [operationPointDisplay]);

  //repositoryのデータからdisplayデータを生成
  const createDisplayData = (): OperationPointChipGroup => {
    let ret = new OperationPointChipGroup();
    if (operationPointList!.operation_point) {
      ret.operation_points = operationPointList!.operation_point.map((e: components["schemas"]["operation_point"]) => {
        return {
          operation_point_id: e.operation_point_id,
          operation_card_id: e.operation_card_id,
          page: e.page,
          category: e.category,
          source: AvatarSource[e.category],
          content: e.content,
          isError: false,
          order: e.order,
        };
      });
    }
    return ret;
  };

  // add chip
  const handleChipAdd = (sourceId: number) => {
    const reqData = new OperationPointRegistRequest(props.operationCardId, sourceId, "");
    postOperationPoint(reqData)
      .then(
        (data) => {
          mutateOperationPointList();
          snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION_POINT, "success");
        },
        (error) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATION_POINT, "error");
        }
      );
  };

  // update chip
  const handleChipContentUpdate = (operationPointId: number, value: string) => {
    // chipの更新処理はここに記述
    const req = new OperationPointUpdateContentRequest(operationPointId, value);

    putOperationPointContent(req).then(
      (data) => {
        mutateOperationPointList();
        mutateOperationPointStereotyped();
        snackBarMessage.setMessage(SuccessMessages.UPDATE_OPERATION_POINT, "success");
      },
      (error) => {
        //更新に失敗した場合はswrのデータから表示データを復元する
        const tmp = createDisplayData();
        //失敗したoperation_point_idのchipをエラー状態にする
        const index = tmp.operation_points.findIndex((op) => op.operation_point_id === operationPointId);
        tmp.operation_points[index].isError = true;
        setOperationPointDisplay(tmp);
        snackBarMessage.setMessage(ErrorMessages.UPDATE_OPERATION_POINT, "error");
      }
    );
  };

  // delete chip
  const handleChipDelete = (operationPointId: number) => {
    deleteOperationPoint(String(operationPointId)).then(
      (data) => {
        mutateOperationPointList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_OPERATION_POINT, "success");
      },
      (error) => {
        snackBarMessage.setMessage(ErrorMessages.DELETE_OPERATION_POINT, "error");
      }
    );
  };

  const handleSwapWorkPoint = (dropResult: DropResult) => {
    // 並び替え後の順番を取得
    const point_list_after_swap = arrayMoveImmutable(
      sortedOperationPointDisplay.operation_points,
      dropResult.removedIndex || 0,
      dropResult.addedIndex || 0
    );
    // データ更新する前に表示を更新
    setOperationPointDisplay({ operation_points: point_list_after_swap });

    // 並び替え後の順番をリクエスト用に整形
    const point_list_same_category = point_list_after_swap.filter(
      (e) => e.category === sortedOperationPointDisplay.operation_points[dropResult.removedIndex!].category
    );
    const req = point_list_same_category.map((o, index) => {
      return { operation_point_id: o.operation_point_id, order: index + 1 };
    });

    putWorkStandardWorkPointOrder({ work_point_order: req }).then(
      (data) => {
        mutateOperationPointList();
        snackBarMessage.setMessage(SuccessMessages.UPDATE_POINT_ORDER, "success");
      },
      (error) => {
        snackBarMessage.setMessage(ErrorMessages.UPDATE_POINT_ORDER, "error");
      }
    );
  };

  useEffect(() => {
    getOperationPointList(props.operationCardId);
  }, [props.operationCardId]);

  // change in SWR data
  useEffect(() => {
    if (!operationPointList) return;
    setOperationPointDisplay(createDisplayData());
  }, [operationPointList]);

  useEffect(() => {
    mutateOperationPointList();
  }, [props.stepsCount]);

  useEffect(() => {
    getOperationPointStereotyped();
  }, []);

  return (
    <div ref={groupRef}>
      <Container dragHandleSelector=".chipDragHandleSelector" lockAxis="y" onDrop={handleSwapWorkPoint}>
        {sortedOperationPointDisplay.operation_points.map((chipContent, index) => {
          const optionsIndex = operationPointStereotyped?.operation_point_stereotyped?.findIndex((e) => e.category === chipContent.category);
          let options: string[] = [];
          if (optionsIndex != undefined && optionsIndex >= 0) {
            if (operationPointStereotyped?.operation_point_stereotyped) {
              options = operationPointStereotyped.operation_point_stereotyped[optionsIndex].content;
            }
          }
          return (
            <Draggable key={index}>
              <OperationChip
                key={index}
                parentWidth={groupWidth - 78}
                chipContent={chipContent}
                comboBoxOptions={options}
                handleDelete={handleChipDelete}
                handleUpdate={handleChipContentUpdate}
                isCardSelected={props.isCardSelected}
              />
            </Draggable>
          );
        })}
      </Container>
      {EditContext.editMode && <DialogBoard AddHandle={handleChipAdd} chipDialogContents={chipDialogContents} />}
    </div>
  );
};
