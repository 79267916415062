import { useState } from "react";
import useSWR from "swr";
import { get, post, put } from "../apiCaller";
import { ApiMethod, ApiName, createEndPoint } from "../createEndPoint";
import {
  ApplicationRequest,
  ApproverRequest,
  CancelUnderRevisionRequest,
  ConfirmerRequest,
  ManagementBookIdRequest,
  ModifyRequest,
  ModifyUnderRevisionRequest,
  PublishRequest,
  PublishUnderRevisionRequest,
  RevertRequest,
  ReviseRequest,
  RevisingApplicationRequest,
  RevisingApproveRequest,
} from "../domain/request/workflow";
import { AuthorizedResponse, UnderRevisionResponse } from "../domain/response/workflow";
import * as $axios from "../../utils/axiosUtils";
import { ApiBody } from "../../utils/schemaUtil";
import { AxiosResponse } from "axios";

// 確認・承認依頼
export const putApplicationRequest = (body: ApplicationRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.WorkFlowApplication, method: ApiMethod.Put }), bodyJson);
};

// 引き戻し
export const putRevert = (body: RevertRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.WorkFlowCancel, method: ApiMethod.Put }), bodyJson);
};

// 確認
export const postConfirmer = (body: ConfirmerRequest) => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Confirmer, method: ApiMethod.Post }), bodyJson);
};

// 承認
export const postApprover = (body: ApproverRequest) => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Approver, method: ApiMethod.Post }), bodyJson);
};

// 否認
export const putReject = (body: ApiBody<"/work_flow/reject","put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/work_flow/reject",
    method: "put",
    data: body,
  });
};

// 修正
export const putModify = (body: ModifyRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.WorkFlowModify, method: ApiMethod.Put }), bodyJson);
};

// 公開
export const putPublishRequest = (body: PublishRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.WorkFlowPublish, method: ApiMethod.Put }), bodyJson);
};

// アーカイブ承認依頼
export const putArchiveApplicationRequest = (operationId: number) => {
  return $axios.request({
    url: "/work_flow/archive_application",
    method: "put",
    data: {
      operation_id: operationId,
    },
  });
};

// アーカイブ否認
export const putArchiveRejectRequest = (operationId: number) => {
  return $axios.request({
    url: "/work_flow/archive_reject",
    method: "put",
    data: {
      operation_id: operationId,
    },
  });
};

// アーカイブ承認
export const putArchiveApproveRequest = (operationId: number) => {
  return $axios.request({
    url: "/work_flow/archive_approve",
    method: "put",
    data: {
      operation_id: operationId,
    },
  });
};

// 改訂
export const postReviseRequest = (body: ReviseRequest) => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.OperationRevise, method: ApiMethod.Post }), bodyJson);
};

// 改訂承認依頼
export const putRevisingApplicationRequest = (body: RevisingApplicationRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.WorkFlowRevisingApplication, method: ApiMethod.Put }), bodyJson);
};

// 改訂引き戻し
export const putCancelUnderRevision = (body: CancelUnderRevisionRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.WorkFlowCancelUnderRevision, method: ApiMethod.Put }), bodyJson);
};

// 改訂承認
export const putRevisingApprove = (body: RevisingApproveRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.WorkFlowRevisingApprove, method: ApiMethod.Put }), bodyJson);
};

// 改訂否認は新規と一緒

// 改訂修正
export const putModifyUnderRevision = (body: ModifyUnderRevisionRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.WorkFlowModifyUnderRevision, method: ApiMethod.Put }), bodyJson);
};

// 改訂発行
export const putPublishUnderRevisionRequest = (body: PublishUnderRevisionRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.WorkFlowPublishUnderRevision, method: ApiMethod.Put }), bodyJson);
};

// 改訂中かどうか取得する
export const useUnderRevision = (): [UnderRevisionResponse, (operation_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get, { refreshInterval: 1000 });
  const getData = (operation_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.WorkFlowUnderRevision, method: ApiMethod.Get }, [operation_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, resetData, mutateData, error];
};

// 承認・確認できるかどうか
export const useAuthorized = (): [AuthorizedResponse, (operation_id: string, user_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operation_id: string, user_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.AuthorizedOperation, method: ApiMethod.Get }, [operation_id, user_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, resetData, mutateData, error];
};

// 管理台帳の承認依頼
export const putManagementBookApplicationRequest = (body: ApiBody<"/management_book_work_flow/application", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/management_book_work_flow/application",
    method: "put",
    data: body,
  });
};

// 管理台帳の承認
export const putManagementBookApproveRequest = (body: ManagementBookIdRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.ManagementBookWorkFlowApprove, method: ApiMethod.Put }), bodyJson);
};

// 管理台帳の発行
export const putManagementBookPublishRequest = (body: ManagementBookIdRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.ManagementBookWorkFlowPublish, method: ApiMethod.Put }), bodyJson);
};
