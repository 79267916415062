import { Button, Card, createStyles, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { EditInfo, Message } from "../../context/appContextData";
import { useGetElementProperty } from "../../hooks/getElementProperty";
import {
  deleteOperationPage,
  getSignedUrlForPut,
  postOperationCard,
  postOperationPage,
  putIllustrationOrder,
  putWorkCardOrder,
  useOperation,
  useOperationCard,
} from "../../apicaller/repository/operation";
import { DialogMessages, ErrorMessages, SuccessMessages } from "../../consts/messages";
import { IllustrationPageRegistRequest, OperationCardRegistRequest } from "../../apicaller/domain/request/operation";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { v4 } from "uuid";
import illustration_template from "../../context/illustration_template.json";
import { putAws } from "../../apicaller/repository/fileupload";
import { OperationCardDisplayGroup } from "../Home/display/home";
import PartsTable from "./PartsTable";
import CompSafetyEquipmentChipGroup from "../Home/CompSafetyEquipmentChipGroup";
import RequirementChipGroup from "../Home/RequirementChipGroup";
import { MoveableBoard } from "./MoveableBoard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation, Pagination } from "swiper/modules";
import { useTheme } from "@material-ui/core/styles";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../styles/Swiper.css";
import { WorkStandardWorkTabLabel } from "../../consts/label";

import { DropResult } from "react-smooth-dnd";
import { arrayMoveImmutable } from "array-move";
import { ApiBody } from "../../utils/schemaUtil";
import WorkStandardDetail from "./WorkStandardDetail";
import { SafetyPointChipGroup } from "../Home/SafetyPointChipGroup";

interface Props {
  contentHeight: number;
  operationId: string;
  revisionNumber: number;
  cardWidth: number | undefined;
  modelId: number;
  workStandardName: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //---右部分---
    operation: {
      overflowX: "hidden",
      overflowY: "auto",
    },
    chip: {
      fontSize: 18,
      borderRadius: 5,
      margin: 2.5,
      "& .MuiChip-label": {
        paddingLeft: "7px",
        paddingRight: "7px",
      },
    },
    //カードタイトル(VIEWモード)
    title: {
      fontSize: 18,
      cursor: "pointer",
      marginLeft: 10,
      marginRight: 10,
      minHeight: "35px",
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
    //チップ部分
    chipGroup: {
      maxWidth: "100%",
    },
  })
);

export const WorkTab = (props: Props) => {
  const styles = useStyles();
  const theme = useTheme();
  const EditContext = useContext(EditInfo);
  const snackBarMessage = useContext(Message);
  const swiperRef = useRef(null);
  const labelRef = useRef(null);
  const labelHeight = useGetElementProperty(labelRef).getElementProperty("height");

  const [workStandard, getWorkStandard, mutateWorkStandard, workStandardError] = useOperation();
  const [operationCardList, getOperationCardList, mutateOperationCardList, OperationCardListError] = useOperationCard();

  const [operationCardDisplayList, setOperationCardDisplay] = useState<OperationCardDisplayGroup>(new OperationCardDisplayGroup());
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0); // 現在表示中の図解ページ(0から始まる、DBは1始まる)
  const [showMainIllustration, setShowMainIllustration] = useState(true);

  //#region ---STEPPER---
  //新しいStepperが追加された時
  const handleAddStepper = (operation_card_id: number, page: number) => {
    const illustration_s3_key = `${props.modelId}/detail_illustration/${v4()}_illustration.json`;
    const reqBody = new IllustrationPageRegistRequest(operation_card_id, page, illustration_s3_key);
    postOperationPage(reqBody).then(
      (data) => {
        getSignedUrlForPut(illustration_s3_key).then(
          (url: string) => {
            putAws(url, new File([JSON.stringify(illustration_template)], "illastration_template.json")).then(
              (data) => {
                mutateOperationCardList();
                snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION_PAGE, "success");
              },
              (error) => {
                console.log(error);
              }
            );
          },
          (error) => {
            console.log(error);
            snackBarMessage.setMessage(ErrorMessages.GET_S3_URL_OPERATION, "error");
          }
        );
      },
      (error) => {
        console.log(error);
        setOperationCardDisplayList();
        snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATION_PAGE, "error");
      }
    );
  };

  //stepperが削除された時の処理
  const handleDeleteStepper = (illustration_page_id: number, operationCardOrder: number) => {
    let result: boolean = window.confirm(DialogMessages.DELETE_ILLUSTRATION_PAGE);
    if (result) {
      deleteOperationPage(String(illustration_page_id)).then(
        () => {
          if (selectedPage >= operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list.length - 1) {
            setSelectedPage(selectedPage - 1);
          }

          mutateOperationCardList();
          snackBarMessage.setMessage(SuccessMessages.DELETE_OPERATION_PAGE, "success");
        },
        (error) => {
          console.log(error);
          setOperationCardDisplayList();
          snackBarMessage.setMessage(ErrorMessages.DELETE_OPERATION_PAGE, "error");
        }
      );
    }
  };

  // 図解の順番を変更する
  const handleChangeIllustrationOrder = (operation_card_id: number, order_to: number, order_from: number) => {
    putIllustrationOrder({
      operation_card_id: operation_card_id,
      order_to: order_to,
      order_from: order_from,
    } as ApiBody<"/work_standard/illustration_order", "put">)
      .then((data) => {
        mutateOperationCardList();
      })
      .catch((err) => {
        console.log(err);
        snackBarMessage.setMessage("todo", "error");
      });
  };
  //#endregion

  //#region ---CARD---
  const handleCardClick = (index: number) => {
    if (!workStandard?.single_illustration) {
      setShowMainIllustration(false);
      setSelectedCardIndex(index);
      if (index === selectedCardIndex) return;
      setSelectedPage(0);
    } else if (workStandard?.single_illustration) {
      setShowMainIllustration(true);
    }
  };

  // card add button clicked
  const handleAddOperationCard = () => {
    const illustration_s3_key = `${props.modelId}/detail_illustration/${v4()}_illustration.json`;
    const reqData = new OperationCardRegistRequest(
      Number(props.operationId),
      "",
      operationCardDisplayList.operationCards.length + 1,
      illustration_s3_key
    );

    postOperationCard(reqData).then(
      (data) => {
        getSignedUrlForPut(illustration_s3_key).then(
          (url: string) => {
            putAws(url, new File([JSON.stringify(illustration_template)], "illastration_template.json")).then(
              (data) => {
                mutateOperationCardList();
                snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION_CARD, "success");
              },
              (error) => {
                console.log(error);
              }
            );
          },
          (error) => {
            console.log(error);
            snackBarMessage.setMessage(ErrorMessages.GET_S3_URL_OPERATION, "error");
          }
        );
      },
      (error) => {
        console.log(error);
        setOperationCardDisplayList();
        snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATION_CARD, "error");
      }
    );
  };

  // SWRのデータから画面表示用データに変換
  const setOperationCardDisplayList = async () => {
    if (!operationCardList?.operation_card) return;

    const updatedDisplayList = await operationCardDisplayList.setData(operationCardList);
    setOperationCardDisplay(updatedDisplayList.sortData());
  };

  const handleSwapWorkCard = (dropResult: DropResult) => {
    const point_list_after_swap = arrayMoveImmutable(
      operationCardDisplayList.operationCards,
      dropResult.removedIndex || 0,
      dropResult.addedIndex || 0
    );
    setOperationCardDisplay(operationCardDisplayList.changeCardOrder(point_list_after_swap));

    const req = point_list_after_swap.map((o, index) => {
      return { operation_card_id: o.operation_card_id, card_order: index + 1 };
    });
    putWorkCardOrder({ work_card_order: req } as ApiBody<"/work_standard/work_card_order", "put">)
      .then((data) => {
        mutateOperationCardList();
        snackBarMessage.setMessage(SuccessMessages.WORK_CARD_ORDER, "success");
      })
      .catch((err) => {
        console.log(err);
        snackBarMessage.setMessage(ErrorMessages.WORK_CARD_ORDER, "error");
      });
  };
  //#endregion

  //#region ---useEffect---
  useEffect(() => {
    getWorkStandard(props.operationId);
    getOperationCardList(props.operationId);
    setSelectedCardIndex(0);
  }, [props.operationId]);

  useEffect(() => {
    swiperRef.current?.swiper.slideTo(selectedPage);
  }, [selectedPage]);

  // change in SWR data
  useEffect(() => {
    setOperationCardDisplayList();
  }, [operationCardList]);

  // SWR error
  useEffect(() => {
    if (OperationCardListError) snackBarMessage.setMessage(ErrorMessages.GET_OPERATION_CARD, "error");
    if (workStandardError) snackBarMessage.setMessage(ErrorMessages.GET_WORK_STANDARD, "error");
  }, [OperationCardListError, workStandardError]);
  //#endregion

  // 図解表示処理
  const slide = () => {
    // 図解がメインイラストの時
    if (showMainIllustration) {
      return (
        <SwiperSlide>
          <MoveableBoard
            id={"mainIllustration"}
            modelId={props.modelId}
            illustrationS3Key={workStandard?.illustration_s3_key}
            workStandardName={props.workStandardName}
            revisionNumber={props.revisionNumber}
            isLastIllust={false}
            isMainIllust={true}
            isFirstIllust={false}
          />
        </SwiperSlide>
      );
    }

    // 図解がない時
    if (operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list.length === 0) {
      return (
        <SwiperSlide>
          {EditContext.editMode && (
            <Button
              variant="contained"
              style={{
                float: "right",
              }}
              color="secondary"
              onClick={() => {
                handleAddStepper(
                  operationCardDisplayList.operationCards[selectedCardIndex]?.operation_card_id,
                  operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list.length + 1
                );
              }}
            >
              <AddCircleIcon />
            </Button>
          )}
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h2"}
              align={"center"}
              style={{
                width: "100%",
              }}
            >
              {WorkStandardWorkTabLabel.NO_ILLUSTRATION_LINE1}
              <br />
              {WorkStandardWorkTabLabel.NO_ILLUSTRATION_LINE2}
            </Typography>
          </div>
        </SwiperSlide>
      );
    }

    // 図解がある時
    return operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list.map((page, index) => (
      <SwiperSlide>
        <MoveableBoard
          id={String(index)}
          modelId={props.modelId}
          illustrationS3Key={page.illustration_s3_key}
          workStandardName={props.workStandardName}
          revisionNumber={props.revisionNumber}
          isFirstIllust={index === 0}
          isLastIllust={operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list.length === index + 1}
          isMainIllust={false}
          addIllustration={() => {
            handleAddStepper(
              operationCardDisplayList.operationCards[selectedCardIndex]?.operation_card_id,
              operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list.length + 1
            );
          }}
          deleteIllustration={() => {
            handleDeleteStepper(
              operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list[selectedPage].illustration_page_id,
              selectedPage
            );
          }}
          changeIllustrationOrder={(leftRightItem: number) => {
            // ページが1で右に移動する場合、1+1=2で0始まりのため-1する
            setSelectedPage(page.page + leftRightItem - 1);
            handleChangeIllustrationOrder(
              operationCardDisplayList.operationCards[selectedCardIndex]?.operation_card_id,
              page.page + leftRightItem,
              page.page
            );
          }}
        />
      </SwiperSlide>
    ));
  };

  return (
    <Grid container spacing={1} style={{ padding: theme.spacing(0, 1) }}>
      <Grid item xs={6} style={{ height: props.contentHeight * 0.7 }}>
        <Typography ref={labelRef} className={"typography-label"}>
          {WorkStandardWorkTabLabel.ILLUSTRATION_TABLE_TITLE}
        </Typography>
        <Paper elevation={1}>
          <Swiper
            ref={swiperRef}
            modules={[Navigation, Pagination, Keyboard]}
            slidesPerView={1}
            navigation
            pagination={{
              renderBullet: function (index: number, className: string) {
                return '<span class="' + className + '" data-index="' + index + '" style="cursor: pointer;">' + (index + 1) + "</span>";
              },
            }}
            keyboard={{ enabled: true }}
            style={{ height: props.contentHeight * 0.7 - labelHeight - theme.spacing(1) }}
            onSlideChange={(swiper) => {
              setSelectedPage(swiper.activeIndex);
            }}
            onPaginationRender={(swiper) => {
              const bullets = swiper.pagination.bullets;
              bullets.forEach((bullet) => {
                bullet.addEventListener("click", (event) => {
                  const target = event.target as HTMLElement;
                  const index = target.getAttribute("data-index");
                  if (index) {
                    swiper.slideTo(Number(index));
                  }
                });
              });
            }}
          >
            {slide()}
          </Swiper>
        </Paper>
      </Grid>

      <Grid item xs={6} style={{ height: props.contentHeight * 0.7, display: "flex", flexDirection: "column" }}>
        <WorkStandardDetail
          operationId={props.operationId}
          showMainIllustration={showMainIllustration}
          setShowMainIllustration={setShowMainIllustration}
          handleSwapWorkCard={handleSwapWorkCard}
          operationCardDisplayList={operationCardDisplayList}
          selectedCardIndex={selectedCardIndex}
          revisionNumber={props.revisionNumber}
          selectedPage={selectedPage}
          handleCardClick={handleCardClick}
          handleAddOperationCard={handleAddOperationCard}
          setOperationCardDisplay={setOperationCardDisplay}
          mutateOperationCardList={mutateOperationCardList}
          setOperationCardDisplayList={setOperationCardDisplayList}
          setSelectedCardIndex={setSelectedCardIndex}
          operationCardList={operationCardList}
        />
      </Grid>

      <Grid item xs={4} style={{ height: props.contentHeight * 0.3, display: "flex", flexDirection: "column" }}>
        <Typography className={"typography-label"}>{WorkStandardWorkTabLabel.SAFETY_POINT_TABLE_TITLE}</Typography>
        <Card style={{ flexGrow: 1 }}>
          <SafetyPointChipGroup operationId={props.operationId} />
        </Card>
      </Grid>
      <Grid item xs={2} style={{ height: props.contentHeight * 0.3, display: "flex", flexDirection: "column" }}>
        <Typography className={"typography-label"}>{WorkStandardWorkTabLabel.SAFETY_EQUIPMENT_TABLE_TITLE}</Typography>
        <Card style={{ flexGrow: 1 }}>
          <CompSafetyEquipmentChipGroup operationId={props.operationId} />
        </Card>
      </Grid>
      <Grid item xs={2} style={{ height: props.contentHeight * 0.3, display: "flex", flexDirection: "column" }}>
        <Typography className={"typography-label"}>{WorkStandardWorkTabLabel.REQUIREMENT_TABLE_TITLE}</Typography>
        <Card style={{ flexGrow: 1 }}>
          <RequirementChipGroup operationId={Number(props.operationId)} />
        </Card>
      </Grid>
      <Grid item xs={4} style={{ height: props.contentHeight * 0.3, display: "flex", flexDirection: "column" }}>
        <Typography className={"typography-label"}>{WorkStandardWorkTabLabel.PARTS_TABLE_TITLE}</Typography>
        <Card style={{ flexGrow: 1 }}>
          <PartsTable operationId={String(props.operationId)} />
        </Card>
      </Grid>
    </Grid>
  );
};
