import React, { ChangeEvent, useContext, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { EditInfo, Message } from "../../context/appContextData";
import { Avatar, Card, Chip, Divider, IconButton, InputAdornment, InputBase, Switch, TextField, Typography } from "@material-ui/core";
import { WorkStandardWorkTabLabel } from "../../consts/label";
import { DropResult, Container, Draggable } from "react-smooth-dnd";
import ChipPoint from "../../icons/ChipPoint.png";
import ChipQualityCharacter from "../../icons/ChipQualityCharacteristics.png";
import ChipEventOccurring from "../../icons/ChipEventOccurring.png";
import ChipTools from "../../icons/ChipTools.png";
import ChipTrouble from "../../icons/ChipTrouble.png";
import ChipSaftyPoint from "../../icons/ChipSaftyPoint.png";
import ChipClipboard from "../../icons/ChipClipboard.png";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { purple } from "@material-ui/core/colors";
import CompOperationHashItemChipGroup from "../Home/CompOperationHashItemChipGroup";
import { OperationChipGroup } from "./OperationChipGroup";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
import { ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import { isLengthCorrect, isOnlyHalfNumber } from "../../function/validationCheck";
import { WorkStandardCardLimits } from "../../consts/inputLimits";
import { revision } from "../../consts/firstRevision";
import { putWorkPersonSeconds, putWorkStandardSingleIllustration } from "../../apicaller/repository/workstandard";
import { ApiBody } from "../../utils/schemaUtil";
import { deleteOperationCard, putOperationCard, useOperation } from "../../apicaller/repository/operation";
import { OperationCardUpdateRequest } from "../../apicaller/domain/request/operation";
import { OperationCardDisplay, OperationCardDisplayGroup } from "../Home/display/home";
import { sortByAsc } from "../../function/sort";
import { OperationCard } from "../../apicaller/domain/response/operation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    operation: {
      overflowX: "hidden",
      overflowY: "auto",
    },
    cardHeaderDiv: {
      width: "100%",
      display: "flex",
      backgroundColor: purple[50],
      overflowWrap: "anywhere",
      alignItems: "center",
    },
    selectedCardDiv: {
      border: "3px solid " + theme.palette.secondary.dark,
      borderRadius: 5,
    },
    title: {
      fontSize: 18,
      cursor: "pointer",
      marginLeft: 10,
      marginRight: 10,
      minHeight: "35px",
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
    chipHintDiv: {
      display: "flex",
      minHeight: "40px",
      alignItems: "center",
      overflowX: "auto",
      overflowY: "hidden",
    },
    deleteBtn: {
      height: "35px",
    },
    personSecondsChip: {
      marginRight: 5,
    },
    chip: {
      fontSize: 18,
      borderRadius: 5,
      margin: 2.5,
      "& .MuiChip-label": {
        paddingLeft: "7px",
        paddingRight: "7px",
      },
    },
    chipLabel: {
      fontSize: "0.8rem",
      fontWeight: "bold",
    },
    titleNo: {
      backgroundColor: theme.palette.secondary.main,
      width: "max-content",
      paddingInline: 10,
      color: "white",
      borderRadius: 5,
      whiteSpace: "nowrap",
      marginLeft: 5,
    },
    edittitle: {
      fontSize: 18,
      marginLeft: 10,
      marginRight: 10,
      flexGrow: 1,
    },
    addBtn: {
      textAlign: "center",
    },
  })
);

interface Props {
  operationId: string;
  showMainIllustration: boolean;
  setShowMainIllustration: (showMainIllustration: boolean) => void;
  handleSwapWorkCard: (dropResult: DropResult) => void;
  operationCardDisplayList: any;
  selectedCardIndex: number;
  revisionNumber: number;
  selectedPage: number;
  operationCardList: any;
  handleCardClick: (index: number) => void;
  handleAddOperationCard: () => void;
  setOperationCardDisplay: (operationCardDisplay: any) => void;
  mutateOperationCardList: () => void;
  setOperationCardDisplayList: () => void;
  setSelectedCardIndex: (selectedCardIndex: number) => void;
}

export default function WorkStandardDetail(props: Props) {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);
  const snackBarMessage = useContext(Message);

  const [workStandard, getWorkStandard, mutateWorkStandard, workStandardError] = useOperation();

  useEffect(() => {
    getWorkStandard(props.operationId);
  }, [props.operationId]);

  const voiceFunc = (readingMsg: string) => {
    var msg = new SpeechSynthesisUtterance();
    msg.text = readingMsg;
    msg.lang = "ja-JP";
    window.speechSynthesis.speak(msg);
  };

  // タイトル編集時にEnterを押下する時
  const handleTitleOnKeyPress = (e: any) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      e.target.blur();
    }
  };

  // 工数更新処理
  const handleUpdatePersonSeconds = (workId: number, personSeconds: string) => {
    putWorkPersonSeconds({ work_id: workId, person_seconds: Number(personSeconds) } as ApiBody<"/work_standard/work_person_seconds", "put">)
      .then(() => {
        snackBarMessage.setMessage(SuccessMessages.WORK_PERSON_SECONDS, "success");
      })
      .catch((err) => {
        console.log(err);
        snackBarMessage.setMessage(ErrorMessages.WORK_PERSON_SECONDS, "error");
      });
  };

  //#region ---TITLE---
  const handleTitleChange = (id: number, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const index = props.operationCardDisplayList.operationCards.findIndex((s: OperationCardDisplay) => s.operation_card_id === id);
    props.setOperationCardDisplay(props.operationCardDisplayList.changeTitle(index, e.currentTarget.value));
  };

  const handleChangePersonSeconds = (id: number, personSeconds: string) => {
    const index = props.operationCardDisplayList.operationCards.findIndex((s: OperationCardDisplay) => s.operation_card_id === id);
    props.setOperationCardDisplay(props.operationCardDisplayList.changePersonSeconds(index, personSeconds));
  };

  // カードのタイトルを更新する
  const handleTitleUpdate = (id: number, title: string) => {
    const reqData = new OperationCardUpdateRequest(id, title);

    putOperationCard(reqData).then(
      (data) => {
        props.mutateOperationCardList();
        snackBarMessage.setMessage(SuccessMessages.UPDATE_OPERATION_CARD, "success");
      },
      (error) => {
        // Restore operation title to pre-changed state
        const operationCards = props.operationCardList.operation_card.map((e: OperationCard) => {
          return {
            operation_card_id: e.operation_card_id,
            title: e.title,
            card_order: e.card_order,
            person_seconds: e.person_seconds,
          };
        });

        let sorted = new OperationCardDisplayGroup();
        sorted.operationCards = sortByAsc(operationCards, "card_order");
        props.setOperationCardDisplay(sorted);
        snackBarMessage.setMessage(ErrorMessages.UPDATE_OPERATION_CARD, "error");
      }
    );
  };
  //#endregion

  // card delete button clicked
  const handleDeleteOperationCard = (operation_card_id: number) => {
    deleteOperationCard(String(operation_card_id)).then(
      (data) => {
        const deletedCardIndex = props.operationCardDisplayList.operationCards.findIndex(
          (s: OperationCardDisplay) => s.operation_card_id === operation_card_id
        );

        // カードの削除が選択されたカードのindexに影響を与える場合に、indexを更新する
        if (props.selectedCardIndex >= deletedCardIndex) {
          if (!(props.selectedCardIndex === 0 && deletedCardIndex === 0)) props.setSelectedCardIndex(props.selectedCardIndex - 1);
        }
        props.mutateOperationCardList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_OPERATION_CARD, "success");
      },
      (error) => {
        console.log(error);
        props.setOperationCardDisplayList();
        snackBarMessage.setMessage(ErrorMessages.DELETE_OPERATION_CARD, "error");
      }
    );
  };

  const handleSetSingleIllustration = () => {
    putWorkStandardSingleIllustration({
      work_standard_id: workStandard?.operation_id,
      single_illustration: !workStandard?.single_illustration,
    } as ApiBody<"/work_standard/single_illustration", "put">)
      .then(() => {
        snackBarMessage.setMessage(SuccessMessages.UPDATE_OPERATION_SINGLE_ILLUSTRATION, "success");
        mutateWorkStandard();
      })
      .catch((err) => {
        console.log(err);
        snackBarMessage.setMessage(ErrorMessages.UPDATE_OPERATION_SINGLE_ILLUSTRATION, "error");
      });
  };

  return (
    <>
      <div className={styles.chipHintDiv}>
        <Chip
          className={styles.chip}
          label={<Typography className={styles.chipLabel}>{WorkStandardWorkTabLabel.QUALITY_CHARACTERISTIC}</Typography>}
          avatar={<Avatar src={ChipQualityCharacter} style={{ color: "Black" }} />}
        />
        <Chip
          className={styles.chip}
          label={<Typography className={styles.chipLabel}>{WorkStandardWorkTabLabel.SPECIFICATION_VALUE}</Typography>}
          avatar={<Avatar src={ChipClipboard} style={{ color: "Black" }} />}
        />
        <Chip
          className={styles.chip}
          label={<Typography className={styles.chipLabel}>{WorkStandardWorkTabLabel.POINT}</Typography>}
          avatar={<Avatar src={ChipPoint} style={{ color: "Black" }} />}
        />
        <Chip
          className={styles.chip}
          label={<Typography className={styles.chipLabel}>{WorkStandardWorkTabLabel.TOOL}</Typography>}
          avatar={<Avatar src={ChipTools} style={{ color: "Black" }} />}
        />
        <Chip
          className={styles.chip}
          label={<Typography className={styles.chipLabel}>{WorkStandardWorkTabLabel.EVENT_OCCURRING}</Typography>}
          avatar={<Avatar src={ChipEventOccurring} style={{ color: "Black" }} />}
        />
        <Chip
          className={styles.chip}
          label={<Typography className={styles.chipLabel}>{WorkStandardWorkTabLabel.TROUBLE}</Typography>}
          avatar={<Avatar src={ChipTrouble} style={{ color: "Black" }} />}
        />
        <Chip
          className={styles.chip}
          label={<Typography className={styles.chipLabel}>{WorkStandardWorkTabLabel.SAFETY_POINT}</Typography>}
          avatar={<Avatar src={ChipSaftyPoint} style={{ color: "Black" }} />}
        />
      </div>
      <Card className={styles.operation}>
        <div>
          <Card
            className={props.showMainIllustration ? styles.selectedCardDiv : ""}
            style={{ backgroundColor: purple[50], marginBottom: 10 }}
            onClick={() => {
              props.setShowMainIllustration(true);
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant={"h4"}>{WorkStandardWorkTabLabel.OVERVIEW_ILLUSTRATION}</Typography>
              {EditContext.editMode && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography>{WorkStandardWorkTabLabel.SINGLE_ILLUSTRATION}</Typography>
                  <Switch checked={workStandard?.single_illustration} onChange={handleSetSingleIllustration} />
                </div>
              )}
            </div>
          </Card>
          <Container dragHandleSelector=".dragHandleSelector" lockAxis="y" onDrop={props.handleSwapWorkCard}>
            {props.operationCardDisplayList.operationCards.map((operationCardData, index) => {
              return (
                <Draggable key={index}>
                  <Card
                    key={index}
                    style={{ minHeight: "150px", marginBottom: 10 }}
                    className={props.selectedCardIndex === index && !props.showMainIllustration ? styles.selectedCardDiv : ""}
                    onClick={() => props.handleCardClick(index)}
                  >
                    {/* タイトル */}
                    {EditContext.editMode ? (
                      <div className={styles.cardHeaderDiv}>
                        <Typography className={styles.titleNo}>{index + 1}</Typography>
                        <InputBase
                          className={styles.edittitle}
                          value={operationCardData.title}
                          onChange={(e) => handleTitleChange(operationCardData.operation_card_id, e)}
                          onBlur={(e) => handleTitleUpdate(operationCardData.operation_card_id, e.currentTarget.value)}
                          onKeyPress={(e) => handleTitleOnKeyPress(e)}
                          inputProps={{
                            maxLength: WorkStandardCardLimits.INPUT_MAX_LENGTH,
                          }}
                        />
                        <Chip
                          className={styles.personSecondsChip}
                          size={"small"}
                          label={
                            <TextField
                              size={"small"}
                              type={"text"}
                              value={operationCardData.person_seconds}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">{WorkStandardWorkTabLabel.SECOND}</InputAdornment>,
                              }}
                              onChange={(e) => {
                                if (!isOnlyHalfNumber(e.currentTarget.value)) {
                                  snackBarMessage.setMessage(WarningMessages.NOT_ONLY_HALF_NUMBER, "warning");
                                  return;
                                }
                                if (!isLengthCorrect(e.currentTarget.value, 0, 8)) {
                                  snackBarMessage.setMessage(WarningMessages.PERSON_SECONDS_LENGTH, "warning");
                                  return;
                                }
                                handleChangePersonSeconds(operationCardData.operation_card_id, e.currentTarget.value);
                              }}
                              onBlur={(e) => handleUpdatePersonSeconds(operationCardData.operation_card_id, e.currentTarget.value)}
                            />
                          }
                        />
                        {props.revisionNumber === revision.firstRevision && (
                          <IconButton
                            className={styles.deleteBtn}
                            disabled={props.operationCardDisplayList.operationCards.length === 1}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteOperationCard(operationCardData.operation_card_id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                        <IconButton className="dragHandleSelector" size="small">
                          <DragHandleIcon />
                        </IconButton>
                      </div>
                    ) : (
                      <div className={styles.cardHeaderDiv}>
                        <Typography className={styles.titleNo}>
                          {index + 1} / {props.operationCardDisplayList.operationCards.length}
                        </Typography>
                        <Typography className={styles.title} onClick={() => voiceFunc(operationCardData.title)}>
                          {operationCardData.title}
                        </Typography>
                        <Chip
                          className={styles.personSecondsChip}
                          color={"secondary"}
                          size={"small"}
                          label={operationCardData.person_seconds + " " + WorkStandardWorkTabLabel.SECOND}
                        />
                      </div>
                    )}

                    <Divider />

                    <CompOperationHashItemChipGroup operationCardId={operationCardData.operation_card_id} />

                    {/* チップグループ */}
                    <div style={{ margin: 10 }}>
                      <OperationChipGroup
                        stepsCount={operationCardData.illustration_page_list?.length}
                        operationCardId={operationCardData.operation_card_id}
                        displayllustrationPageId={
                          operationCardData.illustration_page_list &&
                          operationCardData.illustration_page_list[props.selectedPage]?.illustration_page_id
                        }
                        isCardSelected={props.selectedCardIndex === index}
                      />
                    </div>
                  </Card>
                </Draggable>
              );
            })}
          </Container>
          {!EditContext.editMode && (
            <Typography variant={"h4"} style={{ textAlign: "center", marginBottom: "20px" }}>
              {WorkStandardWorkTabLabel.END_OF_WORK}
            </Typography>
          )}

          {/* 作業カード追加ボタン */}
          {EditContext.editMode && (
            <div className={styles.addBtn}>
              <IconButton color={"secondary"} onClick={props.handleAddOperationCard}>
                <AddCircleIcon fontSize="large" />
              </IconButton>
            </div>
          )}
        </div>
      </Card>
    </>
  );
}
