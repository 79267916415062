import React, { useContext, useState } from "react";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EditInfo } from "../../context/appContextData";
import { WorkStandardPointLimits, WorkStandardRequirementOtherLimits } from "../../consts/inputLimits";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { IconButton } from "@material-ui/core";

interface Props {
  content: string;
  icon: string;
  requirementNecessityId: number;
  handleDelete: any;
  handleUpdate: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexdirection: "row",
      padding: 5,
    },
    chip: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 100,
      "& .MuiChip-label": {
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
  })
);

export const RequirementsChipForEdit = (props: Props) => {
  const styles = useStyles();
  const [onEdit, setOnEdit] = useState(false);
  const [chipContent, setChipContent] = useState(props.content);
  const EditContext = useContext(EditInfo);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setChipContent(e.currentTarget.value);
  };

  const handleOnBlur = (e: React.FormEvent<HTMLInputElement>) => {
    props.handleUpdate(props.requirementNecessityId, e);
    setOnEdit(false);
  };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      if (!e.nativeEvent.isComposing) {
        props.handleUpdate(props.requirementNecessityId, e);
        setOnEdit(false);
      }
    }
    setChipContent(e.currentTarget.value);
  };

  return (
    <div className={styles.root}>
      {EditContext.editMode ? (
        <Chip
          className={styles.chip}
          label={
            onEdit ? (
              <input
                type="text"
                value={chipContent}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onKeyPress={handleOnKeyPress}
                autoFocus={true}
                maxLength={WorkStandardRequirementOtherLimits.INPUT_MAX_LENGTH}
              />
            ) : (
              props.content
            )
          }
          onDelete={() => props.handleDelete(props.requirementNecessityId)}
          avatar={<Avatar src={props.icon} />}
          onClick={(e) => {
            setOnEdit(true);
          }}
        />
      ) : (
        <Chip className={styles.chip} label={props.content} avatar={<Avatar src={props.icon} />} />
      )}
    </div>
  );
};
