import { useContext, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { createStyles, List, makeStyles, TextField, Theme } from "@material-ui/core";
import { Message } from "../../context/appContextData";
import React from "react";
import { grey } from "@material-ui/core/colors";
import { DialogButtonLabel, ManagementBookProcessListTabLabel } from "../../consts/label";
import { postProcessApplicable } from "../../apicaller/repository/managementbook";
import { ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      textAlign: "center",
      padding: 7,
    },
    cancel: {
      backgroundColor: grey[300],
      textAlign: "center",
      padding: 7,
    },
  })
);

export interface Props {
  open: boolean;
  process_applicable_id: number;
  handleCloseDialog: () => void;
}

export default function ProcessApplicableDialog(props: Props) {
  const styles = useStyles();
  const snackBarMessage = useContext(Message);

  const [processApplicableInfo, setProcessApplicableInfo] = useState({
    model_year: "",
    model_name: "",
    derivation: "",
    engine_type: "",
  });

  const handleCloseDialog = () => {
    setProcessApplicableInfo({
      model_year: "",
      model_name: "",
      derivation: "",
      engine_type: "",
    });
    props.handleCloseDialog();
  };

  return (
    <Dialog open={props.open}>
      {/* ダイアログタイトル */}
      <DialogTitle style={{ textAlign: "center" }}>{ManagementBookProcessListTabLabel.ADD_PROCESS_APPLICABLE}</DialogTitle>
      <List>
        <ListItem>
          <TextField
            size="small"
            label={ManagementBookProcessListTabLabel.MODEL_YEAR}
            value={processApplicableInfo.model_year}
            inputProps={{ maxLength: 100 }}
            onChange={(e) => {
              setProcessApplicableInfo({ ...processApplicableInfo, model_year: e.currentTarget.value });
            }}
          />
        </ListItem>
        <ListItem>
          <TextField
            size="small"
            label={ManagementBookProcessListTabLabel.MODEL_NAME}
            value={processApplicableInfo.model_name}
            inputProps={{ maxLength: 100 }}
            onChange={(e) => {
              setProcessApplicableInfo({ ...processApplicableInfo, model_name: e.currentTarget.value });
            }}
          />
        </ListItem>
        <ListItem>
          <TextField
            size="small"
            label={ManagementBookProcessListTabLabel.DERIVATION}
            value={processApplicableInfo.derivation}
            inputProps={{ maxLength: 100 }}
            onChange={(e) => {
              setProcessApplicableInfo({ ...processApplicableInfo, derivation: e.currentTarget.value });
            }}
          />
        </ListItem>
        <ListItem>
          <TextField
            size="small"
            label={ManagementBookProcessListTabLabel.ENGINE_TYPE}
            value={processApplicableInfo.engine_type}
            inputProps={{ maxLength: 100 }}
            onChange={(e) => {
              setProcessApplicableInfo({ ...processApplicableInfo, engine_type: e.currentTarget.value });
            }}
          />
        </ListItem>
        {/* 登録ボタン */}
        <ListItem
          button
          onClick={() => {
            if (
              processApplicableInfo.model_year === "" ||
              processApplicableInfo.model_name === "" ||
              processApplicableInfo.derivation === "" ||
              processApplicableInfo.engine_type === ""
            ) {
              snackBarMessage.setMessage(WarningMessages.PROCESS_APPLICABLE, "warning");
              return;
            }
            postProcessApplicable({
              process_id: props.process_applicable_id,
              model_year: processApplicableInfo.model_year,
              model_name: processApplicableInfo.model_name,
              derivation: processApplicableInfo.derivation,
              engine_type: processApplicableInfo.engine_type,
            })
              .then(() => {
                snackBarMessage.setMessage(SuccessMessages.INSERT_PROCESS_APPLICABLE, "success");
                handleCloseDialog();
              })
              .catch((err) => {
                snackBarMessage.setMessage(ErrorMessages.INSERT_PROCESS_APPLICABLE, "error");
                console.log(err);
              });
          }}
        >
          <ListItemText className={styles.button} primary={DialogButtonLabel.REGIST} />
        </ListItem>

        {/* キャンセルボタン */}
        <ListItem button onClick={handleCloseDialog}>
          <ListItemText className={styles.cancel} primary={DialogButtonLabel.CANCEL} />
        </ListItem>
      </List>
    </Dialog>
  );
}
