import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Modal, CircularProgress } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EditInfo, WindowSize } from "../../context/appContextData";
import EditIcon from "@material-ui/icons/Edit";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ImageEditorContainer from "../../editor/containers/ImageEditorContainer";
import { DialogMessages } from "../../consts/messages";
import Canvas from "../../editor/components/canvas/Canvas";
import { getSignedUrlForGet } from "../../apicaller/repository/operation";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import { cloneDeep } from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { revision } from "../../consts/firstRevision";

interface Props {
  id: string;
  style?: any;
  illustrationS3Key: string;
  modelId: number;
  workStandardName: string;
  revisionNumber: number;
  isFirstIllust: boolean;
  isLastIllust: boolean;
  isMainIllust: boolean;
  addIllustration?: () => void;
  deleteIllustration?: () => void;
  changeIllustrationOrder?: (number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridcontainer: {
      height: "100%",
      width: "100%",
    },
    card: {
      height: "100%",
      width: "100%",
    },
    illustButton: {
      float: "right",
      zIndex: 1,
      marginLeft: theme.spacing(1),
    },
    addButton: {
      float: "right",
      zIndex: 1,
      marginLeft: theme.spacing(1),
    },
    loadingContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
      background: "rgba(255, 255, 255, 0.7)",
    },
  })
);

export const MoveableBoard = (props: Props) => {
  const styles = useStyles();
  const elm = useRef(null);
  var canvasRef: Canvas | null;
  var previewCanvasRef: Canvas | null;

  const windowSizeContext = useContext(WindowSize);
  const EditContext = useContext(EditInfo);
  const [jsonObject, setJsonObject] = useState(null);
  const [jsonObjectectForModal, setJsonObjectForModal] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [openCanvasModal, setOpenCanvasModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const openCanvas = () => {
    setOpenCanvasModal(true);
  };
  const closeCanvas = (editing: boolean) => {
    if (editing) {
      const result: boolean = window.confirm(DialogMessages.OTHMAN);
      if (!result) return;
    }
    setOpenCanvasModal(false);
  };
  useEffect(() => {
    if (!canvasRef) return;
    if (!jsonObject) return;
    canvasRef.handler.clear();
    canvasRef.handler.importJSON(jsonObject);
    canvasRef.handler.zoomHandler.zoomToFit();
  }, [jsonObject]);

  useEffect(() => {
    canvasRef?.handler.zoomHandler.zoomToFit();
    previewCanvasRef?.handler.zoomHandler.zoomToFit();
  }, [windowSizeContext]);

  useEffect(() => {
    //編集ダイアログの表示タイミングでの読み込みは必要ないため
    if (openCanvasModal) return;

    setIsLoading(true);
    getSignedUrlForGet(props.illustrationS3Key).then(
      (url) => {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => blob.text())
          .then(async (text) => {
            const jsonObjects = JSON.parse(text).objects;
            const newJsonObjects = await Promise.all(
              jsonObjects.map(async (object) => {
                if (object.type === "image" || object.type === "video") {
                  // idを取得
                  const id = object.id;
                  // キャンバスの白い部分を除く
                  if (id === "workarea") return object;
                  // 取得したID対する表示用の署名付きURLを取得する
                  object.src = await getSignedUrlForGet(object.name);
                }
                return object;
              })
            );

            return newJsonObjects;
          })
          .then((newJsonObjects) => {
            setJsonObject(cloneDeep(newJsonObjects));
            setJsonObjectForModal(cloneDeep(newJsonObjects));
            setIsLoading(false);
          });
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  }, [props.illustrationS3Key, openCanvasModal, showPopup]);

  useEffect(() => {
    if (EditContext.editMode) return;
    setOpenCanvasModal(false);
  }, [EditContext.editMode]);

  return (
    <div className={styles.gridcontainer}>
      <Modal
        disableEnforceFocus
        aria-labelledby="simple-dialog-title"
        style={{ width: "85vw", height: "85vh", margin: "auto" }}
        open={openCanvasModal}
      >
        <ImageEditorContainer
          illustrationS3Key={props.illustrationS3Key}
          modelId={props.modelId}
          workStandardName={props.workStandardName}
          revisionNumber={props.revisionNumber}
          closeCanvas={closeCanvas}
        />
      </Modal>
      <Modal
        disableEnforceFocus
        aria-labelledby="simple-dialog-title"
        style={{ width: "85vw", height: "85vh", margin: "auto" }}
        open={showPopup}
        onClose={() => {
          setShowPopup(false);
        }}
      >
        <Canvas
          ref={(c) => {
            previewCanvasRef = c;
          }}
          editable={false}
          zoomEnabled={false}
          onLoad={(handler) => {
            handler.importJSON(jsonObjectectForModal);
            handler.zoomHandler.zoomToFit();
          }}
          style={{ position: "absolute", width: "85vw", height: "85vh" }}
        />
      </Modal>
      <Card className={styles.card} id={props.id} ref={elm} style={props.style}>
        {EditContext.editMode ? (
          <>
            <Button className={styles.illustButton} variant="contained" color="secondary" onClick={openCanvas}>
              <EditIcon />
            </Button>
            {!props.isMainIllust && (
              <Button
                className={styles.illustButton}
                disabled={props.revisionNumber !== revision.firstRevision}
                variant="contained"
                color="secondary"
                onClick={props.deleteIllustration}
              >
                <DeleteIcon />
              </Button>
            )}
            {props.isLastIllust && (
              <Button className={styles.illustButton} variant="contained" color="secondary" onClick={props.addIllustration}>
                <AddCircleIcon />
              </Button>
            )}
            {!props.isMainIllust && (
              <>
                <Button
                  disabled={props.isLastIllust}
                  className={styles.illustButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (props.changeIllustrationOrder) {
                      props.changeIllustrationOrder(1);
                    }
                  }}
                >
                  <ArrowRightIcon />
                </Button>
                <Button
                  disabled={props.isFirstIllust}
                  className={styles.illustButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (props.changeIllustrationOrder) {
                      props.changeIllustrationOrder(-1);
                    }
                  }}
                >
                  <ArrowLeftIcon />
                </Button>
              </>
            )}
          </>
        ) : (
          <Button
            className={styles.illustButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowPopup(true);
            }}
          >
            <AspectRatioIcon />
          </Button>
        )}
        {isLoading && (
          <div className={styles.loadingContainer}>
            <CircularProgress size={60} thickness={5} />
          </div>
        )}
        <Canvas
          ref={(c) => {
            canvasRef = c;
          }}
          editable={false}
          zoomEnabled={false}
          style={{ position: "relative" }}
        />
      </Card>
    </div>
  );
};
