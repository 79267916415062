import React, { useContext, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles, TextField } from "@material-ui/core";
import { ManagementBookHistoryLimits } from "../../consts/inputLimits";
import { LoginInfo } from "../../context/appContextData";
import { grey } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DialogButtonLabel, ManagementBookManagementTabLabel, ManagementBookPageLabel, WorkStandardManagementTabLabel } from "../../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    create: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      textAlign: "center",
      padding: 7,
    },
    cancel: {
      backgroundColor: grey[300],
      textAlign: "center",
      padding: 7,
    },
  })
);

export interface Props {
  open: boolean;
  onSubmit: (date) => void;
  onCancel: () => void;
  disableRegisterButton: boolean;
}

export const ApplicationDialog = (props: Props) => {
  const styles = useStyles();
  const LoginContext = useContext(LoginInfo);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Dialog aria-labelledby="definitive-regist-dialog-title" open={props.open}>
      <DialogTitle style={{ textAlign: "center" }} id="definitive-regist-dialog-title">
        {ManagementBookPageLabel.APPLICATION_DIALOG_TITLE}
      </DialogTitle>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="yyyy/MM/dd"
          margin="normal"
          id="date-picker-inline"
          label={ManagementBookManagementTabLabel.PUBLISH_DAY}
          style={{ width: "90%", margin: "0 auto" }}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
      <List>
        <ListItem>
          <TextField
            size="small"
            disabled
            label={ManagementBookPageLabel.REVISE_CONTENT}
            value={"新規作成"}
            inputProps={{ maxLength: ManagementBookHistoryLimits.INPUT_MAX_LENGTH }}
          />
        </ListItem>
        <ListItem>
          <TextField disabled size="small" defaultValue={LoginContext.loginUser.user_name} label={ManagementBookPageLabel.CREATOR} />
        </ListItem>
        <ListItem
          button
          disabled={props.disableRegisterButton}
          onClick={() => {
            props.onSubmit(selectedDate);
          }}
        >
          <ListItemText className={styles.create} primary={ManagementBookPageLabel.MANAGEMENT_BOOK_APPLICATION_DIALOG_BUTTON_LABEL} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.onCancel();
          }}
        >
          <ListItemText className={styles.cancel} primary={DialogButtonLabel.CANCEL} />
        </ListItem>
      </List>
    </Dialog>
  );
};
