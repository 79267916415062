import React, { useContext, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, Theme, Tooltip } from "@material-ui/core";
import { EditInfo, LoginInfo, Message } from "../../context/appContextData";
import { OperationHistoryDisplayGroup } from "./display/home";
import { DialogMessages, ErrorMessages, SuccessMessages } from "../../consts/messages";
import { HistoryList, Operation } from "../../apicaller/domain/response/operation";
import { sortByDesc } from "../../function/sort";
import { revision } from "../../consts/firstRevision";
import { purple } from "@material-ui/core/colors";
import { WorkFlowButtonLabel, WorkStandardManagementTabLabel } from "../../consts/label";
import { postReviseRequest, useUnderRevision } from "../../apicaller/repository/workflow";
import { ReviseRequest } from "../../apicaller/domain/request/workflow";
import { WorkFlowStatus } from "../../consts/workFlow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflow: "auto",
      textAlign: "center",
      height: "100%",
    },
    blankStyle: {},
    tableRow: {
      "&&:hover": {
        backgroundColor: purple[50],
      },
      cursor: "pointer",
    },
    currentRevisionTableRow: {
      backgroundColor: purple[50],
      "& > .MuiTableCell-root": {
        fontWeight: "bold",
      },
    },
    // header style

    headerCell: {
      whiteSpace: "nowrap",
    },
    // body style
    cellDiv: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    button: {
      marginInline: theme.spacing(1),
    },
  })
);

interface Props {
  operationId: number;
  workStandard: Operation;
  historyList: HistoryList;
  mutateHistoryList: () => void;
  onClickWorkStandardHistory: (workStandardId: number) => void;
  handleOpenWorkStandard: (workStandardId: number) => void;
}

export default function HistoryTable(props: Props) {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);
  const LoginContext = useContext(LoginInfo);
  const snackBarMessage = useContext(Message);

  const [isProcessing, setIsProcessing] = useState(false);

  const [
    underRevisionWorkStandard,
    getUnderRevisionWorkStandard,
    resetUnderRevisionWorkStandard,
    mutateUnderRevisionWorkStandard,
    underRevisionWorkStandardError,
  ] = useUnderRevision();

  useEffect(() => {
    getUnderRevisionWorkStandard(String(props.workStandard?.operation_id));
  }, [props.workStandard?.operation_id]);

  // 履歴テーブルの行をクリックした時の遷移処理
  const showPageHandler = (operationId: string) => {
    // 編集モードがオンの場合は遷移しない
    if (EditContext.editMode) return;

    // 現在と同じ作業標準の場合は遷移しない
    if (operationId === String(props.operationId)) return;

    // ログインユーザーの権限をチェックし、一般権限の場合はメッセージを表示してページ遷移しない。
    if (LoginContext.loginUser.authority === 0) {
      window.alert(DialogMessages.NOT_ALLOWED_SHOW_OLD_OPERATION);
      return;
    }

    // クリックしたページを表示する。
    props.onClickWorkStandardHistory(Number(operationId));
  };

  // 改定ボタン押下
  const handleReviseRequest = () => {
    // 改訂処理中チェック(連打防止)
    if (isProcessing) return;

    // 改訂処理開始
    setIsProcessing(true);

    const body = new ReviseRequest(props.operationId);
    postReviseRequest(body).then(
      (data: any) => {
        props.handleOpenWorkStandard(data.copied_operation_id);
        snackBarMessage.setMessage(SuccessMessages.INSERT_COPIED_OPERATION, "success");
        setIsProcessing(false);
      },
      (error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_COPIED_OPERATION, "error");
        setIsProcessing(false);
      }
    );
  };

  const isReviseButtonVisible = () => {
    return (
      props.workStandard?.status === WorkFlowStatus.PUBLISHED &&
      LoginContext.loginUser.authority >= 1 &&
      underRevisionWorkStandard !== undefined &&
      underRevisionWorkStandard?.under_revision_operation_id === 0
    );
  };

  // change in SWR data(sort for display history)
  const historyDisplay = new OperationHistoryDisplayGroup();
  if (props.historyList?.operation_history_list) {
    let sorted = new HistoryList();
    sorted.operation_history_list = sortByDesc(props.historyList.operation_history_list, "created_at");
    historyDisplay.setData(sorted);
  }

  return (
    <TableContainer className={styles.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={styles.headerCell}>{WorkStandardManagementTabLabel.REVISION}</TableCell>
            <TableCell className={styles.headerCell}>{WorkStandardManagementTabLabel.CREATED_AT}</TableCell>
            <TableCell className={styles.headerCell}>{WorkStandardManagementTabLabel.OVERVIEW}</TableCell>
            <TableCell className={styles.headerCell}>{WorkStandardManagementTabLabel.DETAIL}</TableCell>
            <TableCell className={styles.headerCell}>{WorkStandardManagementTabLabel.APPROVER}</TableCell>
            <TableCell className={styles.headerCell}>{WorkStandardManagementTabLabel.CREATOR}</TableCell>
            <TableCell className={styles.headerCell}>{WorkFlowButtonLabel.REVISE}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {historyDisplay.operationHistorys.map((historyData, index) => {
            let style = styles.tableRow;
            if (EditContext.editMode) {
              style = styles.blankStyle;
            } else if (props.operationId === Number(historyData.operation_id)) {
              style = styles.currentRevisionTableRow;
            }
            return (
              <TableRow className={style} key={index} onClick={() => showPageHandler(historyData.operation_id)}>
                <TableCell>
                  <div className={styles.cellDiv}>{historyData.revision_number}</div>
                </TableCell>
                <TableCell>
                  <div className={styles.cellDiv}>{historyData.date}</div>
                </TableCell>
                <TableCell>
                  <div className={styles.cellDiv}>{historyData.title}</div>
                </TableCell>
                <TableCell>
                  <div className={styles.cellDiv}>{historyData.revision_number === "1" ? "-" : historyData.detail}</div>
                </TableCell>
                <TableCell>
                  {historyData.revision_number === String(revision.firstRevision) ? (
                    <div className={styles.cellDiv}>{"-"}</div>
                  ) : (
                    <Tooltip title={historyData.approver_name + " (" + historyData.approver_login_id + ")"} placement={"bottom-start"}>
                      <div className={styles.cellDiv}>{historyData.approver_name}</div>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title={historyData.author_name + " (" + historyData.author_login_id + ")"} placement={"bottom-start"}>
                    <div className={styles.cellDiv}>{historyData.author_name}</div>
                  </Tooltip>
                </TableCell>
                {/* 改定ボタン */}
                <TableCell>
                  {index === 0 && (
                    <Button
                      disabled={!isReviseButtonVisible()}
                      className={styles.button}
                      size={"small"}
                      variant={"contained"}
                      color={"secondary"}
                      onClick={handleReviseRequest}
                    >
                      {WorkFlowButtonLabel.REVISE}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
