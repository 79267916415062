import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DialogButtonLabel, WorkStandardManagementTabLabel } from "../../consts/label";

export interface Props {
  open: boolean;
  onSubmit: (date) => void;
  onCancel: () => void;
}

export const PublishDayPickerDialog = (props: Props) => {

  //get the current date
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Dialog open={props.open} fullWidth maxWidth={"sm"}>
      <DialogTitle style={{ textAlign: "center" }}>{WorkStandardManagementTabLabel.PUBLISH_DAY_EDIT_TITLE}</DialogTitle>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="yyyy/MM/dd"
          margin="normal"
          id="date-picker-inline"
          label={WorkStandardManagementTabLabel.CREATED_AT}
          style={{ width: "90%", margin: "0 auto" }}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
      <DialogActions>
        <Button color="secondary" onClick={props.onCancel}>
          {DialogButtonLabel.CANCEL}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            props.onSubmit(selectedDate);
          }}
        >
          {DialogButtonLabel.REGIST}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
