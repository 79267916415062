import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { EditInfo, Message } from "../../context/appContextData";
import { deleteOperationEquipment, postEquipment, putEquipmentOther, useEquipment } from "../../apicaller/repository/operation";
import { OperationEquipmentRegistRequest } from "../../apicaller/domain/request/operation";
import { ErrorMessages, SuccessMessages } from "../../consts/messages";
import CompSafetyEquipmentChip from "./CompSafetyEquipmentChip";
import DialogEquipmentBoard from "./DialogEquipmentBoard";
import weldingMask from "../../icons/equipments/weldingMask.png";
import helmet from "../../icons/equipments/helmet.png";
import glasses from "../../icons/equipments/glasses.png";
import gloves from "../../icons/equipments/gloves.png";
import boot from "../../icons/equipments/boot.png";
import armCover from "../../icons/equipments/armCover.png";
import earplug from "../../icons/equipments/earplug.png";
import overall from "../../icons/equipments/overall.png";
import mask from "../../icons/equipments/mask.png";
import other from "../../icons/other.png";
import { Requirement, SafetyEquipment as SafetyEquipmentLabel } from "../../consts/label";
import { SafetyEquipment } from "../../apicaller/domain/response/operation";
import { ApiBody } from "../../utils/schemaUtil";
import { SafetyEquipmentChipForEdit } from "../WorkStandard/SafetyEquipmentChipForEdit";

interface Props {
  operationId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outterDiv: {
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      scrollSnapType: "y mandatory",
    },
    innerDiv: {
      flexGrow: 1,
      padding: "0 10px 0 10px",
      display: "flex",
      flexdirection: "row",
      flexWrap: "wrap",
    },
  })
);

const CompSafetyEquipmentChipGroup = (props: Props) => {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);
  const snackBarMessage = useContext(Message);

  const [equipmentList, getEquipmentList, resetEquipmentList, mutateEquipmentList, equipmentError] = useEquipment();

  const equipmentItems = [
    { avatarSourceId: 7, name: SafetyEquipmentLabel.FACE_COVER, image: weldingMask },
    { avatarSourceId: 8, name: SafetyEquipmentLabel.WELDING_MASK, image: weldingMask },
    { avatarSourceId: 1, name: SafetyEquipmentLabel.HELMET, image: helmet },
    { avatarSourceId: 9, name: SafetyEquipmentLabel.ANTI_STATIC_CAP, image: helmet },
    { avatarSourceId: 10, name: SafetyEquipmentLabel.CAP, image: helmet },
    { avatarSourceId: 11, name: SafetyEquipmentLabel.DUST_PROOF_HAT, image: helmet },
    { avatarSourceId: 44, name: SafetyEquipmentLabel.INNER_HELMET, image: helmet },
    { avatarSourceId: 2, name: SafetyEquipmentLabel.GLASSES, image: glasses },
    { avatarSourceId: 12, name: SafetyEquipmentLabel.WELDING_GLASSES, image: glasses },
    { avatarSourceId: 13, name: SafetyEquipmentLabel.SOLVENT_RESISTANT_GLASSES, image: glasses },
    { avatarSourceId: 14, name: SafetyEquipmentLabel.CHEMICAL_RESISTANT_GLASSES, image: glasses },
    { avatarSourceId: 15, name: SafetyEquipmentLabel.SOLVENT_RESISTANT_GOGGLES, image: glasses },
    { avatarSourceId: 16, name: SafetyEquipmentLabel.CHEMICAL_RESISTANT_GOGGLES, image: glasses },
    { avatarSourceId: 3, name: SafetyEquipmentLabel.GLOVES, image: gloves },
    { avatarSourceId: 17, name: SafetyEquipmentLabel.GAUNTLETS, image: gloves },
    { avatarSourceId: 18, name: SafetyEquipmentLabel.SIMON_GLOVES, image: gloves },
    { avatarSourceId: 19, name: SafetyEquipmentLabel.COTTON_GLOVES, image: gloves },
    { avatarSourceId: 20, name: SafetyEquipmentLabel.MEXCEL_GLOVES, image: gloves },
    { avatarSourceId: 21, name: SafetyEquipmentLabel.NYLON_GLOVES, image: gloves },
    { avatarSourceId: 22, name: SafetyEquipmentLabel.LEATHER_GLOVES, image: gloves },
    { avatarSourceId: 23, name: SafetyEquipmentLabel.ANTI_STATIC_GLOVES, image: gloves },
    { avatarSourceId: 24, name: SafetyEquipmentLabel.SOLVENT_RESISTANT_GLOVES, image: gloves },
    { avatarSourceId: 25, name: SafetyEquipmentLabel.CHEMICAL_RESISTANT_GLOVES, image: gloves },
    { avatarSourceId: 26, name: SafetyEquipmentLabel.OIL_RESISTANT_GLOVES, image: gloves },
    { avatarSourceId: 45, name: SafetyEquipmentLabel.CUT_RESISTANT_GLOVES, image: gloves },
    { avatarSourceId: 4, name: SafetyEquipmentLabel.SAFETY_SHOES, image: boot },
    { avatarSourceId: 27, name: SafetyEquipmentLabel.LEG_GUARDS, image: boot },
    { avatarSourceId: 28, name: SafetyEquipmentLabel.LEG_GAUNTLETS, image: boot },
    { avatarSourceId: 29, name: SafetyEquipmentLabel.ANTI_STATIC_SAFETY_SHOES, image: boot },
    { avatarSourceId: 30, name: SafetyEquipmentLabel.SAFETY_HALF_BOOTS, image: boot },
    { avatarSourceId: 31, name: SafetyEquipmentLabel.ANTI_STATIC_SAFETY_HALF_BOOTS, image: boot },
    { avatarSourceId: 32, name: SafetyEquipmentLabel.SAFETY_BOOTS, image: boot },
    { avatarSourceId: 33, name: SafetyEquipmentLabel.LEG_PROTECTOR, image: boot },
    { avatarSourceId: 46, name: SafetyEquipmentLabel.ACHILLES_TENDON_GUARD, image: boot },
    { avatarSourceId: 5, name: SafetyEquipmentLabel.ARM_COVER, image: armCover },
    { avatarSourceId: 34, name: SafetyEquipmentLabel.MEXCEL_ARM_COVER, image: armCover },
    { avatarSourceId: 6, name: SafetyEquipmentLabel.EARPLUG, image: earplug },
    { avatarSourceId: 35, name: SafetyEquipmentLabel.EARMUFFS, image: earplug },
    { avatarSourceId: 36, name: SafetyEquipmentLabel.APRON, image: overall },
    { avatarSourceId: 37, name: SafetyEquipmentLabel.DUST_PROOF_CLOTHING, image: overall },
    { avatarSourceId: 38, name: SafetyEquipmentLabel.SAFETY_BELT, image: overall },
    { avatarSourceId: 39, name: SafetyEquipmentLabel.SOLVENT_RESISTANT_APRON, image: overall },
    { avatarSourceId: 40, name: SafetyEquipmentLabel.CHEMICAL_RESISTANT_APRON, image: overall },
    { avatarSourceId: 41, name: SafetyEquipmentLabel.MASK, image: mask },
    { avatarSourceId: 42, name: SafetyEquipmentLabel.GAS_MASK, image: mask },
    { avatarSourceId: 43, name: SafetyEquipmentLabel.DUST_MASK, image: mask },
    { avatarSourceId: 47, name: SafetyEquipmentLabel.OTHER, image: other },
  ];

  //#region チップ操作
  // チップ追加
  const handleChipAdd = (equipment_id: number) => {
    const reqData = new OperationEquipmentRegistRequest(Number(props.operationId), equipment_id);
    postEquipment(reqData).then(
      () => {
        mutateEquipmentList();
        snackBarMessage.setMessage(SuccessMessages.INSERT_SAFETY_EQUIPMENT, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_SAFETY_EQUIPMENT, "error");
      }
    );
  };

  // チップ編集
  const handleChipEdit = (safety_equipment_necessity_id: number, content: string) => {
    putEquipmentOther({
      safety_equipment_necessity_id: safety_equipment_necessity_id,
      content: content,
    } as ApiBody<"/work_standard/safety_equipment_other", "put">)
      .then(() => {
        mutateEquipmentList();
        snackBarMessage.setMessage(SuccessMessages.INSERT_SAFETY_EQUIPMENT, "success");
      })
      .catch((error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_SAFETY_EQUIPMENT, "error");
      });
  };

  // チップ削除
  const handleChipDelete = (operationPointId: number) => {
    deleteOperationEquipment(String(operationPointId)).then(
      () => {
        mutateEquipmentList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_SAFETY_EQUIPMENT, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.DELETE_SAFETY_EQUIPMENT, "error");
      }
    );
  };
  //#endregion

  const dialogEquipmentList = useMemo(() => {
    if (!equipmentList || !equipmentList.safety_equipment) return [];

    const displayItem = equipmentItems.filter((item) => {
      if (item.avatarSourceId === 47) {
        return true;
      }
      if (equipmentList.safety_equipment.find((e) => e.safety_equipment_id === item.avatarSourceId)) {
        return false;
      }
      return true;
    });
    return displayItem ? displayItem : [];
  }, [equipmentList]);

  useEffect(() => {
    getEquipmentList(Number(props.operationId));
  }, [props.operationId]);

  useEffect(() => {
    if (equipmentError) snackBarMessage.setMessage(ErrorMessages.GET_SAFETY_EQUIPMENT, "error");
  }, [equipmentError]);

  return (
    <div className={styles.outterDiv}>
      <div className={styles.innerDiv}>
        {equipmentList?.safety_equipment.map((safetyEquipment) => {
          if (safetyEquipment.safety_equipment_id === 47) {
            return (
              <SafetyEquipmentChipForEdit
                content={safetyEquipment.content}
                icon={other}
                equipmentNecessityId={safetyEquipment.safety_equipment_necessity_id}
                handleDelete={handleChipDelete}
                handleUpdate={handleChipEdit}
              />
            );
          } else {
            return <CompSafetyEquipmentChip safetyEquipmentData={safetyEquipment} handleDelete={handleChipDelete} equipmentItems={equipmentItems} />;
          }
        })}
        {EditContext.editMode && <DialogEquipmentBoard AddHandle={handleChipAdd} equipmentItems={dialogEquipmentList} />}
      </div>
    </div>
  );
};

export default CompSafetyEquipmentChipGroup;
