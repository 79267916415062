import React, { useEffect, useMemo } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useOperationSignByWork } from "../../apicaller/repository/managementbook";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { formatDate } from "../../function/formatDatetime";

const useStyles = makeStyles(() =>
  createStyles({
    tabelCell: {
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    managementBookContainer: {
      height: "100%",
      flexGrow: 1,
      overflow: "auto",
    },
  })
);

export interface Props {
  management_book_id: number;
}

interface userItem {
  login_id: string;
  name: string;
}

export const OperatorSignByWorkList = (props: Props) => {
  const styles = useStyles();

  const [operationSignByWork, getOperationSignByWork, resetOperationSignByWork, mutateOperationSignByWork, operationSignByWorkError] =
    useOperationSignByWork();

  const signedUserList = useMemo<userItem[]>(() => {
    let ret: userItem[] = [];

    if (!operationSignByWork?.operator_sign_of_process_list) {
      return ret;
    }

    for (const i of operationSignByWork.operator_sign_of_process_list) {
      for (const j of i.operator_signed_list) {
        for (const operator_signed_list of j.operator_signed_list) {
          const loginId = operator_signed_list.login_id;
          const exists = ret.some((item) => item.login_id === loginId);
          if (!exists) {
            ret.push({
              login_id: loginId,
              name: operator_signed_list.user_name,
            });
          }
        }
      }
    }

    return ret;
  }, [operationSignByWork]);

  useEffect(() => {
    if (props.management_book_id) {
      getOperationSignByWork(props.management_book_id);
    }
  }, [props.management_book_id]);

  return (
    <TableContainer className={styles.managementBookContainer}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={styles.tabelCell} align={"center"}>工程名</TableCell>
            <TableCell className={styles.tabelCell} align={"center"}>作業標準名</TableCell>
            {signedUserList.map((user, index) => (
              <Tooltip title={user.name + " (" + user.login_id + ")"}>
                <TableCell className={styles.tabelCell} key={index} align={"center"}>
                  {user.name}
                </TableCell>
              </Tooltip>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {operationSignByWork?.operator_sign_of_process_list?.map((process, pIndex) => {
            return process.operator_signed_list.map((work, index) => {
              return (
                <TableRow key={index}>
                  {index === 0 && <TableCell className={styles.tabelCell} rowSpan={process.operator_signed_list.length}>{process.process_name}</TableCell>}
                  <TableCell className={styles.tabelCell}>{work.ws_name}</TableCell>
                  {signedUserList.map((user, index) => {
                    const signed_date = work.operator_signed_list.find((sign, index) => sign.login_id === user.login_id)?.signed_date;
                    return (
                      <TableCell key={index} className={styles.tabelCell} align={"center"}>
                        {signed_date ? formatDate(signed_date) : "-"}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            });
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
