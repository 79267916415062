import { useState } from "react";
import useSWR from "swr";
import { get } from "../apiCaller";
import { ApiMethod, ApiName, createEndPoint } from "../createEndPoint";
import { WorkStandardListResponse } from "../domain/response/workstandardlist";
import { ApiBody, ApiResponse } from "../../utils/schemaUtil";
import { AxiosError, AxiosResponse } from "axios";
import * as $axios from "../../utils/axiosUtils";
import { useAppSWR } from "../../hooks/useAppSWR";


//---WorkStandardList---
export const useWorkStandardList = (): [WorkStandardListResponse, (team_id: string, model_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (team_id: string, model_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.WorkStandardList, method: ApiMethod.Get }, [team_id, model_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, resetData, mutateData, error];
};

export const putWorkStandardPublishDayAll = (body: ApiBody<"/work_standard_list/publish_day_all", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/work_standard_list/publish_day_all",
    method: "put",
    data: body,
  });
};

export const useAproveStatusList = (): [
  ApiResponse<"/work_standard_list/approve_status_all", "get"> | undefined,
  (team_id: number, model_id: number) => void,
  () => void,
  () => void,
  AxiosError | undefined
] => {
  const [teamID, setTeamID] = useState<number>(0);
  const [modelID, setModelID] = useState<number>(0);
  const { data, mutate, error } = useAppSWR((teamID !== 0 && modelID !== 0)?{
    url: "/work_standard_list/approve_status_all",
    method: "get",
    params: {
      team_id: teamID,
      model_id: modelID,
    },
  }:undefined);

  const getData = (team_id: number, model_id: number) => {
    mutate();
    setTeamID(team_id);
    setModelID(model_id);
  };
  const resetData = () => {
    mutate(undefined, false);
  };
  const mutateData = () => {
    mutate();
  };
  return [data, getData, resetData, mutateData, error];
};