import React, { useContext, useState } from "react";
import { Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { EditInfo, Message } from "../../context/appContextData";
import { ProcessApplicable, ProcessRows } from "./display/operationManagementBook";
import ProcessManagementBookDialog, { ProcessManagementBookDialogAction } from "./ProcessManagementBookDialog";
import { DialogMessages, ErrorMessages, SuccessMessages, TooltipMessages, WarningMessages } from "../../consts/messages";
import {
  deleteProcess,
  deleteProcessApplicable,
  postProcess,
  postProcessApplicable,
  putProcessName,
} from "../../apicaller/repository/managementbook";
import { ProcessNameUpdateRequest, ProcessRegistRequest } from "../../apicaller/domain/request/managementbook";
import { ManagementBookProcessListTabLabel } from "../../consts/label";
import ProcessApplicableDialog from "./ProcessApplicableDialog";
import { OperatorSignByWorkDialog } from "./OperatorSignByWorkDialog";
import { ApiBody } from "../../utils/schemaUtil";

interface Props {
  processDisplayRows: ProcessRows;
  managementBookId: number;
  handleRefleshProcess: any;
  handleSwapProcess: (dropResult: DropResult) => void;
  process_applicable: ProcessApplicable[];
}

const useStyles = makeStyles(() =>
  createStyles({
    div: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    headerContainer: {
      overflow: "auto",
      alignSelf: "stretch",
      borderBottomRightRadius: "0px",
      borderBottomLeftRadius: "0px",
      width: "100%",
    },
    // カラムを上に固定する(へッダー)
    stickyRow: {
      position: "sticky",
      top: 0,
      backgroundColor: "#fff",
      zIndex: 3,
    },
    // カラムを左に固定する
    stickyHeaderColLeft: {
      position: "sticky",
      left: 0,
      zIndex: 3,
      backgroundColor: "#fff",
    },
    // カラムを右に固定する
    stickyHeaderColRight: {
      position: "sticky",
      right: 0,
      zIndex: 3,
      backgroundColor: "#fff",
    },
    // カラムを左に固定する
    stickyBodyColLeft: {
      position: "sticky",
      left: 0,
      zIndex: 2,
      backgroundColor: "#fff",
    },
    // カラムを右に固定する
    stickyBodyColRight: {
      position: "sticky",
      right: 0,
      zIndex: 2,
      backgroundColor: "#fff",
    },
    row: {
      height: "50px",
      width: "100%",
      display: "table-row",
    },
    orderCell: {
      minWidth: "5%",
      height: "auto !important",
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    nameCell: {
      minWidth: "30%",
      height: "auto !important",
      overflow: "visible",
      whiteSpace: "nowrap",
    },
    applicableCell: {
      height: "auto !important",
      whiteSpace: "nowrap",
    },
    dragable: {
      backgroundColor: "grey",
    },
    dragableRow: {
      display: "table-row !important",
    },
    applicableItemCell: {
      minWidth: "5%",
      textAlign: "center",
      height: "auto !important",
      whiteSpace: "nowrap",
      zIndex: 1,
    },
    buttonCell: {
      minWidth: "10%",
      whiteSpace: "nowrap",
      textAlign: "center",
    },
    iconButton: {
      margin: "auto",
    },
    applicableCheckbox: {
      margin: "auto",
    },
    bodyContainer: {
      overflow: "auto",
      flexGrow: 1,
      alignSelf: "stretch",
    },
  })
);

export const ProcessManagementBookTable = (props: Props) => {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);
  const snackBarMessage = useContext(Message);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [targetProcessId, setTargetProcessId] = useState<number>(0);

  const [openAddProcessApplicableDialog, setOpenAddProcessApplicableDialog] = useState<boolean>(false);
  const [processIdForUpdateApplicable, setProcessIdForUpdateApplicable] = useState<number>(0);

  const [operationSignByWorkDialogOpen, setOperationSignByWorkDialogOpen] = useState<boolean>(false);
  const [processForSignDialog, setProcessForSignDialog] = useState<number>(0);

  const [isAdd, setIsAdd] = useState(false);
  const [inputText, setInputText] = useState("");

  // 追加ボタン押下時
  const handleClickAdd = () => {
    setIsAdd(true);
    setOpenDialog(true);
  };

  // 編集ボタン押下時
  const handleClickEdit = (id: number, name: string) => {
    setIsAdd(false);
    setTargetProcessId(id);
    setInputText(name);
    setOpenDialog(true);
  };

  const handleDelete = (id: number) => {
    if (window.confirm(DialogMessages.DELETE_OBJECT)) {
      deleteProcess(String(id))
        .then(() => {
          props.handleRefleshProcess();
          snackBarMessage.setMessage(SuccessMessages.DELETE_PROCESS, "success");
        })
        .catch((error: any) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.DELETE_PROCESS, "error");
        });
    }
    return;
  };

  // ダイアログ入力項目変更時
  const handleChangeText = (text: string) => {
    setInputText(text);
  };

  // ダイアログボタン押下時
  const handleDialogAction = (name: string, action: ProcessManagementBookDialogAction) => {
    // キャンセルボタン押下時処理
    if (action === ProcessManagementBookDialogAction.cancel) {
      setTargetProcessId(0);
      setInputText("");
      setOpenDialog(false);
      return;
    }

    //文字の空チェック
    if (name === "") {
      snackBarMessage.setMessage(WarningMessages.DATAMANAGEMENT_INPUT_LENGTH, "warning");
      return;
    }

    // 工程登録API処理
    if (action === ProcessManagementBookDialogAction.create) {
      postProcess(new ProcessRegistRequest(name, props.managementBookId)).then(
        () => {
          props.handleRefleshProcess();
          setTargetProcessId(0);
          setInputText("");
          setOpenDialog(false);
          snackBarMessage.setMessage(SuccessMessages.INSERT_PROCESS, "success");
        },
        (error: any) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.INSERT_PROCESS, "error");
        }
      );
      return;
    }
    // 工程名変更API処理
    if (action === ProcessManagementBookDialogAction.edit) {
      putProcessName(new ProcessNameUpdateRequest(targetProcessId, name)).then(
        () => {
          props.handleRefleshProcess();
          setTargetProcessId(0);
          setInputText("");
          setOpenDialog(false);
          snackBarMessage.setMessage(SuccessMessages.UPDATE_PROCESS, "success");
        },
        (error: any) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.UPDATE_PROCESS, "error");
        }
      );
      return;
    }
  };

  const handlePostProcessApplicable = (process_id: number, process_applicable: ProcessApplicable) => {
    postProcessApplicable({
      process_id: process_id,
      model_year: process_applicable.model_year,
      model_name: process_applicable.model_name,
      derivation: process_applicable.derivation,
      engine_type: process_applicable.engine_type,
    } as ApiBody<"/management_book/process_applicable", "put">)
      .then(() => {
        props.handleRefleshProcess();
        snackBarMessage.setMessage(SuccessMessages.INSERT_PROCESS_APPLICABLE, "success");
      })
      .catch((error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_PROCESS_APPLICABLE, "error");
      });
  };

  const handleDeleteProcessApplicable = (process_applicable_id: number) => {
    deleteProcessApplicable(process_applicable_id)
      .then(() => {
        props.handleRefleshProcess();
        snackBarMessage.setMessage(SuccessMessages.DELETE_PROCESS_APPLICABLE, "success");
      })
      .catch((error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.DELETE_PROCESS_APPLICABLE, "error");
      });
  };

  return (
    <div className={styles.div}>
      <ProcessManagementBookDialog
        open={openDialog}
        isAdd={isAdd}
        inputText={inputText}
        handleChangeText={handleChangeText}
        handleDialogAction={handleDialogAction}
      />

      <ProcessApplicableDialog
        open={openAddProcessApplicableDialog}
        process_applicable_id={processIdForUpdateApplicable}
        handleCloseDialog={() => {
          setOpenAddProcessApplicableDialog(false);
          props.handleRefleshProcess();
        }}
      />

      <OperatorSignByWorkDialog
        open={operationSignByWorkDialogOpen}
        management_book_id={props.managementBookId}
        process_id={processForSignDialog}
        onClose={() => {
          setOperationSignByWorkDialogOpen(false);
        }}
      />

      {/* ここのテーブルヘッダーはボディのdrag機能の影響で
          固定できないため、TableContainerは別れて書いてます　 */}

      <TableContainer component={Paper} className={styles.headerContainer}>
        <Table>
          <TableHead className={`${styles.stickyRow}`}>
            <TableRow>
              <TableCell className={`${styles.stickyHeaderColLeft} ${styles.orderCell}`} rowSpan={5}>
                {ManagementBookProcessListTabLabel.PROCESS_ORDER}
              </TableCell>
              <TableCell className={`${styles.stickyHeaderColLeft} ${styles.nameCell}`} align={"left"} rowSpan={5}>
                {ManagementBookProcessListTabLabel.PROCESS_NAME}
              </TableCell>
              <TableCell
                className={`${styles.applicableCell}`}
                align="center"
                colSpan={props.process_applicable?.length + Number(EditContext.editMode)}
              >
                {ManagementBookProcessListTabLabel.PROCESS_APPLICABLE}
              </TableCell>
              <TableCell align="center" rowSpan={5}>
                {ManagementBookProcessListTabLabel.OPERATOR_SIGN_LIST}
              </TableCell>
              {EditContext.editMode && (
                <TableCell className={`${styles.stickyHeaderColRight} ${styles.buttonCell}`} rowSpan={5}>
                  <IconButton size="small" onClick={handleClickAdd}>
                    <AddCircleIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              {props.process_applicable?.map((applicable) => {
                return <TableCell className={`${styles.applicableItemCell}`}>{applicable.model_year}</TableCell>;
              })}
              {EditContext.editMode && (
                <TableCell className={styles.applicableItemCell} rowSpan={4}>
                  {ManagementBookProcessListTabLabel.ADD_PROCESS_APPLICABLE}
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              {props.process_applicable?.map((applicable) => {
                return <TableCell className={`${styles.applicableItemCell}`}>{applicable.model_name}</TableCell>;
              })}
            </TableRow>
            <TableRow>
              {props.process_applicable?.map((applicable) => {
                return <TableCell className={`${styles.applicableItemCell}`}>{applicable.derivation}</TableCell>;
              })}
            </TableRow>
            <TableRow>
              {props.process_applicable?.map((applicable) => {
                return <TableCell className={`${styles.applicableItemCell}`}>{applicable.engine_type}</TableCell>;
              })}
            </TableRow>
          </TableHead>

          <Container
            dragHandleSelector=".dragHandleSelector"
            lockAxis="y"
            onDrop={props.handleSwapProcess}
            render={(ref) => (
              <TableBody ref={ref}>
                {props.processDisplayRows.processRows.map((row, index) => {
                  return (
                    <Draggable
                      key={index}
                      className={styles.dragable}
                      render={() => {
                        return (
                          <>
                            <TableRow className={styles.dragableRow + " smooth-dnd-draggable-wrapper"} tabIndex={-1} key={row.process_id}>
                              <TableCell className={`${styles.stickyBodyColLeft} ${styles.orderCell}`}>{index + 1}</TableCell>
                              <TableCell className={`${styles.stickyBodyColLeft} ${styles.nameCell}`} align={"left"}>
                                {row.process_name}
                              </TableCell>
                              {props.process_applicable?.length === 0 && !EditContext.editMode && (
                                <TableCell className={styles.applicableItemCell} align={"center"}></TableCell>
                              )}
                              {props.process_applicable?.map((process_item) => {
                                const checked = process_item.process_id.find((item) => (item.process_id === row.process_id ? item : null));
                                if (EditContext.editMode) {
                                  return (
                                    <TableCell role="checkbox" className={styles.applicableItemCell} align={"center"}>
                                      <Checkbox
                                        className={styles.applicableCheckbox}
                                        checked={!!checked}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            handlePostProcessApplicable(row.process_id, process_item);
                                          } else {
                                            handleDeleteProcessApplicable(checked!.process_applicable_id);
                                          }
                                        }}
                                      />
                                    </TableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell className={styles.applicableItemCell} style={{ fontWeight: "bold", fontSize: "25px" }} align={"left"}>
                                      {!!checked ? "○" : "-"}
                                    </TableCell>
                                  );
                                }
                              })}
                              {EditContext.editMode && (
                                <>
                                  <TableCell className={styles.applicableItemCell} align={"center"}>
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setOpenAddProcessApplicableDialog(true);
                                        setProcessIdForUpdateApplicable(row.process_id);
                                      }}
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  </TableCell>
                                </>
                              )}
                              <TableCell className={styles.applicableItemCell} align={"center"}>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setProcessForSignDialog(row.process_id);
                                    setOperationSignByWorkDialogOpen(true);
                                  }}
                                >
                                  <AssignmentIcon />
                                </IconButton>
                              </TableCell>
                              {EditContext.editMode && (
                                <>
                                  <TableCell className={`${styles.stickyHeaderColRight} ${styles.buttonCell}`}>
                                    <IconButton
                                      className={styles.iconButton}
                                      size="small"
                                      onClick={() => handleClickEdit(row.process_id, row.process_name)}
                                    >
                                      <CreateIcon />
                                    </IconButton>
                                    <Tooltip title={row.deletable ? "" : TooltipMessages.UNDELETABLE_PROCESS}>
                                      <span>
                                        <IconButton size="small" onClick={() => handleDelete(row.process_id)} disabled={!row.deletable}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                    <IconButton size="small" className="dragHandleSelector">
                                      <DragHandleIcon />
                                    </IconButton>
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          </>
                        );
                      }}
                    ></Draggable>
                  );
                })}
              </TableBody>
            )}
          />
        </Table>
      </TableContainer>
    </div>
  );
};
