import React, { useContext, useEffect, useRef, useState } from "react";
import Chip from "@material-ui/core/Chip";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EditInfo } from "../../context/appContextData";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { WorkStandardSafetyPointLimits } from "../../consts/inputLimits";

interface Props {
  id: number;
  chipContent: string;
  comboBoxOptions: string[];
  handleDelete: (id: number) => void;
  handleUpdate: (id: number, e: string) => void;
  isError: Boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexdirection: "row",
      padding: 5,
      scrollSnapAlign: "center",
    },
    chip: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 70,
      "& .MuiChip-label": {
        // wordWrap: "break-word",
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
    comboBox: {
      flexGrow: 1,
      padding: 5,
      width: 300,
    },
    chiperror: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 70,
      backgroundColor: "#F75D59",
      "& .MuiChip-label": {
        // wordWrap: "break-word",
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
  })
);

export const ComboBoxChip = (props: Props) => {
  const styles = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const EditContext = useContext(EditInfo);

  const [onEdit, setOnEdit] = useState(false);

  // comboBox入力欄のフォーカスが外れた時の処理
  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.handleUpdate(props.id, e.target.value);
    setOnEdit(false);
  };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      if (!e.nativeEvent.isComposing) {
        props.handleUpdate(props.id, (e.target as HTMLInputElement).value);
        setOnEdit(false);
      }
    }
  };

  const voiceFunc = (readingMsg: string) => {
    var msg = new SpeechSynthesisUtterance();
    msg.text = readingMsg;
    msg.lang = "ja-JP";
    window.speechSynthesis.speak(msg);
  };

  useEffect(() => {
    if (onEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [onEdit]);

  return (
    <div className={styles.root}>
      {EditContext.editMode ? (
        <Chip
          className={props.isError ? styles.chiperror : styles.chip}
          label={
            onEdit ? (
              <div className={styles.comboBox}>
                <Autocomplete
                  open={onEdit}
                  className={styles.comboBox}
                  disableClearable={true}
                  noOptionsText={"no data found"}
                  options={props.comboBoxOptions}
                  getOptionLabel={(option) => option}
                  size="small"
                  onKeyPress={handleOnKeyPress}
                  value={props.chipContent}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onBlur={handleOnBlur}
                      inputRef={inputRef}
                      inputProps={{
                        ...params.inputProps, // このエラーを防止：Cannot read properties of null (reading 'setAttribute')
                        maxLength: WorkStandardSafetyPointLimits.INPUT_MAX_LENGTH,
                      }}
                      variant="standard"
                    />
                  )}
                />
              </div>
            ) : (
              <div>{props.chipContent}</div>
            )
          }
          onDelete={() => props.handleDelete(props.id)}
          onClick={() => setOnEdit(true)}
        />
      ) : (
        <Chip className={styles.chip} label={props.chipContent} onClick={() => voiceFunc(props.chipContent)} />
      )}
    </div>
  );
};
