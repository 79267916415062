import { cloneDeep } from "lodash";
import { PublishedOperationList, UnlinkedWordStandard } from "../../../apicaller/domain/response/operationlist";
import { sortByAsc } from "../../../function/sort";
import { arrayMoveImmutable } from "array-move"; // 配列移動に使用するライブラリ
import { DropResult } from "react-smooth-dnd";
import {
  Operation,
  OperationInProcess,
  OperationInProcessResponse,
  ProcessList,
  ProcessListResponse,
} from "../../../apicaller/domain/response/managementbook";

// OperationList下のdisplayのため、ファイル名はoperationList.ts
export class ProcessRows {
  processRows: Process[] = [];

  public setData(processList: ProcessListResponse, operationInProcess: OperationInProcessResponse) {
    this.processRows = processList.process.map((e: ProcessList): Process => {
      const deletable = operationInProcess.process_list.some(
        (v: OperationInProcess) => v.process_id === e.process_id && v.operation_list.length === 0
      );
      return { process_id: e.process_id, process_name: e.process_name, process_order: e.process_order, deletable: deletable };
    });
    return cloneDeep(this);
  }

  public sortByAsc() {
    this.processRows = sortByAsc(this.processRows, "process_order");
    return cloneDeep(this);
  }

  public replaceData(dropResult: DropResult) {
    const { removedIndex, addedIndex } = dropResult;
    this.processRows = arrayMoveImmutable(this.processRows, removedIndex || 0, addedIndex || 0);
    return cloneDeep(this);
  }
}

export class Process {
  process_id: number = 0;
  process_name: string = "";
  process_order: number = 0;
  deletable: boolean = false;
}

export class ProcessApplicable {
  model_year: string = "";
  model_name: string = "";
  derivation: string = "";
  engine_type: string = "";
  process_id: ProcessIdAndProcessApplicableID[] = [];
}

export class ProcessIdAndProcessApplicableID {
  process_id: number = 0;
  process_applicable_id: number = 0;
}

export class OperationInProcessDisplayRows {
  processRows: OperationInProcessDisplay[] = [];
  unlinkedWorkStandardRows: UnlinkedWordStandardDisplay[] = [];

  public setData(operationList: OperationInProcessResponse) {
    //Processをソートして、表示用Classに設置
    this.processRows = sortByAsc(
      operationList.process_list.map((process: OperationInProcess): OperationInProcessDisplay => {
        //Process中のOperationを分離してソートする
        const tmpOperation: OperationDisplay[] = process.operation_list.map((operation: Operation): OperationDisplay => {
          return {
            operation_in_process_id: operation.operation_in_process_id,
            operation_id: operation.operation_id,
            operation_order: operation.operation_order,
            operation_name: operation.operation_name,
            managed_number: operation.managed_number,
          };
        });
        let tmpOperationSorted: OperationDisplay[] = sortByAsc(tmpOperation, "operation_order");
        //Display用Process生成
        return {
          process_id: process.process_id,
          process_name: process.process_name,
          process_order: process.process_order,
          operation_list: tmpOperationSorted,
        };
      }),
      "process_order"
    );

    this.unlinkedWorkStandardRows = sortByAsc(
      operationList.unlinked_operation_list.map((unlinkWorkStandard: UnlinkedWordStandard): UnlinkedWordStandardDisplay => {
        //Display用Process生成
        return {
          operation_id: unlinkWorkStandard.operation_id,
          operation_name: unlinkWorkStandard.operation_name,
          managed_number: unlinkWorkStandard.managed_number,
        };
      }),
      "operation_id"
    );

    return this;
  }

  public replaceData(processIndex: number, dropResult: DropResult) {
    const { removedIndex, addedIndex } = dropResult;
    this.processRows[processIndex].operation_list = arrayMoveImmutable(
      this.processRows[processIndex].operation_list,
      removedIndex || 0,
      addedIndex || 0
    );
    return cloneDeep(this);
  }

  //工程リストの中の一番最初の作業標準情報を取得する
  //工程リストと作業標準の紐付けが１つもできていない場合はnewしたオブジェクトをそのままreturnする
  public getInitialOperationData(): OperationDisplay {
    var ret = new OperationDisplay();
    for (let process of this.processRows) {
      //operationListが存在しない場合はreturn
      if (process.operation_list.length === 0) continue;
      //初期値がある場合はbreakする
      ret = process.operation_list[0];
      break;
    }
    return ret;
  }

  //工程リストの中の作業標準情報がある一番最初の工程IDを取得する
  public getInitialProcessId(): number {
    var ret = 0;
    for (let process of this.processRows) {
      //operationListが存在しない場合はreturn
      if (process.operation_list.length === 0) continue;
      //初期値がある場合はbreakする
      ret = process.process_id;
      break;
    }
    return ret;
  }

  // 作業標準名の「前へ(<)」押下時、
  // 作業が登録されている工程のindex(表示する対象)を取得する
  public getPrevProcessIndex(selectedProcessIndex: number): number {
    var index = -1;
    // 現在表示されている工程より上に対象があるかをチェックし、存在する場合は対象indexを取得
    for (let i = selectedProcessIndex - 1; i >= 0; i--) {
      if (this.processRows[i].operation_list.length === 0) continue;
      index = i;
      break;
    }
    // 現在表示されている工程より上に対象がなかったときは一番下からチェックし、存在する場合は対象indexを取得
    if (index === -1) {
      for (let i = this.processRows.length - 1; i >= 0; i--) {
        if (this.processRows[i].operation_list.length === 0) continue;
        index = i;
        break;
      }
    }
    return index;
  }

  // 作業標準名の「次へ(>)」押下時、
  // 作業が登録されている工程のindex(表示する対象)を取得する
  public getNextProcessIndex(selectedProcessIndex: number): number {
    var index = -1;
    // 現在表示されている工程より下に対象があるかをチェックし、存在する場合は対象indexを取得
    for (let i = selectedProcessIndex + 1; i < this.processRows.length; i++) {
      if (this.processRows[i].operation_list.length === 0) continue;
      index = i;
      break;
    }
    // 現在表示されている工程より下に対象がなかったときは一番上からチェックし、存在する場合は対象indexを取得
    if (index === -1) {
      for (let i = 0; i < this.processRows.length; i++) {
        if (this.processRows[i].operation_list.length === 0) continue;
        index = i;
        break;
      }
    }
    return index;
  }
}

export class OperationInProcessDisplay {
  process_id: number = 0;
  process_name: string = "";
  process_order: number = 0;
  operation_list: OperationDisplay[] = [];
}

export class UnlinkedWordStandardDisplay {
  operation_id: number = 0;
  operation_name: string = "";
  managed_number: number = 0;
}

export class OperationDisplay {
  operation_in_process_id: number = 0;
  operation_id: number = 0;
  operation_order: number = 0;
  operation_name: string = "";
  managed_number: number = 0;
}

export class PublishedOperationDisplay {
  managed_number: string[] = [];
  operation_name: string[] = [];
  operation_id: number[] = [];

  public setData(data: PublishedOperationList, registeredOperation: OperationInProcessDisplayRows) {
    let operation_book_list = data.operation_list.filter((row) => {
      let registered = false;
      for (let processIndex = 0; processIndex < registeredOperation.processRows.length; processIndex++) {
        for (let operationIndex = 0; operationIndex < registeredOperation.processRows[processIndex].operation_list.length; operationIndex++) {
          if (row.managed_number === registeredOperation.processRows[processIndex].operation_list[operationIndex].managed_number) {
            registered = true;
          }
        }
      }
      // 作業未登録の場合のみ管理番号/作業標準名/作業標準IDを返す
      return !registered;
    });

    if (operation_book_list) {
      // コンボボックスに表示する管理番号/作業標準名(作業標準ID)をセット
      this.managed_number = operation_book_list.map((operation_book) => String(operation_book.managed_number));
      this.operation_name = operation_book_list.map((operation_book) => operation_book.operation_name);
      this.operation_id = operation_book_list.map((operation_book) => operation_book.operation_id);
    }

    return cloneDeep(this);
  }
}
