import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "i18next";
import { Tooltip } from "@material-ui/core";

import { Flex } from "../flex";
import { CommonButton } from "../common";
import { DialogMessages } from "../../../consts/messages";

class ImageMapHeaderToolbar extends Component {
  static propTypes = {
    canvasRef: PropTypes.any,
    selectedItem: PropTypes.object,
    onDownloadFiles: PropTypes.func,
  };

  state = {
    open: false,
  };

  render() {
    const { canvasRef, selectedItem, onDownloadFiles } = this.props;
    const isCropping = canvasRef ? canvasRef.handler?.interactionMode === "crop" : false;
    return (
      <Flex className="rde-editor-header-toolbar-container" flex="1">
        <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-add">
          <Tooltip title={i18n.t("image-editor.image-upload")}>
            <span>
              <CommonButton
                className="rde-action-btn"
                shape="circle"
                disabled={isCropping}
                onClick={() => {
                  const imageUploader = document.getElementById("image upload dialog");
                  imageUploader.click();
                  return;
                }}
                icon="image"
                iconClassName="rde-action-btn-icon"
              />
            </span>
          </Tooltip>
          <Tooltip title={i18n.t("image-editor.video-upload")}>
            <span>
              <CommonButton
                className="rde-action-btn"
                shape="circle"
                disabled={isCropping}
                onClick={() => {
                  const videoUploader = document.getElementById("video upload dialog");
                  videoUploader.click();
                  return;
                }}
                icon="film"
                iconClassName="rde-action-btn-icon"
              />
            </span>
          </Tooltip>
        </Flex.Item>
        <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-download">
          <Tooltip title={i18n.t("image-editor.image-download")}>
            <span>
              <CommonButton
                className="rde-action-btn"
                shape="circle"
                disabled={isCropping}
                onClick={onDownloadFiles}
                icon="download"
                iconClassName="rde-action-btn-icon"
              />
            </span>
          </Tooltip>
        </Flex.Item>
        <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-delete">
          <Tooltip title={i18n.t("image-editor.delete")}>
            <span>
              <CommonButton
                className="rde-action-btn"
                shape="circle"
                disabled={!selectedItem}
                onClick={() => {
                  const result = window.confirm(DialogMessages.DELETE_OBJECT);
                  if (result) {
                    canvasRef.handler?.remove();
                  }
                  return;
                }}
                icon="trash"
                iconClassName="rde-action-btn-icon"
              />
            </span>
          </Tooltip>
        </Flex.Item>
        {
          // 振る舞い残す系なので残している
        }
        {/* <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-history">
					<CommonButton
						className="rde-action-btn"
						disabled={isCropping || (canvasRef && !canvasRef.handler?.transactionHandler.undos.length)}
						onClick={() => canvasRef.handler?.transactionHandler.undo()}
					>
						<Icon name="undo-alt" style={{ marginRight: 8 }} />
						Undo
					</CommonButton>
					<CommonButton
						className="rde-action-btn"
						disabled={isCropping || (canvasRef && !canvasRef.handler?.transactionHandler.redos.length)}
						onClick={() => canvasRef.handler?.transactionHandler.redo()}
					>
						Redo
						<Icon name="redo-alt" style={{ marginLeft: 8 }} />
					</CommonButton>
				</Flex.Item> */}
      </Flex>
    );
  }
}

export default ImageMapHeaderToolbar;
