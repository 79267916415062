import React from "react";
import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ChipDialog from "./ChipDialog";
import { WorkStandardWorkTabLabel } from "../../consts/label";

interface AddHandleProps {
  AddHandle: any;
  equipmentItems: any;
}

export default function DialogEquipmentBoard(props: AddHandleProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        style={{
          scrollSnapAlign: "center",
        }}
      >
        <AddCircleIcon />
      </IconButton>
      <ChipDialog
        title={props.equipmentItems.length === 0 ? WorkStandardWorkTabLabel.ALL_REGISTERED : WorkStandardWorkTabLabel.ADD_SAFE_EQUIPMENT}
        open={open}
        onClose={handleClose}
        AddHandle={props.AddHandle}
        DialogContents={props.equipmentItems}
      />
    </div>
  );
}
